<template>
    <div v-if="item.show" class="material-card print-base-card print-appearItem" @click="addToSelection(item)" style="height: 81px"
         :class="item.selected ? 'is-selected' : ''"
    >
      <div class="material-card-inner">
        <div class="material-card-media">
          <img src="./Rectangle_54.png" alt="image technologie">
        </div>
        <div>
          <h6 class="material-card-title h6">{{ item.name }}</h6>
<!--          <span class="material-card-price t3">{{ item.model }}</span>-->
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: "AccordSoftwareItem",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    addToSelection(item) {
      this.$emit('update-selection', item, true)
      this.$forceUpdate();
    }
  }
}
</script>