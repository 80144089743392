<template>
  <div>
    <navigation-site :navThemeTransparent="false"></navigation-site>
    <div class="container space-nav-foot">

      <div class="row justify-content-center mt-nav appearContentFromBot2">
        <div class="col-xxl-8 col-xl-7 col-lg-10">

            <h1 class="h2 text-center">Inscription</h1>


            <form v-bind:class="getSentClass()" class="mt-5" @submit.prevent="() => registerProspect">
              
              <div class="row g-2">
                <div class="col-md-6">
                  <input required :value="firstname" @input="updateFirstname" type="text" placeholder="Prénom*" class="form-control">
                </div>
                <div class="col-md-6">
                  <input required :value="lastname" @input="updateLastname" type="text" placeholder="Nom*" class="form-control">
                </div>
                <div class="col-md-12">
                  <input required :disabled="this.$route.params.emailAlreadyVerified" :value="email" @input="updateEmail" type="email" placeholder="Adresse e-mail*" class="form-control">
                </div>
                <div class="col-md-12">
                  <input required :value="password" @input="updatePassword" type="password" placeholder="Mot de passe*" class="form-control">
                  <div class="t3 mt-1 color-secondary">
                    Le mot de passe doit être de 8 caractères minimum et contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial. Exemple : @Mi12Lg09#
                  </div>
                </div>
              </div><!-- row -->

              <div class="row g-2 mt-3">
                <div class="col-md-12">
                  <input :value="company" @input="updateCompany" type="text" placeholder="Société" class="form-control">
                </div>
                <div class="col-md-12">
                  <input required :value="address1" @input="updateAddress1" type="text" placeholder="Rue*" class="form-control">
                </div>
                <div class="col-md-12">
                  <input :value="address2" @input="updateAddress2" type="text" placeholder="Complément d'adresse" class="form-control">
                </div>
                <div class="col-md-8">
                  <input required :value="city" @input="updateCity" type="text" placeholder="Ville*" class="form-control">
                </div>
                <div class="col-md-4">
                  <input id="zipcode" required :value="zipcode" @input="updateZipcode" type="number" maxlength="5" placeholder="Code postal*" class="form-control">
                </div>
                <div class="col-md-12">
                  <Countries :default-country="country" @event-update-country="updateCountry" class="form-control" />
                </div>
                <div class="col-md-12">
                  <input required :value="phone" @input="updatePhone" type="tel" placeholder="Téléphone*" class="form-control">
                </div>
                <div class="col-12">
                  <div class="ms-1 form-check">
                    <input type="checkbox" :checked="newsletter" @input="updateNewsletter" class="form-check-input" id="newsletter">
                    <label for="newsletter" class="form-check-label t1">Inscription à la newsletter</label>
                  </div>
                </div>
                <div class="col-12 text--error">
                  {{ errorProspectSubscription }}
                </div>
              </div><!--row -->

              <div class="mt-3">
                <button class="btn-u3dm btn-u3dm--primary" @click="registerProspect" @keyup.enter="registerProspect">S'inscrire</button>
              </div>
              
            </form>

          </div>
        </div><!-- row -->

      </div><!-- container -->
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Countries from "../Countries";
import NavigationSite from '../ui/NavigationSite.vue';

export default {
  name: 'ProspectSubscription',
  components: {Countries, NavigationSite},
  data() {
    return {
      errorProspectSubscription: ''
    }
  },
  computed: {
    ...mapState('prospectSubscription', {
      firstname: state => state.firstname,
      lastname: state => state.lastname,
      email: state => state.email,
      password: state => state.password,
      newsletter: state => state.newsletter,
      address1: state => state.address1,
      address2: state => state.address2,
      zipcode: state => state.zipcode,
      city: state => state.city,
      country: state => state.country,
      phone: state => state.phone,
      company: state => state.company,
      customerType: state => state.customerType,
    }),
  },
  methods: {
    ...mapActions('prospectSubscription', [
      'updateFirstname',
      'updateLastname',
      'updateEmail',
      'updateFirstnameWithoutGtag',
      'updateLastnameWithoutGtag',
      'updateEmailWithoutGtag',
      'updatePhoneWithoutGtag',
      'updatePassword',
      'updateNewsletter',
      'updateAddress1',
      'updateAddress2',
      'updateZipcode',
      'updateCity',
      'updateCountry',
      'updatePhone',
      'updateCompany',
      'updateCustomerType',
    ]),
    getSentClass(){
      return {
        'sent': this.errorProspectSubscription,
      }
    },
    registerProspect() {
      this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_complete.account_creation.attempt')

      let formData = new FormData();
      formData.append('firstname', this.firstname);
      formData.append('lastname', this.lastname);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('newsletter', this.newsletter);
      formData.append('address1', this.address1);
      formData.append('address2', this.address2);
      formData.append('zipcode', this.zipcode);
      formData.append('city', this.city);
      formData.append('country', this.country);
      formData.append('phone', this.phone);
      formData.append('company', this.company);
      formData.append('customerType', this.customerType);
      formData.append('token', this.$route.params.prospectToken);

      this.errorProspectSubscription = ''

      if(this.$route.params.emailAlreadyVerified === undefined) {
        this.$http.post(process.env.VUE_APP_API_BASE_URL + "prospect/register", formData).then(() => {
          this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_complete.account_creation.success')
          this.$router.push({ name: 'prospect-thanks'})
        }, (response) => {
          for (let key of Object.keys(response.body)) {
            this.errorProspectSubscription = this.errorProspectSubscription + response.body[key][0]
          }
        })
      } else {
        this.$http.post(process.env.VUE_APP_API_BASE_URL + "prospect/update_inactive", formData).then(() => {
          location.href = process.env.VUE_APP_APP_BASE_URL + "fr/mon-compte/login-activated/" + this.$route.params.prospectToken
        }, (response) => {
          for (let key of Object.keys(response.body)) {
            this.errorProspectSubscription = this.errorProspectSubscription + response.body[key][0]
          }
        })
      }
    }
  },
  beforeCreate() {
    if(this.$route.params.emailAlreadyVerified === undefined) {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + "prospect/prospect/" + this.$route.params.prospectToken).then(({body}) => {
        this.updateFirstnameWithoutGtag(body.prospect.firstname)
        this.updateLastnameWithoutGtag(body.prospect.lastname)
        this.updateEmailWithoutGtag(body.prospect.email)
        this.updateCustomerType(body.prospect.customer_type)
      })
    } else {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + "user/user-inactive/" + this.$route.params.prospectToken).then((response) => {
        this.updateFirstnameWithoutGtag(response.body.firstname)
        this.updateLastnameWithoutGtag(response.body.lastname)
        this.updateEmailWithoutGtag(response.body.email)
        this.updatePhoneWithoutGtag(response.body.default_shipping_address.telephone)
        this.updateCustomerType(response.body.customer_type)
      })
    }
  },
  mounted() {
    this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_complete.account_creation.view')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
