<template>
<div>
  <navigation-site :navThemeTransparent="false"></navigation-site>
  <div class="min-h-80vh">
    <div class="container pt-nav pb-foot">

      <div v-if="emailSendSuccess == false" class="row justify-content-center mt-5 pt-5 appearContentFromBot2">
        <div class="col-md-6 col-xl-4 mt-5">

          <h1 class="h3 text-center">Mot de passe oublié</h1>

          <div class="mt-5">
            <div class="text--error mb-1">{{ errorEmail }}</div>
            <div>
              <input type="email" v-model="email" placeholder="Entrer votre email" class="form-control">
            </div>
            <div class="mt-4">
              <button class="btn-u3dm btn-u3dm--primary w-100" @click="sendEmail">Envoyer</button>
            </div>
          </div>

        </div>
      </div><!-- row -->

      <div v-if="emailSendSuccess == true" class="mt-5 pt-5 appearContentFromBot2">
        <div class="mt-5 pt-5">
          <div class="text-center h5">Merci ! un email vous a été envoyé</div>
          <div class="text-center mt-4">
            <a :href="urlWWWHome" class="btn-u3dm btn-u3dm--primary">Retour à l'accueil</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import NavigationSite from '../ui/NavigationSite.vue';
export default {
  components: { NavigationSite },
  name: "PasswordForgot",
  data() {
    return {
      email: '',
      errorEmail: null,
      emailSendSuccess: false,
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL
    }
  },
  methods: {
    sendEmail() {
      // console.log('send email')
      
      let formData = new FormData();
      formData.append('email', this.email);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/forgot_password', formData)
          .then(() => {
            // console.log(response)
            this.errorEmail = null
            this.emailSendSuccess = true

          }, (error) => {
           
            this.errorEmail = error.body[0]
            this.emailSendSuccess = false
          })
    },
  },
}
</script>