<template>
  <div id="app">
    <notifications position="bottom right" classes="vue-notification" />
    <router-view/>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: 'United 3D Makers %s',
  },
  computed: {
    ...mapGetters("authentication", {
      userConnected: "isConnected",
    }),
  },
  watch: {
    userConnected: function () {

      if (this.userConnected == true) {
        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'application/get-orders', {credentials: true})
          .then((response) => {
            this.addProductPrint(response.body.items)
            this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
            if (response.body["fee_show_description"] != undefined) {
            this.updateFeeShowDescription(response.body["fee_show_description"]);

          }
            this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
            this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
          })
      } else {
        this.resetBasket()
      }
    }
  },
  methods: {
    ...mapActions("authentication", [
      "setConnected",
    ]),
    ...mapActions("shop", [
        "addProductPrint", 
        "updateTotalAmountIncl", 
        "updateTotalAmountExcl", 
        "updateFeeShowAmountTaxExcl","updateFeeShowDescription",
        "resetBasket"
    ])
  },
  created() {
    this.setConnected().catch(()=>{})
  }
}
</script>
