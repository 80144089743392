import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'


// import 'bootstrap/dist/js/bootstrap.bundle.min.js'
// import 'src/components/js/popper.min.js'
// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"

import jQuery from 'jquery'
//global declaration of jquery
global.jQuery = jQuery
global.$ = jQuery

import * as VueGoogleMaps from 'vue2-google-maps'

import '../node_modules/bootstrap/js/src/collapse.js'

import './assets/scss/app.scss'
import store from './store'

import { gtag_report_event2 } from './services/google_tag_manager.js'

import VueMeta from 'vue-meta'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';


Sentry.init({
  Vue,
  environment: process.env.VUE_APP_ENV,
  dsn: "https://86675abd63f84ed0975bf465fd1682b3@o1406676.ingest.sentry.io/6760804",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    }),
    new CaptureConsole({
      levels: ['error']
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') {
      const data = {
        requestBody: hint.xhr.__sentry_xhr__.body,
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL
      }
      return { ...breadcrumb, data }
    }
    return breadcrumb
  }
});

if (location.protocol !== 'https:' && process.env.NODE_ENV !== 'development') {
  location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

Vue.prototype.$googleTagManager = gtag_report_event2

// import authentication from './store/authentication.js'
// authentication.getters.getUserId
// authentication.getters.getIsMaker

Vue.filter('formatPrice', function (value) {
  return value.toFixed(2).replace('.', ',')
})

Vue.filter('formatDate', function (date) {
  let d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let year = d.getFullYear()

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('/');
})

Vue.use(Notifications)
Vue.use(VueResource)
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyDcRNzPr6Gz_AEm0mA6GAs8Id2kPJxfoHc',
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    // key: 'AIzaSyBPxkVPrSE-IRY5vd20Uq3QYHhubq9N828',
    // libraries: 'places', // This is required if you use the Autocomplete plugin
    libraries: "places, distancematrix"
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
  },
})
Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.component('multiselect', Multiselect)

jQuery(() => {
  jQuery('#app').tooltip({
    selector: '[data-bs-toggle="tooltip"]'
  })
  jQuery('#app').popover({
    selector: '[data-bs-toggle="popover"]'
  })
})