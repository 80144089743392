<template>
<div>
  <navigation-site :navThemeTransparent="false"></navigation-site>
  <div class="min-h-80vh">
    <div class="container pt-nav pb-foot">


        <div class="mt-5 pt-5 row appearContentFromBot2">
          <div class="col-md-6 offset-md-3 mt-5 pt-5">
            <h3 class="h3">Merci !</h3>
            <p class="h6">
              Un e-mail de confirmation vous a été envoyé, il contient un lien pour activer votre compte.
            </p>
            <div class="mt-4">
              <a :href="urlWWWHome" class="btn-u3dm btn-u3dm--primary">Retour à l'accueil</a>
            </div>
          </div>
        </div>


    </div><!-- container-->
  </div>
</div>
</template>

<script>
import NavigationSite from '../ui/NavigationSite.vue'

export default {
  components: { NavigationSite },
  name: 'ProspectThanks',
  data() {
    return {
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL
    }
  }
}
</script>