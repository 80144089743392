<template>
  <div class="material-card print-base-card print-appearItem cursor-default" style="height: 81px">
    <div class="material-card-inner">
      <div class="material-card-media">
        <img width="40px" height="40px" src="./Rectangle_54.png" alt="image marque">
      </div>
      <div>
        <h6 class="material-card-title h6">{{ item.name }} <a class="material-card-remove" href="#" @click="removeSoftware(item.id)"><i class="bi bi-trash"></i></a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MySoftware",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default() {
        return {}
      }
    },
  },
  methods: {
    removeSoftware(itemId) {
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'software/' + itemId, {credentials: true})
          .then(() => {
            this.$emit('remove-my-software', itemId)
          })
    }
  }
}
</script>