<template>
  <nav id="navOuter" class="nav-outer" 
	:class="[isThemeTransparent == true && navThemeTransparent == true ? 'nav-theme--transparent' : '', navOuterIsHidden == true ? 'nav-outer--hide' : '', navIsOpen == true ? 'mobile-nav--is-open' : '']">
    <div class="container">
      <div class="navbar-site">
                    
				<!-- hamburger -->
				<div id="btnHambuger" class="hamburger hide-on-desktop" @click="navIsOpen = true">
					<svg class="i-hamburger" width="19" height="14" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM18 2C18.5523 2 19 1.55228 19 1C19 0.447715 18.5523 0 18 0V2ZM1 2H18V0H1V2Z" />
						<path d="M1 5.5C0.447715 5.5 0 5.94772 0 6.5C0 7.05228 0.447715 7.5 1 7.5V5.5ZM18 7.5C18.5523 7.5 19 7.05228 19 6.5C19 5.94772 18.5523 5.5 18 5.5V7.5ZM1 7.5H18V5.5H1V7.5Z" />
						<path d="M1 11.5C0.447715 11.5 0 11.9477 0 12.5C0 13.0523 0.447715 13.5 1 13.5V11.5ZM12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5V13.5ZM1 13.5H12V11.5H1V13.5Z" />
					</svg>
				</div>
                
				<!-- branding -->
				<div class="branding">
					<a :href="urlWWWHome">
						<div class="branding-desktop">
							<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 1720 291.33" style="enable-background:new 0 0 1720 291.33;" xml:space="preserve">

									<path class="st0" d="M585.18,60.33c-0.02,22.03-11.96,33.1-35.93,33.08c-23.97-0.03-35.88-11.13-35.85-33.16l0.06-47.8l-26.33-0.03
											l-0.05,45.87c-0.05,38.8,21.97,55.87,62.15,55.91c40.18,0.05,62.24-16.97,62.28-55.76l0.05-45.87l-26.33-0.03L585.18,60.33z"/>
									<polygon class="st0" points="747.44,85.88 661.47,12.62 633.76,12.58 633.64,112.63 659.97,112.66 660.05,41.57 743.25,112.76 
											773.73,112.8 773.85,12.75 747.52,12.72 	"/>
									
											<rect x="759.13" y="49.65" transform="matrix(1.082690e-03 -1 1 1.082690e-03 745.462 871.9022)" class="st0" width="100.05" height="26.33"/>
									<polygon class="st0" points="894.87,112.95 921.2,112.97 921.3,33.3 980.19,33.37 980.21,13 836.24,12.83 836.22,33.2 
											894.97,33.27 	"/>
									<polygon class="st0" points="1116.49,92.84 1020.32,92.73 1020.35,72.91 1110.42,73.02 1110.44,53.21 1020.37,53.09 1020.4,33.42 
											1116.57,33.53 1116.59,13.17 994.09,13.02 993.97,113.06 1116.47,113.21 	"/>
									<path class="st0" d="M1277.84,63.38c0.04-31.59-27.1-50.06-72.28-50.11l-70.95-0.09l-0.12,100.04l71.92,0.09
											C1250.49,113.37,1277.8,94.98,1277.84,63.38z M1160.86,92.48l0.07-58.48l43.37,0.05c30.76,0.04,46.83,8.65,46.8,29.29
											c-0.03,20.65-16.11,29.22-46.87,29.18L1160.86,92.48z"/>
									<path class="st0" d="M584.98,225.67c10.54-5.39,14.7-13.01,14.71-20.77c0.02-17.74-23.8-28.85-61.49-28.9
											c-21.76-0.02-40.05,4.25-56.83,13.38l-0.02,22.72l0.55,0.41c15.81-10.65,34.1-15.61,57.11-15.58c21.2,0.02,35.74,4.75,35.74,11.54
											c-0.01,5.54-7.08,8.3-21.35,8.29l-40.46-0.05l-0.02,19.82l40.04,0.04c17.05,0.02,25.77,3.36,25.76,9.6
											c-0.01,8.59-14.29,13.42-39.37,13.39c-23.97-0.03-44.89-5.46-60.12-15.59l-0.69,0.41l-0.03,22.86
											c15.65,8.62,36.01,13.21,58.87,13.24c41.57,0.05,66.25-11.98,66.28-32.21C603.67,238.71,598.56,230.4,584.98,225.67z"/>
									<path class="st0" d="M691.34,178.96l-70.95-0.09l-0.12,100.05l71.92,0.09c44.07,0.05,71.38-18.34,71.42-49.94
											C763.65,197.48,736.52,179.02,691.34,178.96z M690,258.22l-43.37-0.05l0.07-58.48l43.37,0.06c30.76,0.04,46.82,8.64,46.8,29.29
											C736.84,249.69,720.76,258.26,690,258.22z"/>
									<polygon class="st0" points="902.88,255.71 855.16,179.16 824.95,179.12 824.83,279.17 850.33,279.2 850.41,214.21 890.51,279.24 
											915.18,279.28 955.44,214.34 955.36,279.32 980.86,279.36 980.98,179.31 950.77,179.27 	"/>
									<path class="st0" d="M1055.84,179.4l-63.86,99.97l28.27,0.03l12.5-20.07l70.67,0.09l11.75,20.11l28.27,0.03l-60.43-100.12
											L1055.84,179.4z M1045.23,238.98l23.88-38.36l22.4,38.42L1045.23,238.98z"/>
									<polygon class="st0" points="1283.53,180.23 1283.11,179.68 1246.67,179.63 1180.93,221.96 1180.98,179.55 1154.66,179.52 
											1154.54,279.57 1180.86,279.6 1180.9,248.7 1203.78,233.48 1252.23,279.69 1286.73,279.73 1287.15,279.17 1224.17,219.79 	"/>
									<polygon class="st0" points="1298.25,279.74 1420.75,279.89 1420.77,259.52 1324.6,259.41 1324.63,239.59 1414.7,239.7 
											1414.72,219.89 1324.65,219.77 1324.68,200.1 1420.84,200.21 1420.87,179.84 1298.37,179.69 	"/>
									<path class="st0" d="M1568.15,213.84c0.02-21.9-18.67-33.83-52.2-33.88l-77.04-0.09l-0.12,100.05l26.33,0.03l0.04-32.57l40.19,0.05
											l33.49,32.61l32.01,0.04l0.42-0.56l-36.13-33.58C1556.47,242.23,1568.13,231.15,1568.15,213.84z M1465.18,226.6l0.03-25.92
											l50.02,0.06c17.6,0.02,26.6,2.8,26.59,13.05c-0.01,10.12-9.03,12.88-26.62,12.86L1465.18,226.6z"/>
									<path class="st0" d="M1610.86,207.65c0.01-6.24,11.93-9.68,40.06-9.65c19.67,0.02,39.21,2.95,58.33,8.66l0.83-0.28l0.02-21.06
											c-18.29-5.43-38.1-8.08-59.71-8.11c-43.23-0.06-65.83,10.72-65.86,31.23c-0.06,47.39,109.17,21.06,109.14,41.15
											c-0.01,6.79-12.48,11.49-41.86,11.45c-27.44-0.03-47.25-3.24-65.95-10.33l-0.83,0.41l-0.03,22.17c17.04,5.43,40.03,8.51,65.25,8.54
											c49.33,0.06,69.71-10.03,69.74-32.89C1720.06,202.24,1610.83,227.75,1610.86,207.65z"/>
									<path class="st1" d="M260.21,167.87c0,54.39-52.8,98.64-117.7,98.64s-117.7-44.25-117.7-98.64V24.81h40.24v143.06
											c0,37.46,34.75,67.93,77.46,67.93s77.46-30.47,77.46-67.93V0h-89.86v167.87c0,15.88,6.37,30.97,17.44,42.91
											c-1.66,0.13-3.34,0.21-5.04,0.21c-29.03,0-52.65-19.34-52.65-43.12V0H0v167.87c0,68.07,63.93,123.46,142.51,123.46h65.05
											c78.58,0,142.51-55.38,142.51-123.46V0h-89.87V167.87z M154.92,167.87V24.81h40.24v143.06c0,13.72-7.89,25.94-20.13,33.84
											C162.49,193.58,154.92,181.05,154.92,167.87z M285.02,24.81h40.24v143.06c0,47.67-40.56,87.55-94.28,96.68
											c32.89-22.63,54.04-57.55,54.04-96.68V24.81z"/>
							
							</svg>
						</div>
						<div class="branding-mobile hide-on-desktop">
							<svg class="i-brand--mobile" width="29" height="23" viewBox="0 0 29 23" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.8772 13.2527C20.8772 17.5474 16.6415 21.0413 11.4339 21.0413C6.22629 21.0413 1.99054 17.5474 1.99054 13.2527V1.95867H5.21931V13.2527C5.21931 16.2109 8.00607 18.616 11.4339 18.616C14.8617 18.616 17.6485 16.2109 17.6485 13.2527V0H10.4386V13.2527C10.4386 14.5057 10.9509 15.6982 11.8379 16.6401C11.7061 16.6516 11.5715 16.6574 11.4339 16.6574C9.10379 16.6574 7.20985 15.1307 7.20985 13.2527V0H0V13.2527C0 18.6276 5.12857 23 11.4339 23H16.6532C22.9585 23 28.0871 18.6276 28.0871 13.2527V0H20.8772V13.2527ZM12.4292 13.2527V1.95867H15.6579V13.2527C15.6579 14.3358 15.0256 15.3007 14.0421 15.9257C13.038 15.2834 12.4292 14.2925 12.4292 13.2527ZM22.8678 1.95867H26.0966V13.2527C26.0966 17.0174 22.8414 20.1657 18.5325 20.8858C21.17 19.0999 22.8678 16.3434 22.8678 13.2527V1.95867Z"/>
							</svg>
						</div>
					</a>
				</div>

				<div id="navList" class="nav-list" :class="navIsOpen == true ? 'nav-list--is-open' : ''">
					<!-- close hamburger -->
					<div id="closeNav" class="close-nav hide-on-desktop" @click="navIsOpen = false">
						<svg class="i-close" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.1647 17.94C16.9669 17.94 16.7686 17.8645 16.6177 17.7136L6.285 7.38043C5.98313 7.07855 5.98313 6.58871 6.285 6.28683C6.58687 5.98496 7.07672 5.98496 7.37859 6.28683L17.7117 16.62C18.0136 16.9218 18.0136 17.4117 17.7117 17.7136C17.5603 17.8645 17.3625 17.94 17.1647 17.94Z" fill="#161616"/>
							<path d="M6.83399 17.94C6.63617 17.94 6.43789 17.8645 6.28695 17.7136C5.98508 17.4117 5.98508 16.9218 6.28695 16.62L16.6201 6.28683C16.922 5.98496 17.4118 5.98496 17.7137 6.28683C18.0156 6.58871 18.0156 7.07855 17.7137 7.38043L7.38102 17.7131C7.23008 17.8645 7.03227 17.94 6.83399 17.94Z" fill="#161616"/>
						</svg> 
						<span>Fermer</span>     
					</div>
					<!-- list links -->
					<ul class="level-1">
						<li class="level-1-item level-1-item--maker">
              <a :href="isChecked ? urlAppBase + 'fr/mon-compte/mon-compte-maker/mes-devis/processing' : '#'" class="level-1-link" :class="!isChecked ? 'link-disabled' : ''"><span class="h6">Mes devis</span></a>
            </li>
						<li class="level-1-item level-1-item--maker">
              <a :href="isChecked ? urlAppBase + 'fr/mon-compte/mon-compte-maker/mes-commandes/processing' : '#'" class="level-1-link" :class="!isChecked ? 'link-disabled' : ''"><span class="h6">Mes commandes</span></a>
            </li>
						<li class="level-1-item">
              <a :href="urlContact" class="level-1-link">
                <span class="btn-u3dm btn-u3dm--secondary">Contactez-nous</span>
              </a>
            </li>
						<li class="level-1-item">
							<a href="" class="nav-btn-connexion">
								<router-link v-if="initialName == 0" to="/login">
									<svg class="svg-icon-item i-account" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 24C8.79478 24 5.7813 22.7517 3.51478 20.4852C1.24826 18.2187 0 15.2052 0 12C0 8.79478 1.24826 5.7813 3.51478 3.51478C5.7813 1.24826 8.79478 0 12 0C15.2052 0 18.2187 1.24826 20.4852 3.51478C22.7517 5.7813 24 8.79478 24 12C24 15.2052 22.7517 18.2187 20.4852 20.4852C18.2187 22.7517 15.2052 24 12 24ZM12 1.73913C9.25913 1.73913 6.68261 2.80652 4.74435 4.74435C2.80652 6.68261 1.73913 9.25913 1.73913 12C1.73913 14.7409 2.80652 17.3174 4.74435 19.2557C6.68261 21.1935 9.25913 22.2609 12 22.2609C14.7409 22.2609 17.3174 21.1935 19.2557 19.2557C21.1935 17.3174 22.2609 14.7409 22.2609 12C22.2609 9.25913 21.1935 6.68261 19.2557 4.74435C17.3174 2.80652 14.7409 1.73913 12 1.73913Z"/>
										<path d="M3.56847 20.1347C3.37282 20.1347 3.17629 20.069 3.01412 19.9347C2.64412 19.6282 2.59281 19.0803 2.89934 18.7103C5.15369 15.9886 8.47152 14.4277 12.002 14.4277C15.532 14.4277 18.8498 15.9886 21.1037 18.7103C21.4102 19.0803 21.3585 19.6282 20.9889 19.9347C20.6189 20.2408 20.0707 20.1895 19.7646 19.8199C17.842 17.4986 15.0124 16.1673 12.002 16.1673C8.99109 16.1673 6.16152 17.4986 4.2389 19.8199C4.06673 20.0273 3.81847 20.1347 3.56847 20.1347Z"/>
										<path d="M11.9987 13.1427C9.79391 13.1427 8 11.3487 8 9.14396C8 6.93918 9.79391 5.14526 11.9987 5.14526C14.2035 5.14526 15.9974 6.93918 15.9974 9.14396C15.9974 11.3487 14.2035 13.1427 11.9987 13.1427ZM11.9987 6.88439C10.753 6.88439 9.73913 7.89787 9.73913 9.14396C9.73913 10.39 10.7526 11.4035 11.9987 11.4035C13.2448 11.4035 14.2583 10.39 14.2583 9.14396C14.2583 7.89787 13.2443 6.88439 11.9987 6.88439Z"/>
									</svg>
								</router-link>
								<router-link v-if="initialName.length > 0" to="/account" class="initial-name nav-btn-connexion-initial-name">{{ initialName }}</router-link>
								<router-link v-if="initialName == 0" to="/account" class="nav-btn-connexion-for--mobile hide-on-desktop">Connexion</router-link>
								<router-link v-if="initialName.length > 0" to="/account" class="nav-btn-connexion-for--mobile hide-on-desktop">Mon compte</router-link>
							</a>
						</li>
					</ul>

				</div> <!-- nav-lists -->
                
			</div> <!-- navbar-site -->
		</div> <!-- nav-container -->
	</nav> <!-- nav-outer -->
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
	name: 'NavigationSite',
	props: {
		navThemeTransparent: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			navIsOpen: false,
			navOuterIsHidden: false,
			lastScrollTop: 0,
			isThemeTransparent: this.navThemeTransparent,
			// 
			token: '',
			//
      urlAppBase: process.env.VUE_APP_APP_BASE_URL,
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
			urlWWWPrint: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PRINT,
			urlWWWProject: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PROJECT,
			urlWWWModel: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_MODEL,
			urlWWWResource: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_RESOURCE,
      urlContact: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CONTACT,
		}
	},
	computed: {
    ...mapState("authentication", {
      isChecked: (state) => state.isChecked,
    }),
    ...mapGetters('authentication', {
    initialName: 'initialName'
    }),
	...mapGetters("shop", {
		shopCompleted: "shopCompleted"
	})
	},
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	beforeCreate() {
		if (this.$route.params.token != undefined) {
			this.token = '/' + this.$route.params.token
		} else {
			this.token = ''
		}
	},
	methods: {
    handleScroll() {
 

      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (window.scrollY <= 88) {

        if (window.innerWidth <= 992) {
        this.navOuterIsHidden = false;
			this.isThemeTransparent = true
        } else {
			this.navOuterIsHidden = false;
			this.isThemeTransparent = true
        }

      } else if (st > this.lastScrollTop) {
				this.isThemeTransparent = false
				this.navOuterIsHidden = true
			} else {
				this.navOuterIsHidden = false;
				this.isThemeTransparent = false
			}

      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
  },
}
</script>