<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes technologies</h4>

            <technologies-items :activeType="'softwares'"></technologies-items>

            <div v-if="loaded">
              <div class="a-accordion-header theme--active">
                <icon-thread :width="32" :color="'#7F1F23'"></icon-thread>
                <h4 class="h4">Vos Logiciels</h4>
              </div>

              <div class="mb-2">
                <router-link to="/maker/logiciels">
                  <span class="btn-u3dm btn-u3dm--secondary">Retour vers la sélection de logiciels</span>
                </router-link>
              </div>

              <div class="height-auto accord-material-container-maker border-radius-5 maker-stuff accord-material-container d-flex flex-wrap pl-5">
                <my-software
                    v-for="(item, index) in mySoftwares"
                    :key="index"
                    :index="index"
                    :item="item"
                    :class="(index + 1) % 2 == 1 ? 'print-appearItem__item--impair' : 'print-appearItem__item--pair'"
                    @remove-my-software="removeMySoftware"
                ></my-software>
              </div>

              <div v-if="newSoftwares.length > 0">
                <h4 style="margin-top: 20px" class="h4">Vos demandes d'ajout de logiciels (en attente de validation)</h4>
                <div class="height-auto accord-material-container-maker border-radius-5 maker-stuff accord-material-container d-flex flex-wrap pl-5">
                  <new-software
                      v-for="(item, index) in newSoftwares"
                      :key="'A' + index"
                      :index="index"
                      :item="item"
                      @remove-new-software="removeNewSoftware"
                  ></new-software>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import IconThread from "../components/icon/IconThread";
import MySoftware from "../components/maker/my-softwares/MySoftware";
import NewSoftware from "../components/maker/softwares/newSoftware";
import TechnologiesItems from "../components/maker/TechnologiesItems";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, IconThread, MySoftware, NewSoftware, TechnologiesItems},
  name: "MakerSoftwares",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mes logiciels',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      mySoftwares: [],
      newSoftwares: [],
      loaded: false,
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'software/getSoftwaresMaker', {credentials: true})
        .then((response) => {
          this.mySoftwares = response.body.design_softwares
          if(this.mySoftwares.length === 0) {
            this.$router.replace({ name: "maker-logiciels"})
          } else {
            this.loaded = true
          }
        })

    this.getNewSoftwares()
  },
  methods: {
    getNewSoftwares() {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'software/newSoftwares', {credentials: true})
          .then((response) => {
            this.newSoftwares = response.body
          })
    },
    removeNewSoftware(params) {
      this.newSoftwares.splice(params.index, 1)
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'software/removeNewSoftware/' + params.id, {credentials: true})
          .then(() => {
          })
    },
    removeMySoftware(id) {
      this.mySoftwares = this.mySoftwares.filter(mySoftware => mySoftware.id != id);
    }
  }

  }
</script>
