export default {
    namespaced: true,
    state: {
        btnIsLoading: false
    },
    mutations: {
        UPDATE_BTN_IS_LOADING(state, payload = null) {
            state.btnIsLoading = payload
        },
    },
    actions: {
        updateBtnIsLoading({commit}, payload = null) {
            commit('UPDATE_BTN_IS_LOADING', payload)
        },
    },

}
  
  