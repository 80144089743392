<template>
  <div class="d-flex justify-content-center mb-5">
    <div>
      <router-link :to="this.hasPrinters ? '/maker/mes-imprimantes' : '/maker/imprimantes'" class="elementToComplete" :class="activeType === 'printers' ? 'element-active' : ''">
        Impression 3D
      </router-link>
      <div class="under-print-menu" v-if="activeType === 'printers'">
        <router-link :class="$route.name === 'maker-imprimantes' ? 'active' : ''" to="imprimantes">Mes imprimantes</router-link>
        <i class="bi bi-chevron-right"></i>
        <router-link :class="$route.name === 'maker-mes-imprimantes' ? 'active' : ''" to="mes-imprimantes">Mes matériaux</router-link>
        <i class="bi bi-chevron-right"></i>
        <router-link :class="$route.name === 'maker-mes-prix-dimpressions' ? 'active' : ''" to="mes-prix-dimpressions">Mes prix</router-link>
      </div>

    </div>
    <router-link to="/maker/mes-scanners" class="elementToComplete" :class="activeType === 'scanners' ? 'element-active' : ''">
      Scanners
    </router-link>
    <router-link to="/maker/mes-logiciels" class="elementToComplete" :class="activeType === 'softwares' ? 'element-active' : ''">
      Logiciels
    </router-link>
    <router-link to="/maker/usinage" class="elementToComplete" :class="activeType === 'machining' ? 'element-active' : ''">
      Usinage
    </router-link>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "TechnologiesItems",
  props: {
    activeType: {
      type: String,
      default() {
        return {}
      }
    },
  },
  computed: {
    ...mapState("authentication", {
      hasPrinters: (state) => state.hasPrinters,
    })
  },
}
</script>