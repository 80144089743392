import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from "../views/LandingPage";
import ProspectClient from "../components/Prospect/ProspectClient";
import ProspectMaker from "../components/Prospect/ProspectMaker";
import ProspectSociety from "../components/Prospect/ProspectSociety";
import ProspectSubscription from "../components/Prospect/ProspectSubscription";
import ProspectThanks from "../components/Prospect/ProspectThanks";
import PasswordReset from "../components/Prospect/PasswordReset";
import PasswordForgot from "../components/Prospect/PasswordForgot";
import BaseAccount from "../views/BaseAccount";
import SubscriptionMaker from "../components/Prospect/SubscriptionMaker";
import MakerMyTechnologies from "../views/MakerMyTechnologies";
import MakerPrinters from "../views/MakerPrinters";
import MakerMyPrinters from "../views/MakerMyPrinters";
import MakerScanners from "../views/MakerScanners";
import MakerMyScanners from "../views/MakerMyScanners";
import MakerSoftwares from "../views/MakerSoftwares";
import MakerMySoftwares from "../views/MakerMySoftwares";
import MakerRdv from "../views/MakerRdv";
import MakerMyBankInfos from "../views/MakerMyBankInfos";
import MakerDashboard from "../views/MakerDashboard";
import MakerMachining from "../views/MakerMachining";
import MakerMyPrintsPrices from "../views/MakerMyPrintsPrices";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter() {
      window.location.replace(process.env.VUE_APP_WWW_BASE_URL)
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/project/:ref?',
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  },
  {
    path: '/project/:ref/:token',
    name: 'ProjectToken',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  },
  {
    path: '/correction/project/:ref/:token?',
    name: 'ProjectCorrection',
    component: () => import(/* webpackChunkName: "about" */ '../views/Project.vue')
  },
  {
    path: '/print/:token?',
    name: 'Print',
    component: () => import(/* webpackChunkName: "about" */ '../views/Print.vue')
  },
  {
    path: '/test-navigation',
    name: 'TestNavigation',
    component: () => import(/* webpackChunkName: "about" */ '../views/TestNavigation.vue')
  },
  { path: '/fr/', component: LandingPage,
    children: [
      {
        path: 'inscription',
        component: ProspectClient
      },
      {
        path: 'inscription-maker',
        component: ProspectMaker
      },
      {
        path: 'inscription-societe',
        component: ProspectSociety
      },
      {
        name: 'prospect-subscription',
        path: 'mon-compte/inscription/:prospectToken/:emailAlreadyVerified?',
        component: ProspectSubscription
      },
      {
        name: 'subscription-maker',
        path: 'mon-compte/inscription-maker/:prospectToken/:emailAlreadyVerified?',
        component: SubscriptionMaker
      },
      {
        name: 'prospect-thanks',
        path: 'mon-compte/merci/',
        component: ProspectThanks
      },
      {
        path: 'reset-password/:resetToken',
        component: PasswordReset
      },
      {
        path: 'password-forgot',
        component: PasswordForgot
      },
    ]
  },
  {
    path: '/basket/:token?',
    name: 'Basket',
    component: () => import(/* webpackChunkName: "about" */ '../views/Basket.vue')
  },
  {
    path: '/order/:orderSellId?/:reference?',
    name: 'Order',
    component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue')
  },
  {
    path: '/', component: BaseAccount,
    children: [
      {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "about" */ '../views/Account.vue')
      },
      {
        path: '/maker/account',
        name: 'MakerAccount',
        component: () => import(/* webpackChunkName: "about" */ '../views/MakerAccount.vue')
      },
      {
        path: '/maker',
        name: 'maker-dashboard',
        component: MakerDashboard
      },
      {
        path: '/maker/mes-technologies',
        name: 'maker-mes-technologies',
        component: MakerMyTechnologies
      },
      {
        path: '/maker/mes-imprimantes',
        name: 'maker-mes-imprimantes',
        component: MakerMyPrinters
      },
      {
        path: '/maker/imprimantes',
        name: 'maker-imprimantes',
        component: MakerPrinters
      },
      {
        path: '/maker/scanners',
        name: 'maker-scanners',
        component: MakerScanners
      },
      {
        path: '/maker/usinage',
        name: 'maker-usinage',
        component: MakerMachining
      },
      {
        path: '/maker/mes-scanners',
        name: 'maker-mes-scanners',
        component: MakerMyScanners
      },
      {
        path: '/maker/logiciels',
        name: 'maker-logiciels',
        component: MakerSoftwares
      },
      {
        path: '/maker/mes-logiciels',
        name: 'maker-mes-logiciels',
        component: MakerMySoftwares
      },
      {
        path: '/maker/mon-rendez-vous',
        name: 'maker-mon-rendez-vous',
        component: MakerRdv
      },
      {
        path: '/maker/ma-societe',
        name: 'maker-ma-societe',
        component: MakerMyBankInfos
      },
      {
        path: '/maker/mes-prix-dimpressions',
        name: 'maker-mes-prix-dimpressions',
        component: MakerMyPrintsPrices
      },
      {
        path: '/devis/:reference/:token?',
        name: 'Devis',
        component: () => import(/* webpackChunkName: "about" */ '../views/Devis.vue')
      },
      {
        path: '/commande/:reference/:token?',
        name: 'Commande',
        component: () => import(/* webpackChunkName: "about" */ '../views/Commande.vue')
      },
      {
        path: '/profil',
        component: () => import(/* webpackChunkName: "about" */ '../components/Prospect/UserProfil.vue')
      },
      {
        path: '/maker/mon-atelier',
        component: () => import(/* webpackChunkName: "about" */ '../views/MakerProfil')
      },
      {
        path: '/maker/modifier-mot-de-passe',
        component: () => import(/* webpackChunkName: "about" */ '../views/MakerPasswordUpdate')
      },
      {
        path: 'modifier-mot-de-passe',
        component: () => import(/* webpackChunkName: "about" */ '../components/Prospect/PasswordUpdate.vue')
      },
      {
        path: 'releve-de-compte',
        component: () => import(/* webpackChunkName: "about" */ '../views/AccountCustomerDetail.vue')
      },
    ]
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import(/* webpackChunkName: "about" */ '../views/Password.vue')
  },
  {
    path: '/notation/:orderToken',
    name: 'NotationFromMail',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotationFromMail.vue')
  },
  { 
    path: '*', 
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
