<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes technologies</h4>

            <technologies-items :activeType="'scanners'"></technologies-items>

            <div v-if="loaded">
              <div class="a-accordion-header theme--active">
                <icon-thread :width="32" :color="'#7F1F23'"></icon-thread>
                <h4 class="h4">Vos Scanners</h4>
              </div>

              <div class="mb-2">
                <router-link to="/maker/scanners">
                  <span class="btn-u3dm btn-u3dm--secondary">Retour vers la sélection de scanners</span>
                </router-link>
              </div>

              <div class="accord-material-container-maker height-auto border-radius-5 maker-stuff accord-material-container d-flex flex-wrap pl-5">
                <my-scanner
                    v-for="(item, index) in myScanners"
                    :key="index"
                    :item="item"
                    :index="index"
                    :class="(index + 1) % 2 == 1 ? 'print-appearItem__item--impair' : 'print-appearItem__item--pair'"
                    @remove-my-scanner="removeMyScanner"
                ></my-scanner>
              </div>

              <div v-if="newScanners.length > 0">
                <h4 style="margin-top: 20px" class="h4">Vos demandes d'ajout de scanners (en attente de validation)</h4>
                <div class="height-auto accord-material-container-maker border-radius-5 maker-stuff accord-material-container d-flex flex-wrap pl-5">
                  <new-scanner
                      v-for="(item, index) in newScanners"
                      :key="'A' + index"
                      :index="index"
                      :item="item"
                      @remove-new-scanner="removeNewScanner"
                  ></new-scanner>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import IconThread from "../components/icon/IconThread";
import MyScanner from "../components/maker/my-scanners/MyScanner";
import NewScanner from "../components/maker/scanners/newScanner";
import TechnologiesItems from "../components/maker/TechnologiesItems";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, IconThread, MyScanner, NewScanner, TechnologiesItems},
  name: "MakerScanners",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mes scanners',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      myScanners: [],
      newScanners: [],
      loaded: false,
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'scanner/getScannersMaker', {credentials: true})
        .then((response) => {
          this.myScanners = response.body
          if(this.myScanners.length === 0) {
            this.$router.replace({ name: "maker-scanners"})
          } else {
            this.loaded = true
          }
        })

    this.getNewScanners()
  },
  methods: {
    getNewScanners() {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'scanner/newScanners', {credentials: true})
          .then((response) => {
            this.newScanners = response.body
          })
    },
    removeNewScanner(params) {
      this.newScanners.splice(params.index, 1)
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'scanner/removeNewScanner/' + params.id, {credentials: true})
          .then(() => {
          })
    },
    removeMyScanner(id) {
      this.myScanners = this.myScanners.filter(myScanner => myScanner.id != id);
    },
  }

  }
</script>
