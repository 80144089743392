<template>
  <footer class="footer pt-5 pb-4">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-sm-8 col-xl-4">
          <a :href="urlHome" class="d-block" style="max-width: 140px">
              <svg version="1.1" fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1720 291.33" style="enable-background:new 0 0 1720 291.33;" xml:space="preserve">
                <path  d="M585.18,60.33c-0.02,22.03-11.96,33.1-35.93,33.08c-23.97-0.03-35.88-11.13-35.85-33.16l0.06-47.8l-26.33-0.03
                l-0.05,45.87c-0.05,38.8,21.97,55.87,62.15,55.91c40.18,0.05,62.24-16.97,62.28-55.76l0.05-45.87l-26.33-0.03L585.18,60.33z"/>
                <polygon points="747.44,85.88 661.47,12.62 633.76,12.58 633.64,112.63 659.97,112.66 660.05,41.57 743.25,112.76 
                773.73,112.8 773.85,12.75 747.52,12.72 	"/>
                <rect x="759.13" y="49.65" transform="matrix(1.082690e-03 -1 1 1.082690e-03 745.462 871.9022)"  width="100.05" height="26.33"/>
                <polygon points="894.87,112.95 921.2,112.97 921.3,33.3 980.19,33.37 980.21,13 836.24,12.83 836.22,33.2 
                894.97,33.27 	"/>
                <polygon points="1116.49,92.84 1020.32,92.73 1020.35,72.91 1110.42,73.02 1110.44,53.21 1020.37,53.09 1020.4,33.42 
                1116.57,33.53 1116.59,13.17 994.09,13.02 993.97,113.06 1116.47,113.21 	"/>
                <path d="M1277.84,63.38c0.04-31.59-27.1-50.06-72.28-50.11l-70.95-0.09l-0.12,100.04l71.92,0.09
                C1250.49,113.37,1277.8,94.98,1277.84,63.38z M1160.86,92.48l0.07-58.48l43.37,0.05c30.76,0.04,46.83,8.65,46.8,29.29
                c-0.03,20.65-16.11,29.22-46.87,29.18L1160.86,92.48z"/>
                <path d="M584.98,225.67c10.54-5.39,14.7-13.01,14.71-20.77c0.02-17.74-23.8-28.85-61.49-28.9
                c-21.76-0.02-40.05,4.25-56.83,13.38l-0.02,22.72l0.55,0.41c15.81-10.65,34.1-15.61,57.11-15.58c21.2,0.02,35.74,4.75,35.74,11.54
                c-0.01,5.54-7.08,8.3-21.35,8.29l-40.46-0.05l-0.02,19.82l40.04,0.04c17.05,0.02,25.77,3.36,25.76,9.6
                c-0.01,8.59-14.29,13.42-39.37,13.39c-23.97-0.03-44.89-5.46-60.12-15.59l-0.69,0.41l-0.03,22.86
                c15.65,8.62,36.01,13.21,58.87,13.24c41.57,0.05,66.25-11.98,66.28-32.21C603.67,238.71,598.56,230.4,584.98,225.67z"/>
                <path d="M691.34,178.96l-70.95-0.09l-0.12,100.05l71.92,0.09c44.07,0.05,71.38-18.34,71.42-49.94
                C763.65,197.48,736.52,179.02,691.34,178.96z M690,258.22l-43.37-0.05l0.07-58.48l43.37,0.06c30.76,0.04,46.82,8.64,46.8,29.29
                C736.84,249.69,720.76,258.26,690,258.22z"/>
                <polygon points="902.88,255.71 855.16,179.16 824.95,179.12 824.83,279.17 850.33,279.2 850.41,214.21 890.51,279.24 
                915.18,279.28 955.44,214.34 955.36,279.32 980.86,279.36 980.98,179.31 950.77,179.27 	"/>
                <path  d="M1055.84,179.4l-63.86,99.97l28.27,0.03l12.5-20.07l70.67,0.09l11.75,20.11l28.27,0.03l-60.43-100.12
                L1055.84,179.4z M1045.23,238.98l23.88-38.36l22.4,38.42L1045.23,238.98z"/>
                <polygon  points="1283.53,180.23 1283.11,179.68 1246.67,179.63 1180.93,221.96 1180.98,179.55 1154.66,179.52 
                  1154.54,279.57 1180.86,279.6 1180.9,248.7 1203.78,233.48 1252.23,279.69 1286.73,279.73 1287.15,279.17 1224.17,219.79 	"/>
                <polygon  points="1298.25,279.74 1420.75,279.89 1420.77,259.52 1324.6,259.41 1324.63,239.59 1414.7,239.7 
                  1414.72,219.89 1324.65,219.77 1324.68,200.1 1420.84,200.21 1420.87,179.84 1298.37,179.69 	"/>
                <path  d="M1568.15,213.84c0.02-21.9-18.67-33.83-52.2-33.88l-77.04-0.09l-0.12,100.05l26.33,0.03l0.04-32.57l40.19,0.05
                  l33.49,32.61l32.01,0.04l0.42-0.56l-36.13-33.58C1556.47,242.23,1568.13,231.15,1568.15,213.84z M1465.18,226.6l0.03-25.92
                  l50.02,0.06c17.6,0.02,26.6,2.8,26.59,13.05c-0.01,10.12-9.03,12.88-26.62,12.86L1465.18,226.6z"/>
                <path  d="M1610.86,207.65c0.01-6.24,11.93-9.68,40.06-9.65c19.67,0.02,39.21,2.95,58.33,8.66l0.83-0.28l0.02-21.06
                  c-18.29-5.43-38.1-8.08-59.71-8.11c-43.23-0.06-65.83,10.72-65.86,31.23c-0.06,47.39,109.17,21.06,109.14,41.15
                  c-0.01,6.79-12.48,11.49-41.86,11.45c-27.44-0.03-47.25-3.24-65.95-10.33l-0.83,0.41l-0.03,22.17c17.04,5.43,40.03,8.51,65.25,8.54
                  c49.33,0.06,69.71-10.03,69.74-32.89C1720.06,202.24,1610.83,227.75,1610.86,207.65z"/>
                <path d="M260.21,167.87c0,54.39-52.8,98.64-117.7,98.64s-117.7-44.25-117.7-98.64V24.81h40.24v143.06
                  c0,37.46,34.75,67.93,77.46,67.93s77.46-30.47,77.46-67.93V0h-89.86v167.87c0,15.88,6.37,30.97,17.44,42.91
                  c-1.66,0.13-3.34,0.21-5.04,0.21c-29.03,0-52.65-19.34-52.65-43.12V0H0v167.87c0,68.07,63.93,123.46,142.51,123.46h65.05
                  c78.58,0,142.51-55.38,142.51-123.46V0h-89.87V167.87z M154.92,167.87V24.81h40.24v143.06c0,13.72-7.89,25.94-20.13,33.84
                  C162.49,193.58,154.92,181.05,154.92,167.87z M285.02,24.81h40.24v143.06c0,47.67-40.56,87.55-94.28,96.68
                  c32.89-22.63,54.04-57.55,54.04-96.68V24.81z"/>
              </svg>
          </a>
          <p class="mt-4">
            <a class="color-white" :href="urlAbout">
              Nous simplifions l’impression 3D en mettant en relation les
              différents acteurs du secteur dans un souci permanent d’innovation
              et de performance
            </a>
          </p>
        </div>
        <div class="col-xl-7">
          <div class="row">
            <div class="ps-2 mt-2 col-lg-4">
              <ul class="ps-0"><h4 class="h4 mb-2 color-footer">Société</h4>
                <li><a :href="urlAbout" class="h7 mb-2">À propos</a></li>
                <li><a :href="urlTeam" class="h7 mb-2">Equipe</a></li>
                <li><a :href="urlLegacyNotice" class="h7 mb-2">Mentions légales</a></li>
                <li><a :href="urlKitCom" class="h7 mb-2">Kit de communication</a></li>
                <li><a :href="urlBlog" class="h7 mb-2">Blog</a></li>
              </ul>
            </div>
            <div class="ps-2 mt-2 col-lg-4">
              <ul class="ps-0"><h4 class="h4 mb-2 color-footer">Besoin d'aides</h4>
                <li><a :href="urlContact" class="h7 mb-2">Contact</a></li>
                <li><a :href="urlCgvCgu" class="h7 mb-2">CGU</a></li>
                <li><a :href="urlGlossary" class="h7 mb-2">Glossaires impression 3D</a></li>
                <li><a :href="urlFaq" class="h7 mb-2">FAQ</a></li>
              </ul>
            </div>
            <div class="ps-2 mt-2 col-lg-4">
              <ul class="ps-0"><h4 class="h4 mb-2 color-footer">Connexion</h4>
                <li><router-link to="/account" class="h7 mb-2">Mon compte</router-link></li>
                <li><a :href="urlWWWPrint" class="h7 mb-2">Impression 3D</a></li>
                <li><a :href="urlWWWProject" class="h7 mb-2">Modélisation 3D</a></li>
                <li><a :href="urlWWWModel" class="h7 mb-2">Modèles 3D</a></li>
                <li><router-link to="/fr/inscription-maker" class="h7 mb-2">Je suis un maker</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div><!-- row -->
      <div class="row mt-2">
        <div class="col">
          <div class="newsletter">
            <h4 class="h4 mb-2 color-white">Newsletter</h4>
            <input v-model="newsletterEmail" type="text" placeholder="Votre adresse mail">
            <span @click="subscribeNewsletter" class="btn-newsletter">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.215162 13.7891C-0.0456404 13.5334 -0.0693497 13.1333 0.144034 12.8514L0.215162 12.7706L6.10174 7L0.215162 1.2294C-0.0456404 0.973724 -0.0693497 0.573635 0.144034 0.291703L0.215162 0.210931C0.475964 -0.044743 0.884077 -0.0679861 1.17166 0.141202L1.25405 0.210931L7.65984 6.49077C7.92064 6.74644 7.94435 7.14653 7.73097 7.42846L7.65984 7.50923L1.25405 13.7891C0.967172 14.0703 0.502044 14.0703 0.215162 13.7891Z" fill="#161616"/>
              </svg>
            </span>
            <span v-if="newsletterSuccess">Merci, vous êtes bien abonné à notre newsletter.</span>
            <span v-if="newsletterError">Veuillez vérifier que l'adresse e-mail saisie est correcte.</span>
          </div>
        </div>
      </div>
      <hr class="mt-5 mb-3 mb-md-2 color-white">
      <div class="row justify-content-between">
        <div class="col-xl-8">
          <div class="d-flex align-items-end flex-wrap">
            <span>United 3D Makers - 2021 - Tous droits réservés. Une entreprise </span>  
            <a href="https://lafrenchtech.com/fr/" target="_blank" class="ps-2"><img src="@/assets/images/french_tech.png" alt="logo french tech" style="max-width: 140px"></a> 
            <a href="https://www.lafrenchfab.fr/" target="_blank" class="ps-2"><img src="@/assets/images/french_fab.png" alt="logo french fab" style="max-width: 140px"></a>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="pt-4 text-xl-end">
            <a href="https://www.facebook.com/United3dMakers/" class="ps-3">
						<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.0075 8.9995L8.452 6.104H5.6735V4.225C5.6735 3.433 6.0615 2.6605 7.306 2.6605H8.569V0.1955C8.569 0.1955 7.423 0 6.327 0C4.039 0 2.5435 1.387 2.5435 3.8975V6.1045H0V9H2.5435V16H5.6735V9L8.0075 8.9995Z" fill="white"/>
						</svg>
					</a>
					<a href="https://twitter.com/united3dmakers" class="ps-3">
						<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16 1.42342C15.4116 1.66396 14.7795 1.82649 14.115 1.89995C14.8007 1.52145 15.3136 0.925741 15.5582 0.223974C14.914 0.576974 14.209 0.825448 13.4738 0.958593C12.9794 0.471576 12.3245 0.148773 11.6109 0.0403016C10.8973 -0.06817 10.1648 0.0437586 9.52717 0.35871C8.88955 0.673661 8.38247 1.17401 8.08467 1.78209C7.78686 2.39016 7.71499 3.07193 7.88021 3.72154C6.57496 3.66108 5.29807 3.34809 4.13242 2.80288C2.96677 2.25767 1.93841 1.49244 1.11407 0.556828C0.832203 1.0054 0.670131 1.52549 0.670131 2.07938C0.669817 2.578 0.802912 3.069 1.05761 3.50879C1.3123 3.94858 1.68073 4.32357 2.13019 4.60049C1.60893 4.58519 1.09918 4.45524 0.643354 4.22148V4.26048C0.643302 4.95983 0.905511 5.63765 1.38549 6.17894C1.86547 6.72023 2.53365 7.09165 3.27667 7.23017C2.79312 7.3509 2.28615 7.36869 1.79406 7.28218C2.0037 7.88392 2.41205 8.41013 2.96194 8.78712C3.51184 9.16412 4.17575 9.37303 4.86074 9.38462C3.69794 10.2268 2.26188 10.6836 0.783581 10.6816C0.521717 10.6816 0.260073 10.6675 0 10.6393C1.50056 11.5294 3.24731 12.0018 5.03127 12C11.0702 12 14.3715 7.38554 14.3715 3.38349C14.3715 3.25347 14.368 3.12215 14.3617 2.99213C15.0038 2.56369 15.5581 2.03315 15.9986 1.42537L16 1.42342Z" fill="white"/>
						</svg>
					</a>
					<a href="https://fr.linkedin.com/company/united-3dmakers" class="ps-3">
						<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.290323 16H3.19355V4.9H0.290323V16ZM1.74194 0C0.774193 0 0 0.8 0 1.8C0 2.8 0.774193 3.6 1.74194 3.6C2.70968 3.6 3.48387 2.8 3.48387 1.8C3.48387 0.8 2.70968 0 1.74194 0ZM8.12903 6.6V4.9H5.22581V16H8.12903V10.3C8.12903 7.1 12.0968 6.9 12.0968 10.3V16H15V9.2C15 3.8 9.48387 4 8.12903 6.6Z" fill="white"/>
						</svg>
					</a>
          </div>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSite",
  data() {
    return {
      newsletterEmail: null,
      newsletterSuccess: false,
      newsletterError: false,
      urlHome: process.env.VUE_APP_WWW_BASE_URL,
      urlAbout: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_ABOUT,
      urlTeam: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_TEAM,
      urlLegacyNotice: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_LEGAL_NOTICE,
      urlKitCom: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_KIT_COM,
      urlBlog: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_RESOURCE,
      urlContact: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CONTACT,
      urlCgvCgu: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CGU_CGV,
      urlGlossary: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_GLOSSARY,
      urlFaq: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_FAQ,
      urlWWWPrint: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PRINT,
			urlWWWProject: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PROJECT,
			urlWWWModel: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_MODEL
    }
  },
  methods : {
    subscribeNewsletter() {

      if(this.newsletterEmail === null || this.newsletterEmail === '') {
        this.newsletterSuccess = false;
        this.newsletterError = true;
      } else {
        let formData = new FormData();
        formData.append('email', this.newsletterEmail);

        this.$http.post(process.env.VUE_APP_APP_BASE_URL + 'fr/newsletter/ajax/subscribe', formData)
            .then(() => {
              this.newsletterSuccess = true;
              this.newsletterError = false;

            }, () => {
              this.newsletterSuccess = false;
              this.newsletterError = true;
            })
      }

    },
  }
};
</script>