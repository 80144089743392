<template>
  <div>
    <router-view></router-view>
    <footer-site></footer-site>
  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'

export default {
  components: { FooterSite },
  name: 'LandingPage',
}
</script>
