<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes technologies</h4>

            <div class="d-flex justify-content-center">
              <router-link to="mes-imprimantes" class="elementToComplete">
                Imprimantes
              </router-link>
              <router-link to="/maker/scanners" class="elementToComplete">
                Scanners
              </router-link>
              <router-link to="/maker/logiciels" class="elementToComplete">
                Logiciels
              </router-link>
              <router-link to="/maker/usinage" class="elementToComplete">
                Usinage
              </router-link>
            </div>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu},
  name: "MakerMyTechnologies",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mes technologies',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
}
</script>
