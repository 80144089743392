export default {
    namespaced: true,
    state: {
        productProjectName: null,
        listProductsPrint: [],
        listProductsProject: [],
        feeShowAmountTaxExcl: null,
        feeShowDescription : null,
        totalAmountTaxIncl: null,
        totalAmountTaxExcl: null,
    },
    mutations: {
        UPDATE_PRODUCT_PROJECT_NAME(state, payload = null) {
            state.productProjectName = payload
        },
        ADD_PRODUCT_PRINT(state, payload = null) {
            state.listProductsPrint = payload
        },
        ADD_PRODUCT_PROJECT(state, payload = null) {
            state.listProductsProject = payload
        },
        UPDATE_FEE_SHOW_AMOUNT_TAX_EXCL(state, payload = null) {
            state.feeShowAmountTaxExcl = payload
        },
        UPDATE_FEE_SHOW_DESCRIPTION(state, payload = null) {
            state.feeShowDescription = payload
        },
        UPDATE_TOTAL_AMOUNT_INCL(state, payload = null) {
            state.totalAmountTaxIncl = payload
        },
        UPDATE_TOTAL_AMOUNT_EXCL(state, payload = null) {
            state.totalAmountTaxExcl = payload
        },
        RESET_BASKET(state) {
            state.productProjectName = null
            state.listProductsPrint = []
            state.listProductsProject = []
            state.feeShowAmountTaxExcl = null
            state.feeShowDescription = null
            state.totalAmountTaxIncl = null
            state.totalAmountTaxExcl = null
        }
    },
    actions: {
        updateProductProjectName({commit}, payload = null) {
            commit('UPDATE_PRODUCT_PROJECT_NAME', payload)
        },
        addProductPrint({commit}, payload = null) {
            commit('ADD_PRODUCT_PRINT', payload)
        },
        addProductProject({commit}, payload = null) {
            commit('ADD_PRODUCT_PROJECT', payload)
        },
        updateFeeShowAmountTaxExcl({commit}, payload = null) {
            commit('UPDATE_FEE_SHOW_AMOUNT_TAX_EXCL', payload)
        },
        updateFeeShowDescription({commit}, payload = null) {
            commit('UPDATE_FEE_SHOW_DESCRIPTION', payload)
        },
        updateTotalAmountIncl({commit}, payload = null) {
            commit('UPDATE_TOTAL_AMOUNT_INCL', payload)
        },
        updateTotalAmountExcl({commit}, payload = null) {
            commit('UPDATE_TOTAL_AMOUNT_EXCL', payload)
        },
        resetBasket({commit}) {
            commit('RESET_BASKET')
        }
    },
    getters: { 
        shopCompleted: state => {
            let completed = false
            if (state.listProductsPrint !== null) {
                if (state.listProductsPrint.length >= 1) {

                    completed = true
                }
            }

            return completed
        }
    }
}
  
  