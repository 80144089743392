export default {
    namespaced: true,
    state: {
        // componentLogginIsOpen: false
        previousRoute: ''
    },
    mutations: {
        UPDATE_ROUTE(state, payload = null) {
            state.previousRoute = payload
        },
    },
    actions: {
        updateRoute({commit}, payload) {
            commit('UPDATE_ROUTE', payload)
        },
        resetRoute({commit}) {
            commit('UPDATE_ROUTE', '')
        }
    },
}