<template>
  <select @change="changeEvent($event)">
    <option v-for="(country, index) in countries" :key="index" :value="country.alpha2Code" :selected="country.alpha2Code == defaultCountry">
      {{country.translations.fr}}
    </option>
  </select>
</template>

<script>
import Countries from "../countries.json";

export default {
  name: 'Countries',
  props: {
    defaultCountry: {
      type: String
    }
  },
  data() {
    return {
      countries: Countries,
    }
  },
  computed: {
  },
  methods: {
    changeEvent(event) {
      this.$emit('event-update-country', event.target.value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
