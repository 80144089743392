<template>
  <div class="material-card print-base-card print-appearItem cursor-default" style="height: 81px">
    <div class="material-card-inner">
      <div class="material-card-media">
        <img width="40px" height="40px" :src="pictureBaseUrl + item.scanner_model.ref_brand.id" alt="image marque">
      </div>
      <div>
        <h6 class="material-card-title h6">{{ item.scanner_model.ref_brand.name }} - {{ item.name }} <a class="material-card-remove" href="#" @click="removeScanner(item.id)"><i class="bi bi-trash"></i></a>
        </h6>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "MyScanner",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      pictureBaseUrl: process.env.VUE_APP_API_BASE_URL + 'brand/picture/'
    }
  },
  methods: {
    removeScanner(itemId) {
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'scanner/' + itemId, {credentials: true})
          .then(() => {
            this.$emit('remove-my-scanner', itemId)
          })
    },
  }
}
</script>