<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes technologies</h4>

            <technologies-items :activeType="'printers'"></technologies-items>

            <div v-if="this.loaded">
              <div class="a-accordion-header theme--active" style="display: flex;">
                <div style="flex: 2">
                  <icon-thread :width="32" :color="'#7F1F23'"></icon-thread>
                  <h4 class="h4" style="display: inline-block">Sélectionnez vos modèles d'imprimantes</h4>
                </div>
                <input v-model="search" type="text" placeholder="Rechercher marque / modèle / technologie" style="flex: 1;">
              </div>

              <div class="accord-material-container-maker border-radius-5 accord-material-container d-flex flex-wrap pl-5">
                <accord-printer-item
                    v-for="(item, index) in printers"
                    :key="index"
                    :item="item"
                    :class="(index + 1) % 2 == 1 ? 'print-appearItem__item--impair' : 'print-appearItem__item--pair'"
                    @update-selection="addPrinterToSelection(item, true)"
                ></accord-printer-item>
              </div>

              <div style="display: flex; justify-content: space-between;" class="mt-3">
                <div
                    class="d-flex align-items-center pb-3 cursor-p"
                    @click="(e) => {e.preventDefault(); isModalAddNewPrinterVisible = true}"
                >
                  <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M8 12.8349C7.57297 12.8349 7.22656 12.4885 7.22656 12.0615V3.93896C7.22656 3.51193 7.57297 3.16553 8 3.16553C8.42703 3.16553 8.77344 3.51193 8.77344 3.93896V12.0615C8.77344 12.4885 8.42703 12.8349 8 12.8349Z"
                        fill="#7C7C7C"
                    />
                    <path
                        d="M12.06 8.77393H3.9375C3.51047 8.77393 3.16406 8.42752 3.16406 8.00049C3.16406 7.57346 3.51047 7.22705 3.9375 7.22705H12.06C12.487 7.22705 12.8334 7.57346 12.8334 8.00049C12.8334 8.42752 12.487 8.77393 12.06 8.77393Z"
                        fill="#7C7C7C"
                    />
                    <path
                        d="M7.99797 15.9237C3.62875 15.9237 0.0742188 12.3691 0.0742188 7.99992C0.0742188 3.6307 3.62875 0.0761719 7.99797 0.0761719C12.3672 0.0761719 15.9217 3.6307 15.9217 7.99992C15.9217 12.3691 12.3672 15.9237 7.99797 15.9237ZM7.99797 1.62305C4.48187 1.62305 1.62109 4.48383 1.62109 7.99992C1.62109 11.516 4.48187 14.3768 7.99797 14.3768C11.5141 14.3768 14.3748 11.516 14.3748 7.99992C14.3748 4.48383 11.5141 1.62305 7.99797 1.62305Z"
                        fill="#7C7C7C"
                    />
                  </svg>
                  <h6 class="h6 mb-0 ms-2 color-secondary">
                    Demander une nouvelle imprimante
                  </h6>
                </div>

                <router-link to="/maker/mes-imprimantes">
                  <span class="btn-u3dm btn-u3dm--primary">Suivant</span>
                </router-link>
              </div>

              <div v-if="newPrinters.length > 0">
                <h4 style="margin-top: 20px" class="h4">Vos demandes d'ajout d'imprimantes (en attente de validation)</h4>
                <div style="height: auto" class="accord-material-container-maker border-radius-5 maker-stuff accord-material-container d-flex flex-wrap pl-5">
                  <new-printer
                      v-for="(item, index) in newPrinters"
                      :key="index"
                      :index="index"
                      :item="item"
                      @remove-new-printer="removeNewPrinter"
                  ></new-printer>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

    <transition name="fade-modal-login">
      <modal-add-new-printer v-if="isModalAddNewPrinterVisible" @close="isModalAddNewPrinterVisible = false"></modal-add-new-printer>
    </transition>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import IconThread from "../components/icon/IconThread";
import AccordPrinterItem from "../components/maker/printers/AccordPrinterItem";
import ModalAddNewPrinter from "../components/modal/ModalAddNewPrinter";
import NewPrinter from "../components/maker/printers/newPrinter";
import TechnologiesItems from "../components/maker/TechnologiesItems";
import {mapActions, mapState} from "vuex";

export default {
  components: {NewPrinter, MakerNavigationSite, FooterSite, MakerMenu, IconThread, AccordPrinterItem, ModalAddNewPrinter, TechnologiesItems},
  name: "MakerPrinters",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mes imprimantes',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      newPrinters: [],
      isModalAddNewPrinterVisible: false,
      search: '',
      loaded: false,
    }
  },
  computed: {
    ...mapState("printers", {
      printers: (state) => state.printers,
    }),
  },
  watch: {
    search: function(val) {
      for (var j = 0; j < this.printers.length; j++) {
        this.printers[j].show = true
        this.updatePrinter({i: j, printer: this.printers[j]})
      }
      if(val !== '') {
        for (var i = 0; i < this.printers.length; i++) {
          let brand = this.printers[i].brand.toLowerCase()
          let model = this.printers[i].model.toLowerCase()
          let technology = this.printers[i].technology.name.toLowerCase()
          if(brand.search(val.toLowerCase()) === -1 && model.search(val.toLowerCase()) && technology.search(val.toLowerCase())) {
            this.printers[i].show = false
            this.updatePrinter({i: i, printer: this.printers[i]})
          }
        }
      }
    },
    '$store.state.printers.printers': {
      deep: true,
      handler(newValPrinters) {
        for(let newValPrinter of newValPrinters) {
          if(newValPrinter.selected === true) {
            this.updateHasPrinters(true)
            return
          }
          this.updateHasPrinters(false)
        }
      }
    },
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'printer-model', {credentials: true})
        .then((response) => {
          this.updatePrinters(response.body)
          this.$http.get(process.env.VUE_APP_API_BASE_URL + 'printer-model/getPrintersMaker', {credentials: true})
              .then((response) => {
                let printersMaker = response.body
                for (let printerMaker of printersMaker) {
                  this.addPrinterToSelection(printerMaker, false)
                }
                this.loaded = true
              })
        })

    this.getNewPrinters()
  },
  methods: {
    ...mapActions("printers", [
      "updatePrinters",
      "updatePrinter",
    ]),
    ...mapActions("authentication", [
      "updateHasPrinters",
    ]),
    addPrinterToSelection(e, register) {
      for (let [i, printer] of this.printers.entries()) {
        if(printer.id === e.id) {
          if(printer.selected) {
            printer.selected = false
            if(register) {
              this.removePrinter(e.id)
            }
          } else {
            if(register) {
              this.addPrinter(e.id)
            }
            printer.selected = true
          }
          this.updatePrinter({i: i, printer: printer})
        }
      }
    },
    addPrinter(id) {
      let formData = new FormData();

      formData.append("printers[][id]", id);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + 'printer/addPrinter', formData, {credentials: true})
          .then(() => {
            this.$notify({ type: 'success', text: 'Enregistré' })
          })
    },
    removePrinter(id) {
      let formData = new FormData();

      formData.append("printerId", id);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + 'printer/removePrinter', formData, {credentials: true})
          .then(() => {
            this.$notify({ type: 'success', text: 'Enregistré' })
          })
    },
    getNewPrinters() {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'printer/newPrinters', {credentials: true})
          .then((response) => {
            this.newPrinters = response.body
          })
    },
    removeNewPrinter(params) {
      this.newPrinters.splice(params.index, 1)
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'printer/removeNewPrinter/' + params.id, {credentials: true})
          .then(() => {
          })
    }
  }

  }
</script>
