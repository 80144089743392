<template>
  <div v-if="loaded">
    <router-view></router-view>
  </div>
</template>

<script>

import {mapActions} from 'vuex'

export default {
  name: 'BaseAccount',
  data() {
    return {
      loaded: false,
    }
  },
  methods: {
    ...mapActions("authentication", [
        "setConnected",
    ]),
  },
  beforeMount() {
    if(this.$route.params.token === undefined) {
      this.setConnected().then(
        () => {
          this.loaded = true
        },
        () => {
            window.location.href = '/login'
        }
      );
    } else {
        this.loaded = true
    }
  }
}
</script>
