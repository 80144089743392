<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes technologies</h4>

            <technologies-items :activeType="'printers'"></technologies-items>

            <div class="a-accordion-header theme--active">
              <icon-thread :width="32" :color="'#7F1F23'"></icon-thread>
              <h4 class="h4">Configurer vos matériaux</h4>
            </div>

            <div v-if="myPrinters.length === 0 && loaded">
              <p>
                Vous n'avez aucune imprimante de sélectionné. <br>
              </p>
              <p>
                Aller à la sélection de <router-link to="/maker/imprimantes">mes imprimantes</router-link> pour configurer ensuite <strong>mes matériaux</strong>.
              </p>
            </div>

            <div v-if="myPrinters.length > 0 && loaded">
              <div class="mb-2">
                <router-link to="/maker/imprimantes">
                  <span class="btn-u3dm btn-u3dm--secondary">Retour vers la sélection d'imprimantes</span>
                </router-link>

                <a style="float: right" href="#" class="btn-u3dm btn-u3dm--primary" @click="(e) => {e.preventDefault(); saveMyPrinters();}">Enregistrer</a>
              </div>
              <my-printer
                  v-for="(item, index) in myPrinters"
                  :key="index"
                  :item="item"
                  @remove-my-printer="removeMyPrinter"
              ></my-printer>
              <div class="ask-for-adding-container">
                <div class="d-flex align-items-center pb-3 cursor-p ask-for-adding">
                  <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M8 12.8349C7.57297 12.8349 7.22656 12.4885 7.22656 12.0615V3.93896C7.22656 3.51193 7.57297 3.16553 8 3.16553C8.42703 3.16553 8.77344 3.51193 8.77344 3.93896V12.0615C8.77344 12.4885 8.42703 12.8349 8 12.8349Z"
                        fill="#7C7C7C"
                    />
                    <path
                        d="M12.06 8.77393H3.9375C3.51047 8.77393 3.16406 8.42752 3.16406 8.00049C3.16406 7.57346 3.51047 7.22705 3.9375 7.22705H12.06C12.487 7.22705 12.8334 7.57346 12.8334 8.00049C12.8334 8.42752 12.487 8.77393 12.06 8.77393Z"
                        fill="#7C7C7C"
                    />
                    <path
                        d="M7.99797 15.9237C3.62875 15.9237 0.0742188 12.3691 0.0742188 7.99992C0.0742188 3.6307 3.62875 0.0761719 7.99797 0.0761719C12.3672 0.0761719 15.9217 3.6307 15.9217 7.99992C15.9217 12.3691 12.3672 15.9237 7.99797 15.9237ZM7.99797 1.62305C4.48187 1.62305 1.62109 4.48383 1.62109 7.99992C1.62109 11.516 4.48187 14.3768 7.99797 14.3768C11.5141 14.3768 14.3748 11.516 14.3748 7.99992C14.3748 4.48383 11.5141 1.62305 7.99797 1.62305Z"
                        fill="#7C7C7C"
                    />
                  </svg>
                  <h6 class="h6 mb-0 ms-2 color-secondary">
                    <a target="_blank" style="color: #7C7C7C;" href="mailto:contact@united-3dmakers.fr?subject=Demande ajout de matériaux&body=Merci de préciser pour quelles technologies vous souhaitez ces matériaux.">Demander d'autres matériaux</a>
                  </h6>
                </div>

                <div>
                  <a href="#" class="btn-u3dm btn-u3dm--primary" @click="(e) => {e.preventDefault(); saveMyPrinters();}">Enregistrer</a>
                </div>
              </div>
            </div>

            <div v-if="newPrinters.length > 0">
              <h4 style="margin-top: 20px" class="h4">Vos demandes d'ajout d'imprimantes (en attente de validation)</h4>
              <div style="height: auto" class="accord-material-container-maker border-radius-5 maker-stuff accord-material-container d-flex flex-wrap pl-5">
              <new-printer
                  v-for="(item, index) in newPrinters"
                  :key="index"
                  :index="index"
                  :item="item"
                  @remove-new-printer="removeNewPrinter"
              ></new-printer>
            </div>
            </div>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import IconThread from "../components/icon/IconThread";
import MyPrinter from "../components/maker/my-printers/MyPrinter";
import NewPrinter from "../components/maker/printers/newPrinter";
import TechnologiesItems from "../components/maker/TechnologiesItems";
import {mapActions, mapState} from "vuex";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, IconThread, MyPrinter, NewPrinter, TechnologiesItems},
  name: "MakerPrinters",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mes imprimantes',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  computed: {
    ...mapState("authentication", {
      hasPrinters: (state) => state.hasPrinters,
    })
  },
  data() {
    return {
      myPrinters: [],
      newPrinters: [],
      loaded: false,
      urlContact: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CONTACT,
    }
  },
  watch: {
    myPrinters: function(val) {
      if(val.length > 0) {
        this.updateHasPrinters(true)
      } else {
        this.updateHasPrinters(false)
      }
    },
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'printer/getPrintersMaker', {credentials: true})
        .then((response) => {
          this.myPrinters = response.body
          if(this.myPrinters.length === 0) {
            this.updateHasPrinters(false)
            this.loaded = true
          } else {
            this.updateHasPrinters(true)

            this.$http.get(process.env.VUE_APP_API_BASE_URL + 'ref/technologyMaterial', {credentials: true})
                .then((response) => {
                  for (let myPrinter of this.myPrinters) {
                    for (let technologyMaterial of response.body) {
                      if(technologyMaterial.id === myPrinter.technology.id) {
                        myPrinter.materials = JSON.parse(JSON.stringify(technologyMaterial.materials))
                        // Set active if material already saved
                        if(myPrinter.materialsSaved !== undefined) {
                          for (let materialSaved of myPrinter.materialsSaved) {
                            for (let material of myPrinter.materials) {
                              if(material.id === materialSaved) {
                                material.selected = true
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  this.loaded = true
                })
          }
        })

    this.getNewPrinters()
  },
  methods: {
    ...mapActions("authentication", [
      "updateHasPrinters",
    ]),
    getNewPrinters() {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'printer/newPrinters', {credentials: true})
          .then((response) => {
            this.newPrinters = response.body
          })
    },
    removeNewPrinter(params) {
      this.newPrinters.splice(params.index, 1)
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'printer/removeNewPrinter/' + params.id, {credentials: true})
          .then(() => {
          })
    },
    saveMyPrinters() {
      let formData = new FormData();

      let error = false
      this.myPrinters.forEach((myPrinter) => {
        if(myPrinter.max_dimensions.x === '' || myPrinter.max_dimensions.y === '' || myPrinter.max_dimensions.z === '' || myPrinter.quantity === ''
        || myPrinter.max_dimensions.x <= 0 || myPrinter.max_dimensions.y <= 0 || myPrinter.max_dimensions.z <= 0 || myPrinter.quantity <= 0) {
          error = true
        }
      })

      if(!error) {
        this.myPrinters.forEach((myPrinter) => {
          formData.append("printers[" + myPrinter.id + "][id]", myPrinter.id);
          formData.append("printers[" + myPrinter.id + "][quantity]", myPrinter.quantity);
          formData.append("printers[" + myPrinter.id + "][max_dimensions][x]", myPrinter.max_dimensions.x);
          formData.append("printers[" + myPrinter.id + "][max_dimensions][y]", myPrinter.max_dimensions.y);
          formData.append("printers[" + myPrinter.id + "][max_dimensions][z]", myPrinter.max_dimensions.z);
          myPrinter.materials.forEach((material) => {
            if(material.selected) {
              formData.append("printers[" + myPrinter.id + "][materials][]", material.id);
            }
          })
        })

        this.$http.post(process.env.VUE_APP_API_BASE_URL + 'printer/updatePrinters', formData, {credentials: true})
            .then(() => {
              this.$notify({ type: 'success', text: 'Imprimantes enregistrés' })
            })
      } else {
        this.$notify({ type: 'error', text: 'Il faut remplir tout les champs et ce doit être supérieur à 0' })
      }

    },
    removeMyPrinter(id) {
      this.myPrinters = this.myPrinters.filter(myPrinter => myPrinter.id != id);
      },
  }

  }
</script>
