
export default {
  namespaced: true,
    state: {
        printers: [],
    },
    mutations: {
        UPDATE_PRINTERS(state, payload = null) {
            state.printers = payload
        },
        UPDATE_PRINTER(state, payload = null) {
            state.printers[payload.i] = payload.printer
        },
        RESET_PRINTERS(state) {
            state.printers =  []
        }
    },
    actions: {
        updatePrinters({commit}, payload = null) {
            commit('UPDATE_PRINTERS', payload)
        },
        updatePrinter({commit}, payload = null) {
            commit('UPDATE_PRINTER', payload)
        },
        // RESET
        resetPrinters({commit}) {
            commit('RESET_PRINTERS')
        },
    },
}
