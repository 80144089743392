
export default {
  namespaced: true,
    state: {
        scanners: [],
    },
    mutations: {
        UPDATE_SCANNERS(state, payload = null) {
            state.scanners = payload
        },
        UPDATE_SCANNER(state, payload = null) {
            state.scanners[payload.i] = payload.scanner
        },
        RESET_SCANNERS(state) {
            state.scanners =  []
        }
    },
    actions: {
        updateScanners({commit}, payload = null) {
            commit('UPDATE_SCANNERS', payload)
        },
        updateScanner({commit}, payload = null) {
            commit('UPDATE_SCANNER', payload)
        },
        // RESET
        resetScanners({commit}) {
            commit('RESET_SCANNERS')
        },
    },
}
