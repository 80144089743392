<template>
    <div class="w-lg-25 mt-lg-5 ps-3 b-4-radius account-menu-content"
         :class="[menuIsOpen == true ? 'menu-is-open' : '']">
      <div>
        <div class="mb-4">
          <h4 class="h4 mb-0 uc-first d-inline-block">{{ firstname }}</h4>
          <h4 class="h4 mb-0 uc-first d-inline-block ms-1">{{ lastname }}</h4>
          <span class="t3 d-block color-secondary overflow-x-hidden">{{ email }}</span>
        </div>
      </div>
      <div class="mt-2">
        <router-link v-if="!this.isChecked" to="/maker/account" class="h6 color-secondary mb-1 cursor-p d-block">Tableau de bord</router-link>
        <router-link v-if="this.isChecked" to="/maker" class="h6 color-secondary mb-1 cursor-p d-block">Tableau de bord</router-link>
        <div v-if="isMakerRdvMenuShowed">
          <router-link to="/maker/mon-rendez-vous" class="h6 color-secondary mb-1 cursor-p d-block">Mon rendez-vous</router-link>
        </div>
        <router-link :to="this.isChecked ? this.hasPrinters ? '/maker/mes-imprimantes' : '/maker/imprimantes' : '#'" :class="!this.isChecked ? 'link-disabled' : ''" class="h6 color-secondary mb-1 cursor-p d-block">Mes technologies</router-link>
        <router-link :to="this.isChecked ? '/maker/mes-prix-dimpressions' : '#'" :class="!this.isChecked ? 'link-disabled' : ''" class="h6 color-secondary mb-1 cursor-p d-block">Mes prix d'impressions</router-link>
        <router-link to="/maker/mon-atelier" class="h6 color-secondary mb-1 cursor-p d-block">Mon atelier</router-link>
        <router-link :to="this.isChecked ? '/maker/ma-societe' : '#'" :class="!this.isChecked ? 'link-disabled' : ''" class="h6 color-secondary mb-1 cursor-p d-block">Ma société</router-link>
        <router-link to="/maker/modifier-mot-de-passe" class="h6 color-secondary mb-1 cursor-p d-block">Mon mot de passe</router-link>
        <br>
        <div class="h6 color-secondary mb-1 cursor-p hover-c-text" @click="logoutAccount">Déconnexion</div>
      </div>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: 'MakerMenu',
  data() {
    return {
      menuIsOpen: false,
      urlBase: process.env.VUE_APP_APP_BASE_URL
    }
  },
  computed: {
    ...mapState("authentication", {
      isMaker: (state) => state.isMaker,
      isChecked: (state) => state.isChecked,
      firstname: (state) => state.firstname,
      lastname: (state) => state.lastname,
      email: (state) => state.email,
      isMakerRdvMenuShowed: (state) => state.isMakerRdvMenuShowed,
      hasPrinters: (state) => state.hasPrinters,
    })
  },
  methods: {
    ...mapActions("authentication", [
      "logout",
      "setConnected",
    ]),
    logoutAccount: function () {
      this.logout()
          .then(() => {
            window.location.href = process.env.VUE_APP_WWW_BASE_URL
          })
    },
  }
}
</script>