import Vue from 'vue'

export default {
  namespaced: true,
    state: {
        followId: null,
        printFile: {
            file: null,
            filename: null,
            extension: null,
            dimensions: {
                x: null,
                y: null,
                z: null
            },
            volume: null,
            volumeDisplay: null,
            application: {
                id: null,
                name: null,
            },
            technology: {
                id: null,
                name: null,
                description: null
            },
            material: {
                id: null,
                name: null,
                basePrice: null
            },
            infoMaterial: {
                id: null,
                name: null,
                basePrice: null
            },
            color: {
                id: null,
                name: null,
                plusPrice: null,
                hexadecimalCode: null
            },
            layer: {
                id: null,
                height: null,
                plusPrice: null
            },
            infoLayer: {
                id: null,
                height: null,
                plusPrice: null
            },
            finishing: {
                id: null,
                name: null,
                description: null,
                price: null
            },
            quantity: 1,
            coefDimensions: null,
            // weight: null,
            // density: null,
            //color: '#760039',
            // finishing: [],
            // finishingChecked: [],
            // technologyLabel: null,
            // material: null,
            // materialLabel: null,
            // filling: null,
            // heightPrinting: null,
            // numberOfParts: null,
            // makersList: [],
            // //maker: null,
            // priceTaxEcl: 0,
            // priceTaxInc: 0,
            // state: 'open', // open /
        
            // for affichage
            referentialItems: [],
            materialItems: [],
            technologieCategoryList: [],
            //materialItemsByTechnologieCategory: [],
            colorsList: [],
            layersList: [],
            finishingList: [],
            infoTechnology: null,
            screenshot: null,
            oldApplication: null,

        },
    },
    mutations: {
        UPDATE_FILE(state, payload = null) {
            state.printFile.file = payload
        },
        UPDATE_FILENAME(state, payload = null) {
            state.printFile.filename = payload
        },
        UPDATE_EXTENSION(state, payload = null) {
            state.printFile.extension = payload
        },
        UPDATE_DIMENSIONS_X(state, payload = null) {
            state.printFile.dimensions.x = payload
        },
        UPDATE_DIMENSIONS_Y(state, payload = null) {
            state.printFile.dimensions.y = payload
        },
        UPDATE_DIMENSIONS_Z(state, payload = null) {
            state.printFile.dimensions.z = payload
        },
        UPDATE_VOLUME(state, payload = null) {
            state.printFile.volume = payload
        },
        UPDATE_VOLUME_DISPLAY(state, payload = null) {
            state.printFile.volumeDisplay = payload
        },
        UPDATE_APPLICATION(state, payload = null) {
            state.printFile.application = payload
        },
        UPDATE_TECHNOLOGY(state, payload = null) {
            state.printFile.technology = payload
        },
        UPDATE_INDEX_SELECT(state, payload = null) {
            state.printFile.indexSelect = payload
        },
        UPDATE_INFO_TECHNOLOGY(state, payload = null) {
            state.printFile.infoTechnology = payload
        },
        UPDATE_MATERIAL(state, payload = null) {
            state.printFile.material = payload
        },
        UPDATE_INFO_MATERIAL(state, payload = null) {
            state.printFile.infoMaterial = payload
        },
        UPDATE_COLOR(state, payload = null) {
            state.printFile.color = payload
        },
        UPDATE_LAYER(state, payload = null) {
            state.printFile.layer = payload
        },
        UPDATE_INFO_LAYER(state, payload = null) {
            state.printFile.infoLayer = payload
        },
        UPDATE_FINISHING(state, payload = null) {
            state.printFile.finishing = payload
        },
        UPDATE_QUANTITY(state, payload = null) {
            state.printFile.quantity = payload
        },
        // for affichage
        UPDATE_REFERENTIAL_ITEMS(state, payload = null) {
            state.printFile.referentialItems = payload
        },
        UPDATE_FOLLOW_ID(state, payload = null) {
            state.followId = payload
        },
        UPDATE_MATERIAL_ITEMS(state, payload = null) {
            state.printFile.materialItems = payload
        },
        UPDATE_TECHNOLOGIE_CATEGORY_LIST(state, payload = null) {
            state.printFile.technologieCategoryList = payload
        },
        /*
        UPDATE_MATERIAL_ITEMS_BY_TECHNOLOGIE_CATEGORY(state, payload = null) {
            state.printFile.materialItemsByTechnologieCategory = payload
        },
        */
        UPDATE_COLORS_LIST(state, payload = null) {
            state.printFile.colorsList = payload
        },
        UPDATE_LAYERS_LIST(state, payload = null) {
            state.printFile.layersList = payload
        },
        UPDATE_FINISHING_LIST(state, payload = null) {
            state.printFile.finishingList = payload
        },
        UPDATE_SCREENSHOT(state, payload = null) {
            state.screenshot = payload
        },
        UPDATE_OLD_APPLICATION(state, payload = null) {
            state.printFile.oldApplication = payload
        },


        RESET_PRINT(state) {
            state.printFile = {
                file: null,
                filename: null,
                extension: null,
                dimensions: {
                    x: null,
                    y: null,
                    z: null
                },
                volume: null,
                volumeDisplay: null,
                application: {
                    id: null,
                    name: null,
                },
                technology: {
                    id: null,
                    name: null,
                    description:null
                },
                material: {
                    id: null,
                    name: null,
                    basePrice: null
                },
                color: {
                    id: null,
                    name: null,
                    plusPrice: null,
                    hexadecimalCode: null
                },
                layer: {
                    id: null,
                    height: null,
                    plusPrice: null
                },
                finishing: {
                    id: null,
                    name: null,
                    description: null,
                    price: null
                },
                quantity: null,
                referentialItems: [],
                materialItems: [],
                technologieCategoryList: [],
                //materialItemsByTechnologieCategory: [],
                colorsList: [],
                layersList:[],
                finishingList: [],
                screenshot: null ,
                oldApplication: null,
                infoTechnologyVisible: false,
                infoMaterialVisible: false,
                infoLayerVisible: false,
                indexSelect:0
            }
        }
    },
    actions: {     
        updateFile({commit}, payload = null) {
            commit('UPDATE_FILE', payload)
        },
        updateFilename({commit}, payload = null) {
            commit('UPDATE_FILENAME', payload)
        },
        updateExtension({commit}, payload = null) {
            commit('UPDATE_EXTENSION', payload)
        },
        updateDimensionX({commit}, payload = null) {
            commit('UPDATE_DIMENSIONS_X', payload)
        },
        updateDimensionY({commit}, payload = null) {
            commit('UPDATE_DIMENSIONS_Y', payload)
        },
        updateDimensionZ({commit}, payload = null) {
            commit('UPDATE_DIMENSIONS_Z', payload)
        },
        updateVolume({commit}, payload = null) {
            commit('UPDATE_VOLUME', payload)
        },
        updateVolumeDisplay({commit}, payload = null) {
            commit('UPDATE_VOLUME_DISPLAY', payload)
        },
        updateApplication({commit}, payload = null) {
            commit('UPDATE_APPLICATION', payload)
        },
        updateTechnology({commit}, payload = null) {
            commit('UPDATE_TECHNOLOGY', payload)
        },
        updateIndexSelect({commit}, payload = null) {
            commit('UPDATE_INDEX_SELECT', payload)
        },
        updateInfoTechnology({commit}, payload = null) {
            commit('UPDATE_INFO_TECHNOLOGY', payload)
        },
        updateMaterial({commit}, payload = null) {
            commit('UPDATE_MATERIAL', payload)  
        },
        updateInfoMaterial({commit}, payload = null) {
            commit('UPDATE_INFO_MATERIAL', payload)  
        },    
        updateColor({commit}, payload = null) {
            commit('UPDATE_COLOR', payload)
        },
        updateLayer({commit}, payload = null) {
            commit('UPDATE_LAYER', payload)
        },
        updateInfoLayer({commit}, payload = null) {
            commit('UPDATE_INFO_LAYER', payload)
        },
        updateFinishing({commit}, payload = null) {
            commit('UPDATE_FINISHING', payload)
        },
        updateQuantity({commit}, payload = null) {
            commit('UPDATE_QUANTITY', payload)
        },
        // for affichage
        updateReferentialItems({commit}, payload = null) {
            commit('UPDATE_REFERENTIAL_ITEMS', payload)
        },
        updateFollowId({commit}, payload = null) {
            commit('UPDATE_FOLLOW_ID', payload)
        },
        updateMaterialItems({commit}, payload = null) {
            commit('UPDATE_MATERIAL_ITEMS', payload)
        },
        updateTechnologieCategoryList({commit}, payload = null) {
            commit('UPDATE_TECHNOLOGIE_CATEGORY_LIST', payload)
        },
        /*
        updateMaterialItemsByTechnologieCategory({commit}, payload = null) {
            commit('UPDATE_MATERIAL_ITEMS_BY_TECHNOLOGIE_CATEGORY', payload)
        },*/
        updateColorsList({commit}, payload = null) {
            commit('UPDATE_COLORS_LIST', payload)
        },
        updateLayersList({commit}, payload = null) {
            commit('UPDATE_LAYERS_LIST', payload)
        },
        updateFinishingList({commit}, payload = null) {
            commit('UPDATE_FINISHING_LIST', payload)
        },
        updateScreenshot({commit}, payload = null) {
            commit('UPDATE_SCREENSHOT', payload)
        },
        updateOldApplication({commit}, payload = null) {
            commit('UPDATE_OLD_APPLICATION', payload)
        },

        // RESET
        resetPrint({commit}) {
            commit('RESET_PRINT')
        },
        resetPrintByStep2({commit}) {
            commit('UPDATE_TECHNOLOGIE_CATEGORY_LIST', [])
            commit('UPDATE_MATERIAL_ITEMS_BY_TECHNOLOGIE_CATEGORY', [])
         
            commit('UPDATE_MATERIAL_ITEMS', [])
            commit('UPDATE_MATERIAL', {id: null,name:null,basePrice:null})  

            commit('UPDATE_COLORS_LIST', [])
            commit('UPDATE_COLOR', {id:null,name:null,plusPrice:null,hexadecimalCode:null})

            commit('UPDATE_LAYERS_LIST', [])
            commit('UPDATE_LAYER', {id:null,height:null,plusPrice:null})
            commit('UPDATE_INFO_LAYER', {id:null,height:null,plusPrice:null})

            commit('UPDATE_FINISHING_LIST', [])
            commit('UPDATE_FINISHING', {id: null,name: null,description: null,price: null})
        },
        hideInfoLayer({commit}) {
            commit('UPDATE_INFO_LAYER', {id:null,height:null,plusPrice:null})
        },
        //
        savePrint({state}, payload) {
            let formData = new FormData();

            formData.append("application_id", state.printFile.application.id);
            formData.append("color_id", state.printFile.color.id);
            formData.append("layer_id", state.printFile.layer.layerId);
            formData.append("material_id", state.printFile.material.id);

            formData.append("dim[x]", state.printFile.dimensions.x);
            formData.append("dim[y]", state.printFile.dimensions.y);
            formData.append("dim[z]", state.printFile.dimensions.z);

            formData.append("quantity", state.printFile.quantity);

            formData.append("technology_id", state.printFile.technology.id);
            formData.append("file", state.printFile.file);
     
            if(payload.enableToken !== undefined) {
                formData.append('enableToken', payload.enableToken)
            }

            return Vue.http.post(process.env.VUE_APP_API_BASE_URL + "application/save", formData, {credentials: true});
        }
    },
    getters: {
        sortMaterialItemsAlphabetically: state => {
            return state.printFile.materialItems.sort(function (a, b) {
                return a.name > b.name ? 1 : -1
            });
        },
        /*
        sortMaterialItemsByTechnologyAlphabetically: state => {
            return state.printFile.materialItemsByTechnologieCategory.sort(function (a, b) {
                return a.name > b.name ? 1 : -1
            });
        },*/
        sortColorsItemsByPrice: state => {
            return state.printFile.colorsList.sort(function (a, b) {
                return a.plusPrice - b.plusPrice;
            });
        },
        sortLayersItemsByPrice: state => {
            return state.printFile.layersList.sort(function (a, b) {
                return a.plusPrice - b.plusPrice;
            });
        },
        sortFinishingItemsByPrice: state => {
            return state.printFile.finishingList.sort(function (a, b) {
                return a.price - b.price;
            });
        },
        priceHtShopBar: state => {
            let total = 0
            if(state.printFile.material.id !== null) {
                total += state.printFile.material.basePrice
            }
            if(state.printFile.color.id !== null) {
                total += state.printFile.color.plusPrice
            }
            if(state.printFile.layer.id !== null) {
                total += state.printFile.layer.plusPrice
            }
            if(state.printFile.finishing.id !== null) {
                total += state.printFile.finishing.price
            }
            total = total * state.printFile.quantity
            return total
        }
    }
}
