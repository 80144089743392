export default {
    namespaced: true,
    state: {
        step: null,
        paymentThanks: false,
        // formBillingIsValid: null,
        orderReference: null,
        orderBtnPayIsLoading: false,
        needShipping: true,
        shippingConfiguration: '',
        addressLabel: '',
        isDeposit: false,
        deposit: 0,
        timeline: '',
        isQuotation: false,
        billing: null,
    },
    mutations: {
        UPDATE_STEP(state, payload = null) {
            state.step = payload
        },
        UPDATE_PAYMENT_THANKS(state, payload = null) {
            state.paymentThanks = payload
        },
        UPDATE_ORDER_REFERENCE(state, payload = null) {
            state.orderReference = payload
        },
        UPDATE_ORDER_BTN_PAY_IS_LOADING(state, payload = null) {
            state.orderBtnPayIsLoading = payload
        },
        UPDATE_NEED_SHIPPING(state, payload = null) {
            state.needShipping = payload
        },
        UPDATE_SHIPPING_CONFIGURATION(state, payload = null) {
            state.shippingConfiguration = payload
        },
        UPDATE_ADDRESS_LABEL(state, payload = null) {
            state.addressLabel = payload
        },
        UPDATE_DEPOSIT(state, payload = null) {
            state.deposit = payload
        },
        UPDATE_IS_DEPOSIT(state, payload = null) {
            state.isDeposit = payload
        },
        UPDATE_TIMELINE(state, payload = null) {
            state.timeline = payload
        },
        UPDATE_IS_QUOTATION(state, payload = null) {
            state.isQuotation = payload
        },
        UPDATE_BILLING(state, payload = null) {
            state.billing = payload
        },
    },
    actions: {
        updateStep({commit}, payload = null) {
            commit('UPDATE_STEP', payload)
        },
        updatePaymentThanks({commit}, payload = null) {
            commit('UPDATE_PAYMENT_THANKS', payload)
        },
        updateOrderReference({commit}, payload = null) {
            commit('UPDATE_ORDER_REFERENCE', payload)
        },
        updateOrderBtnPayIsLoading({commit}, payload = null) {
            commit('UPDATE_ORDER_BTN_PAY_IS_LOADING', payload)
        },
        updateNeedShipping({commit}, payload = null) {
            commit('UPDATE_NEED_SHIPPING', payload)
        },
        updateShippingConfiguration({commit}, payload = null) {
            commit('UPDATE_SHIPPING_CONFIGURATION', payload)
        },
        updateAddressLabel({commit}, payload = null) {
            commit('UPDATE_ADDRESS_LABEL', payload)
        },
        updateDeposit({commit}, payload = null) {
            commit('UPDATE_DEPOSIT', payload)
        },
        updateIsDeposit({commit}, payload = null) {
            commit('UPDATE_IS_DEPOSIT', payload)
        },
        updateTimeline({commit}, payload = null) {
            commit('UPDATE_TIMELINE', payload)
        },
        updateIsQuotation({commit}, payload = null) {
            commit('UPDATE_IS_QUOTATION', payload)
        },
        updateBilling({commit}, payload = null) {
            commit('UPDATE_BILLING', payload)
        }
    },
  }
  
  