<template>
<div>
  <navigation-site :navThemeTransparent="false"></navigation-site>
  <div class="min-h-80vh">
    <div class="container pt-nav pb-foot">

      <div v-if="changePasswordSuccess == false" class="row justify-content-center mt-5 pt-5 appearContentFromBot2">
        <div class="col-md-6 col-xl-5 mt-5">

          <h1 class="h2 text-center">vous connecter</h1>

          <form class="row g-2 mt-5" @submit.prevent="() => send">
            <div class="col-12">
              <div class="text--error mb-1">{{ errorPassword }}</div>
            </div>
            <div class="col-md-12">
              <input type="password" v-model="password1" placeholder="Entrer votre nouveau mot de passe" class="form-control" @keyup.enter="send">
              <!-- <div class="t3 mt-1 color-secondary">
                    Le mot de passe doit être de 8 caractères minimum et contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial. Exemple : @Mi12Lg09#
              </div> -->
            </div>
            <div class="col-md-12">
              <input type="password" v-model="password2" placeholder="Entrer a nouveau votre mot de passe" class="form-control" @keyup.enter="send">
            </div>
            <div class="mt-4">
              <button class="btn-u3dm btn-u3dm--primary w-100" @click="send" @keyup.enter="send">Changer</button>
            </div>
          </form>              
        </div>
      </div>

      <div v-if="changePasswordSuccess == true" class="mt-5 pt-5 appearContentFromBot2">
        <div class="mt-5 pt-5">
          <div class="text-center h5">Votre mot de passe à été mofifiée avec succès</div>
          <div class="text-center mt-4">
            <router-link to="/account" class="btn-u3dm btn-u3dm--primary">Vous connecter</router-link>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
</template>

<script>
import NavigationSite from "../ui/NavigationSite.vue"

export default {
  name: "PasswordReset",
  components: { NavigationSite },
  data() {
    return {
      password1: null,
      password2: null,
      errorPassword: null,
      changePasswordSuccess: false
    }
  },
  
  methods: {
    send() {
      
      let formData = new FormData();    
     
      formData.append('password', this.password1);
      formData.append('password_secondary', this.password2); 

      // user/reset-password/tkfPqEE4r5PUyy5G2ZM_eEuxxwu8a3sZjDsZala2omg
      this.$http.post(process.env.VUE_APP_API_BASE_URL + "user/reset-password/" + this.$route.params.resetToken, formData).then(() => {
        // console.log(response)
        this.changePasswordSuccess = true
      }
      , (error) => {
          // console.log(error.body)
          this.errorPassword = error.body.password
          this.changePasswordSuccess = false
        
      })

    },
  },
}
</script>