
export default {
  namespaced: true,
    state: {
        softwares: [],
    },
    mutations: {
        UPDATE_SOFTWARES(state, payload = null) {
            state.softwares = payload
        },
        UPDATE_SOFTWARE(state, payload = null) {
            state.softwares[payload.i] = payload.software
        },
        RESET_SOFTWARES(state) {
            state.softwares =  []
        }
    },
    actions: {
        updateSoftwares({commit}, payload = null) {
            commit('UPDATE_SOFTWARES', payload)
        },
        updateSoftware({commit}, payload = null) {
            commit('UPDATE_SOFTWARE', payload)
        },
        // RESET
        resetSoftwares({commit}) {
            commit('RESET_SOFTWARES')
        },
    },
}
