import Vue from 'vue'
import Vuex from 'vuex'

import prospectClient from './prospectClient'
import prospectSubscription from './prospectSubscription'
import prospectMaker from './prospectMaker'
import authentication from './authentication'
import projectDesign from './projectDesign'
import print from './print'
import printManager from './printManager'
import orderManager from './orderManager'
import order from './order'
import shop from './shop'
import stripeManager from './stripeManager'
import test from './test.js'

import routeRedirect from './routeRedirect.js'
import printers from "./printers";
import scanners from "./scanners";
import softwares from "./softwares";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    prospectClient,
    prospectSubscription,
    prospectMaker,
    authentication,
    projectDesign,
    print,
    printers,
    scanners,
    softwares,
    printManager,
    order,
    orderManager,
    shop,
    stripeManager,
    test,
    routeRedirect
  }
})
