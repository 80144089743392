import Vue from 'vue'


const WAYS_TAB = 
  {
    'IMPRESSION_A': ['name', 'projectType', 'projectAskOne', 'fileMandatory', 'fields', 'description', 'zipcode', 'deliveryTime', 'recap', 'thanks'],
    'IMPRESSION_B': ['name', 'projectType', 'projectAskOne', 'projectAskTwo', 'fileMandatory', 'description', 'fields', 'zipcode', 'deliveryTime', 'recap', 'thanks'],
    'IMPRESSION_C': ['name', 'projectType', 'projectAskOne', 'projectAskTwo', 'dim', 'howScan', 'zipcode', 'description', 'fields', 'file', 'deliveryTime', 'recap', 'thanks'],
    'MODELISATION_A': ['name', 'projectType', 'projectAskFive', 'fileMandatory', 'description', 'fields', 'projectAskFour', 'deliveryTime', 'recap', 'thanks'],
    'MODELISATION_B': ['name', 'projectType', 'projectAskFive', 'fileMandatory', 'description', 'fields', 'projectAskFour', 'zipcode', 'deliveryTime', 'recap', 'thanks'],
    'NUMERISATION_A': ['name', 'projectType', 'projectAskFive', 'dim', 'howScan', 'zipcode', 'fields', 'description', 'file', 'wantDevis', 'deliveryTime', 'recap', 'thanks'],
    // 'REPRISE_PROJECT': ['reprise']
  }


export default {
  namespaced: true,
    state: {
      currentGoogleTag: '',
      currentGoogleRecapTag: '',
      returnToRecap: false,
      steps: {
        stepNumActive: 0,
        stepNameActive: '',
        stepList: '',
        wayCurrent: 'IMPRESSION_A'
      },
      projectDesign: {
        name: '',
          projectType: '',
          deliveryTime: '',
          description: '',
          fields: [],
          dim: {
            x: '',
            y: '',
            z: '',
          },
          zipcode: '',
          file: [],
          savedFile: [],
          scanOnSite: false,
          howScan: '',
          quoteOptionForPrinting: false,
          projectId: '',
          returnReason: '',
          status: '',
          seeProjectAfterSave: {
            token: '',
            reference: ''
          }
      }
    },
    mutations: {
      UPDATE_CURRENT_GOOGLE_TAG(state, payload = '') {
        state.currentGoogleTag = payload
      },
      UPDATE_CURRENT_GOOGLE_RECAP_TAG(state, payload = '') {
        state.currentGoogleRecapTag = payload
      },
      CHANGE_NAME(state, payload = '') {
        state.projectDesign.name = payload
      },
      CHANGE_PROJECT_TYPE(state, payload = '') {
        state.projectDesign.projectType = payload
      },
      CHANGE_DELIVERY_TIME(state, payload = '') {
        state.projectDesign.deliveryTime = payload
      },
      CHANGE_DESCRIPTION(state, payload = '') {
        state.projectDesign.description = payload
      },
      CHANGE_FIELDS(state, payload = []) {
        state.projectDesign.fields = payload
      },
      CHANGE_DIM(state, payload = '') {
        state.projectDesign.dim.x = payload.x
        state.projectDesign.dim.y = payload.y
        state.projectDesign.dim.z = payload.z
      },
      CHANGE_ZIPCODE(state, payload = '') {
        state.projectDesign.zipcode = payload
      },
      CHANGE_SAVED_FILE(state, payload = []) {
        state.projectDesign.savedFile = payload
      },
      CHANGE_FILE(state, payload = []) {
        state.projectDesign.file = payload
      },
      CHANGE_SCAN_ON_SITE(state, payload = false) {
        state.projectDesign.scanOnSite = payload
      },
      CHANGE_STATUS(state, payload = '') {
        state.projectDesign.status = payload
      },
      UPDATE_PROJECT_ID(state, payload = '') {
        state.projectDesign.projectId = payload
      },
      UPDATE_RETURN_REASON(state, payload = '') {
        state.projectDesign.returnReason = payload
      },
      UPDATE_QUOTE_OPTION_FOR_PRINTING(state, payload = false) {
        state.projectDesign.quoteOptionForPrinting = payload
      },
      UPDATE_HOW_SCAN(state, payload = '') {
        state.projectDesign.howScan = payload
      },
      UPDATE_WAY_CURRENT(state, payload = '') {
        state.steps.wayCurrent = payload
      },
      ADAPT_STEPS_LIST(state, payload = null) {
        state.steps.stepList = WAYS_TAB[payload]
      },
      ADAPT_STEP_NUM_ACTIVE(state, payload = null) {
        state.steps.stepNumActive = payload
      },
      ADAPT_STEP_NAME_ACTIVE(state, payload) {
        state.steps.stepNameActive = payload
      },
      INCREMENT(state, payload = 1) {
        state.steps.stepNumActive += payload
      },
      DECREMENT(state) {
        state.steps.stepNumActive -= 1
      },
      RESET_CURRENT_GOOGLE_TAG(state) {
        state.currentGoogleTag = ''
      },
      RESET_CURRENT_GOOGLE_RECAP_TAG(state) {
        state.currentGoogleRecapTag = ''
      },
      RESET_PROJECT_DESIGN(state) {
        state.projectDesign = {
          name: '',
          projectType: '',
          deliveryTime: '',
          description: '',
          fields: [],
          dim: {
            x: '',
            y: '',
            z: '',
          },
          zipcode: '',
          file: [],
          savedFile: [],
          scanOnSite: false,
          howScan: '',
          quoteOptionForPrinting: false,
          projectId: '',
          status: '',
          seeProjectAfterSave: {
            token: '',
            reference: ''
          }
        }
      },
      RESET_STEPS(state) {
            state.steps = {
              stepNumActive: 0,
              stepNameActive: '',
              stepList: [],
              wayCurrent: 'IMPRESSION_A'
            }
      },
      ADAPT_WAY(state, payload) {

        if (payload.key == 'projectType') {

          if (payload.value == 1) {
            state.steps.stepList = WAYS_TAB.IMPRESSION_C
            state.steps.wayCurrent = 'IMPRESSION_C'
          } else {
            state.steps.stepList = WAYS_TAB.NUMERISATION_A
            state.steps.wayCurrent = 'IMPRESSION_A'
          }
        } 

        if ( payload.key == 'projectAskOne') {

          if (payload.value) {
            state.projectDesign.dim.x = '';
            state.projectDesign.dim.y = '';
            state.projectDesign.dim.z = '';
            state.steps.stepList = WAYS_TAB.IMPRESSION_A
            state.steps.wayCurrent = 'IMPRESSION_A'
          } else {

            state.steps.stepList = WAYS_TAB.IMPRESSION_C
            state.steps.wayCurrent = 'IMPRESSION_C'
          }
        }

        if ( payload.key == 'projectAskTwo') {

          if (payload.value) {
            state.steps.stepList = WAYS_TAB.IMPRESSION_C
            state.steps.wayCurrent = 'IMPRESSION_C'

          } else {

            state.steps.stepList = WAYS_TAB.IMPRESSION_B
            state.steps.wayCurrent = 'IMPRESSION_B'
          }
        }

        if ( payload.key == 'projectAskFour') {

          if (payload.value) {
            state.steps.stepList = WAYS_TAB.MODELISATION_B
            state.steps.wayCurrent = 'MODELISATION_B'

          } else {

            state.steps.stepList = WAYS_TAB.MODELISATION_A
            state.steps.wayCurrent = 'MODELISATION_A'
          }
        }

        if ( payload.key == 'projectAskFive' ) {

          if (payload.value == 1) {
            state.steps.stepList = WAYS_TAB.NUMERISATION_A
            state.steps.wayCurrent = 'NUMERISATION_A'
          } else {
            state.projectDesign.dim.x = '';
            state.projectDesign.dim.y = '';
            state.projectDesign.dim.z = '';
            state.steps.stepList = WAYS_TAB.MODELISATION_A
            state.steps.wayCurrent = 'MODELISATION_A'
          }
        }
      },
      RETURN_TO_RECAP(state, payload) {
        state.returnToRecap = payload;
      },
      UPDATE_SEE_PROJECT_AFTER_SAVE(state, payload) {
        // console.log(state.projectDesign.seeProjectAfterSave)
        state.projectDesign.seeProjectAfterSave.token = payload.token
        state.projectDesign.seeProjectAfterSave.reference = payload.reference
      }
    },
    actions: {
      updateCurrentGoogleTag({commit}, payload) {
        commit('UPDATE_CURRENT_GOOGLE_TAG', payload)
      },
      updateCurrentGoogleRecapTag({commit}, payload) {
        commit('UPDATE_CURRENT_GOOGLE_RECAP_TAG', payload)
      },
      updateName({commit}, payload) {
        commit('CHANGE_NAME', payload)
      },
      updateProjectType({commit}, payload) {
        commit('CHANGE_PROJECT_TYPE', payload)
      },
      updateDeliveryTime({commit}, payload) {
        commit('CHANGE_DELIVERY_TIME', payload)
      },
      updateDescription({commit}, payload) {
        commit('CHANGE_DESCRIPTION', payload)
      },
      updateFields({commit}, payload) {
        commit('CHANGE_FIELDS', payload)
      },
      updateDim({commit}, payload) {
        commit('CHANGE_DIM', payload)
      },
      updateZipcode({commit}, payload) {
        commit('CHANGE_ZIPCODE', payload)
      },
      updateFile({commit}, payload) {
        commit('CHANGE_FILE', payload)
      },
      updateSavedFile({commit}, payload) {
        commit('CHANGE_SAVED_FILE', payload)
      },
      updateScanOnSite({commit}, payload) {
        commit('CHANGE_SCAN_ON_SITE', payload)
      },
      updateWayCurrent({commit}, payload) {
        commit('UPDATE_WAY_CURRENT', payload)
      },
      updateStepsList({commit}, payload) {
        commit('ADAPT_STEPS_LIST', payload)
      },
      updateStepNumActive({commit}, payload) {
        commit('ADAPT_STEP_NUM_ACTIVE', payload)
      },
      goOnStepByName({commit}, payload) {
        commit('ADAPT_STEP_NAME_ACTIVE', payload)
      },
      updateQuoteOptionForPrinting({commit}, payload) {
        commit('UPDATE_QUOTE_OPTION_FOR_PRINTING', payload)
      },
      updateHowScan({commit}, payload) {
        commit('UPDATE_HOW_SCAN', payload)
      },
      updateProjectId({commit}, payload) {
        commit('UPDATE_PROJECT_ID', payload)
      },
      updateProjectDesign({commit}, payload) {
        commit('CHANGE_NAME', payload.name)
        commit('CHANGE_PROJECT_TYPE', payload.type)
        commit('CHANGE_DELIVERY_TIME', payload['delivery_time'])
        commit('CHANGE_DESCRIPTION', payload.description)
        let tab = []
        if (typeof payload.fields[0] == 'object') { 
            payload.fields.forEach(elt => {
              tab.push(elt.id)
            });
          } else {
            tab = payload.fields
          }

        commit('CHANGE_FIELDS', tab)
        commit('CHANGE_DIM', payload.dimensions)
        commit('CHANGE_ZIPCODE', payload.zipcode)
        commit('CHANGE_SAVED_FILE', payload.files)
        commit('CHANGE_SCAN_ON_SITE', payload.scanOnSite)
        commit('CHANGE_STATUS', payload.status)
        commit('UPDATE_HOW_SCAN', payload['how_scan'])
        commit('UPDATE_QUOTE_OPTION_FOR_PRINTING', payload.quoteOptionForPrinting)
        commit('UPDATE_PROJECT_ID', payload.id)
        commit('UPDATE_RETURN_REASON', payload.return_reason)
      },
      increment ({commit, state}) {
        commit('INCREMENT')
        commit('ADAPT_STEP_NAME_ACTIVE', state.steps.stepList[state.steps.stepNumActive])
        if(state.steps.stepNameActive === 'recap') {
          window.location.href = '#recap'
        } else {
          window.location.href = '#' + state.steps.stepNumActive
        }
      },
      decrement (/*{commit, state}*/) {
        history.back()
      },
      decrementWithoutAnchor ({commit, state}/*, payload*/) {
        // if(payload.oldStepNumActive === 'recap') {
        //   commit('ADAPT_STEP_NUM_ACTIVE', state.steps.stepList[state.steps.stepList.length - 1])
        // } else {
          commit('DECREMENT')
        // }
        commit('ADAPT_STEP_NAME_ACTIVE', state.steps.stepList[state.steps.stepNumActive])
      },
      goOnStep({commit, state}) {
        commit('ADAPT_STEP_NAME_ACTIVE', state.steps.stepList[state.steps.stepNumActive])
      },
      resetCurrentGoogleTag({commit}) {
        commit('RESET_CURRENT_GOOGLE_TAG')
      },
      resetCurrentGoogleRecapTag({commit}) {
        commit('RESET_CURRENT_GOOGLE_RECAP_TAG')
      },
      resetProject({commit}) {
        commit('RESET_PROJECT_DESIGN')
      },
      resetSteps({commit}) {
        commit('RESET_STEPS')
      },
      adaptWay({commit}, payload) {
        commit('ADAPT_WAY', payload)
      },
      saveProject({state, getters}, payload) {

        let formData = new FormData();

        formData.append("name", state.projectDesign.name);
        formData.append("projectType", state.projectDesign.projectType);
        formData.append("deliveryTime", state.projectDesign.deliveryTime);
        formData.append("description", state.projectDesign.description);

        state.projectDesign.fields.forEach((element) => {
          formData.append("fields[]", element);
        });
        
        formData.append("dim[x]", state.projectDesign.dim.x);
        formData.append("dim[y]", state.projectDesign.dim.y);
        formData.append("dim[z]", state.projectDesign.dim.z);
        formData.append("zipcode", state.projectDesign.zipcode);
        formData.append("scanOnSite", state.projectDesign.scanOnSite);
        formData.append("howScan", state.projectDesign.howScan);
        formData.append("quoteOptionForPrinting", state.projectDesign.quoteOptionForPrinting);
        
        formData.append("way", state.steps.wayCurrent);
        formData.append("lastStep", state.steps.stepNumActive);

        if (payload.enableToken !== undefined) {
          formData.append("enableToken", payload.enableToken);
        } else {
          formData.append("customer_id", payload.customerId);
        }
        
        formData.append("projectId", state.projectDesign.projectId);

        if(payload.save) {
          formData.append("status", "1");
        } else {
          formData.append("status", getters.getStatusOfProject);
        }

        state.projectDesign.file.forEach(function (element) {
          formData.append("file[]", element);
        });
  
        state.projectDesign.savedFile.forEach(function (element) {
          formData.append("idFile[]", element.id);
        });

        return Vue.http.post(process.env.VUE_APP_API_BASE_URL + "project/save", formData, {credentials: true});
      },
      updateReturnToRecap({commit}, payload) {
        commit('RETURN_TO_RECAP', payload)
      },
      updateSeeProjectAfterSave({commit}, payload) {
        commit('UPDATE_SEE_PROJECT_AFTER_SAVE', payload)
      }
    },
    getters: { 
      barStep: state => {
        return Number( ((100 * (state.steps.stepNumActive + 1)) / (100 * (state.steps.stepList.length))) * 100 )
      },
      getStatusOfProject: state => {
        let calcul = Number(WAYS_TAB[state.steps.wayCurrent].length) - Number(state.steps.stepNumActive)
        if (calcul <= 2) {
          return 2
        } else {
          return 1
        }

      },
      getNbSteps: state => {
        return Number(WAYS_TAB[state.steps.wayCurrent].length) - Number(state.steps.stepNumActive)
      }
    }
}

 