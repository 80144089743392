import Vue from 'vue'

function getValueFromEvent (val) {
    if(val.target) {
        return val.target.value
    }
    return val
}

export default {
    namespaced: true,
    state: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        newsletter: 1,
        address1: '',
        address2: '',
        zipcode: '',
        city: '',
        country: 'FR',
        phone: '',
        company: '',
        customerType: '',
        website: '',
        siret: '',
        legalStatus: '',
        started: false,
        printer: 0,
        designer: 0,
    },
    mutations: {
        CHANGE_FIRSTNAME(state, payload = null) {
            state.firstname = payload
        },
        CHANGE_LASTNAME(state, payload = null) {
            state.lastname = payload
        },
        CHANGE_EMAIL(state, payload = null) {
            state.email = payload
        },
        CHANGE_PASSWORD(state, payload = null) {
            state.password = payload
        },
        CHANGE_NEWSLETTER(state, payload = null) {
            state.newsletter = payload
        },
        CHANGE_ADDRESS1(state, payload = null) {
            state.address1 = payload
        },
        CHANGE_ADDRESS2(state, payload = null) {
            state.address2 = payload
        },
        CHANGE_ZIPCODE(state, payload = null) {
            state.zipcode = payload
        },
        CHANGE_CITY(state, payload = null) {
            state.city = payload
        },
        CHANGE_COUNTRY(state, payload = null) {
            state.country = payload
        },
        CHANGE_PHONE(state, payload = null) {
            state.phone = payload
        },
        CHANGE_WEBSITE(state, payload = null) {
            state.website = payload
        },
        CHANGE_COMPANY(state, payload = null) {
            state.company = payload
        },
        CHANGE_LEGAL_STATUS(state, payload = null) {
            state.legalStatus = payload
        },
        CHANGE_SIRET(state, payload = null) {
            state.siret = payload
        },
        CHANGE_CUSTOMER_TYPE(state, payload = null) {
            state.customerType = payload
        },
        CHANGE_STARTED(state, payload = null) {
            state.started = payload
        },
        CHANGE_PRINTER(state, payload = null) {
            state.printer = payload
        },
        CHANGE_DESIGNER(state, payload = null) {
            state.designer = payload
        },
    },
    actions: {
        updateFirstname({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_FIRSTNAME', getValueFromEvent(payload))
        },
        updateLastname({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_LASTNAME', getValueFromEvent(payload))
        },
        updateEmail({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_EMAIL', getValueFromEvent(payload))
        },
        updateWebsite({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_WEBSITE', getValueFromEvent(payload))
        },
        updateLegalStatus({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_LEGAL_STATUS', getValueFromEvent(payload))
        },
        updateSiret({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_SIRET', getValueFromEvent(payload))
        },
        updateFirstnameWithoutGtag({commit}, payload) {
            commit('CHANGE_FIRSTNAME', getValueFromEvent(payload))
        },
        updateLastnameWithoutGtag({commit}, payload) {
            commit('CHANGE_LASTNAME', getValueFromEvent(payload))
        },
        updateEmailWithoutGtag({commit}, payload) {
            commit('CHANGE_EMAIL', getValueFromEvent(payload))
        },
        updatePhoneWithoutGtag({commit}, payload) {
            commit('CHANGE_PHONE', getValueFromEvent(payload))
        },
        updatePassword({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_PASSWORD', e.target.value)
        },
        updateNewsletter({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            if(e.target.checked) {
                commit('CHANGE_NEWSLETTER', 1)
            } else {
                commit('CHANGE_NEWSLETTER', '')
            }
        },
        updateAddress1({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_ADDRESS1', e.target.value)
        },
        updateAddress2({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_ADDRESS2', e.target.value)
        },
        updateZipcode({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_ZIPCODE', e.target.value)
        },
        updateCity({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_CITY', e.target.value)
        },
        updateCountry({commit,dispatch}, payload) {
            dispatch('googleTagStarted')
            commit('CHANGE_COUNTRY', payload)
        },
        updatePhone({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_PHONE', e.target.value)
        },
        updateCompany({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_COMPANY', e.target.value)
        },
        updateCustomerType({commit}, payload) {
            commit('CHANGE_CUSTOMER_TYPE', payload)
        },
        updatePrinter({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            if(e.target.checked) {
                commit('CHANGE_PRINTER', 1)
            } else {
                commit('CHANGE_PRINTER', '')
            }
        },
        updateDesignerWithoutGtag({commit}, payload) {
            commit('CHANGE_DESIGNER', payload)
        },
        updatePrinterWithoutGtag({commit}, payload) {
            commit('CHANGE_PRINTER', payload)
        },
        updateDesigner({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            if(e.target.checked) {
                commit('CHANGE_DESIGNER', 1)
            } else {
                commit('CHANGE_DESIGNER', '')
            }
        },
        googleTagStarted({commit,state}) {
            if(!state.started) {
                commit('CHANGE_STARTED', true)
                Vue.prototype.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_complete.account_creation.started')
            }
        }
    },
}