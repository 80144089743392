<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Ma société</h4>
            <strong>
              Pour être payé, merci de remplir les champs ci-dessous.
            </strong>
            <form v-if="loaded" @submit.prevent="() => sendForm" class="mt-3">
              <div class="row g-2">
                <div class="col-md-12">
                  <input v-model="siret" required placeholder="N° de SIRET*" type="text" class="form-control">
                  <span v-if="this.siretError" class="text--error">N° de SIRET non valide</span>
                </div>
                <div class="col-md-12">
                  <input v-model="vatNumber" required placeholder="TVA intracommunautaire*" type="text" class="form-control">
                  <span v-if="this.vatNumberError" class="text--error">TVA intracommunautaire non valide</span>
                </div>

                <div class="col-md-2">
                  Kbis <br> (image / pdf)
                </div>
                <div class="col-md-10">
                  <span v-if="savedKbis">Fournis</span>
                  <span v-if="!savedKbis"><input @change="uploadKbis($event)" required type="file" accept="image/*,.pdf"></span>
                  <div v-if="this.kbisError" class="text--error">Kbis obligatoire</div>
                </div>

                <h4 class="h4 mt-3">Le siège social</h4>

                <div class="col-md-6">
                  <input v-model="legalStatus" required placeholder="Forme juridique*" type="text" class="form-control">
                  <span v-if="this.legalStatusError" class="text--error">Forme juridique obligatoire</span>
                </div>
                <div class="col-md-6">
                  <input v-model="socialReason" required placeholder="Raison social*" type="text" class="form-control">
                  <span v-if="this.socialReasonError" class="text--error">Raison social du représentant légal obligatoire</span>
                </div>
                <div class="col-md-12">
                  <div class="form-check" style="margin: 0; padding-left: 40px;">
                    <input class="form-check-input" type="radio" value="sameAddressAsMaker" v-model="sameAddressAsMaker" name="flexRadioDefault" id="flexRadioDefault1">
                    <label class="form-check-label" for="flexRadioDefault1">
                      Même adresse et téléphone que mon atelier
                    </label>
                  </div>
                </div>
                <div class="col-md-12" style="margin-top: 0;">
                  <div class="form-check" :class="this.sameAddressAsMaker !== 'notSameAddressAsMaker' ? 'no-border' : ''" style="border-left: 1px solid #7C7C7C; border-right: 1px solid #7C7C7C; border-top: 1px solid #7C7C7C; margin: 0; padding-left: 40px; padding-top: 10px;">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="notSameAddressAsMaker" v-model="sameAddressAsMaker">
                    <label class="form-check-label" for="flexRadioDefault2">
                      Adresse ou téléphone différent
                    </label>
                  </div>
                </div>
                <div :class="this.sameAddressAsMaker !== 'notSameAddressAsMaker' ? 'no-border' : ''" style="padding-left: 18px; border-right: 5px solid #7C7C7C; border-left: 5px solid #7C7C7C; border-bottom: 1px solid #7C7C7C; padding-top: 10px; padding-bottom: 10px; margin: 0;" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                  <div class="col-md-12" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                    <input type="text" v-model="address.street1" placeholder="Rue*" class="form-control" required>
                    <span v-if="this.address.street1Error" class="text--error">Rue obligatoire</span>
                  </div>
                  <div class="col-md-12" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                    <input type="text" v-model="address.street2" placeholder="Complément d'adresse" class="form-control">
                  </div>
                  <div class="col-md-8" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                    <input type="text" v-model="address.city" placeholder="Ville*" class="form-control" required>
                    <span v-if="this.address.cityError" class="text--error">Ville obligatoire</span>
                  </div>
                  <div class="col-md-4" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                    <input type="number" v-model="address.zipcode" placeholder="Code postal*" class="form-control" required>
                    <span v-if="this.address.zipcodeError" class="text--error">Code postal obligatoire</span>
                  </div>
                  <div class="col-md-12" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                    <Countries :default-country="address.country" @event-update-country="updateCountry" class="form-control" />
                  </div>
                  <div class="col-md-12" v-if="this.sameAddressAsMaker === 'notSameAddressAsMaker'">
                    <input v-model="address.phone" required type="tel" placeholder="Téléphone*" class="form-control">
                    <span v-if="this.address.phoneError" class="text--error">Téléphone obligatoire</span>
                  </div>
                </div>

                <h4 class="h4 mt-3">Le représentant légal</h4>

                <div class="col-md-6">
                  <input v-model="firstname" required placeholder="Prénom du représentant légal*" type="text" class="form-control">
                  <span v-if="this.firstnameError" class="text--error">Prénom du représentant légal obligatoire</span>
                </div>
                <div class="col-md-6">
                  <input v-model="lastname" required placeholder="Nom du représentant légal*" type="text" class="form-control">
                  <span v-if="this.lastnameError" class="text--error">Nom du représentant légal obligatoire</span>
                </div>
                <div class="col-md-12">
                  <input v-model="birthDate" required placeholder="Date de naissance du représentant légal*" type="date" class="form-control">
                  <span v-if="this.birthDateError" class="text--error">Date de naissance du représentant légal obligatoire</span>
                </div>

                <div class="col-md-12">
                  <input type="text" v-model="legalRepresentatives[0].address.street1" placeholder="Rue*" class="form-control" required>
                  <span v-if="this.legalRepresentatives[0].address.street1Error" class="text--error">Rue obligatoire</span>
                </div>
                <div class="col-md-12">
                  <input type="text" v-model="legalRepresentatives[0].address.street2" placeholder="Complément d'adresse" class="form-control">
                </div>
                <div class="col-md-8">
                  <input type="text" v-model="legalRepresentatives[0].address.city" placeholder="Ville*" class="form-control" required>
                  <span v-if="this.legalRepresentatives[0].address.cityError" class="text--error">Ville obligatoire</span>
                </div>
                <div class="col-md-4">
                  <input type="number" v-model="legalRepresentatives[0].address.zipcode" placeholder="Code postal*" class="form-control" required>
                  <span v-if="this.legalRepresentatives[0].address.zipcodeError" class="text--error">Code postal obligatoire</span>
                </div>
                <div class="col-md-12">
                  <Countries :default-country="legalRepresentatives[0].address.country" @event-update-country="updateCountry" class="form-control" />
                </div>

                <div class="col-md-12" v-if="!savedPassport && !savedIdRecto && !savedIdVerso">
                    <input class="form-check-input" style="margin-right: 5px" type="radio" value="card" v-model="idType" name="flexRadioDefault2" id="flexRadioDefault3">
                    <label class="form-check-label" for="flexRadioDefault3">
                      Carte d'identité
                    </label>
                  <span style="margin: 0 10px">OU</span>
                    <input class="form-check-input" style="margin-right: 5px" type="radio" name="flexRadioDefault2" id="flexRadioDefault4" value="passport" v-model="idType">
                    <label class="form-check-label" for="flexRadioDefault4">
                      Passeport (2 pages comprenant photo + signature)
                    </label>
                </div>

                <div class="col-md-2" v-if="this.idType === 'card'">
                  Pièce d'identité recto <br> (image / pdf)
                </div>
                <div class="col-md-10" v-if="this.idType === 'card'">
                  <span v-if="savedIdRecto">Fournis</span>
                  <span v-if="!savedIdRecto"><input @change="uploadIdRecto($event)" required type="file" accept="image/*,.pdf"></span>
                  <div v-if="this.idRectoError" class="text--error">Pièce d'identité recto obligatoire</div>
                </div>
                <div class="col-md-2" v-if="this.idType === 'card'">
                  Pièce d'identité verso <br> (image / pdf)
                </div>
                <div class="col-md-10" v-if="this.idType === 'card'">
                  <span v-if="savedIdVerso">Fournis</span>
                  <span v-if="!savedIdVerso"><input @change="uploadIdVerso($event)" required type="file" accept="image/*,.pdf"></span>
                  <div v-if="this.idVersoError" class="text--error">Pièce d'identité verso obligatoire</div>
                </div>

                <div class="col-md-2" v-if="this.idType === 'passport'">
                  Passeport <br> (image / pdf)
                </div>
                <div class="col-md-10" v-if="this.idType === 'passport'">
                  <span v-if="savedPassport">Fournis</span>
                  <span v-if="!savedPassport"><input @change="uploadPassport($event)" required type="file" accept="image/*,.pdf"></span>
                  <div v-if="this.passportError" class="text--error">Passeport obligatoire</div>
                </div>
                <div v-if="savedPassport || savedIdRecto || savedIdVerso">
                  Pour déposer une autre piece d'identité, merci de <a target="_blank" href="mailto:contact@united-3dmakers.fr?subject=Demande de changement de la pièce d'identité du responsable légal">contacter United 3D Makers</a>.
                </div>
                <h4 class="h4 mt-3">Mes informations bancaires</h4>

                <div class="col-md-12">
                  <input @change="changeIbanEvent($event)" :value="iban" required placeholder="IBAN*" type="text" class="form-control">
                  <span v-if="this.ibanError" class="text--error">IBAN non valide</span>
                </div>

                <!--
                Not for now

                <div style="padding: 20px" class="row" v-for="(legalRepresentative, index) in legalRepresentatives" :key="index">
                  <div class="col-md-12">
                    Représentant légal {{index + 1}}
                    <a href="#" @click="(e) => {e.preventDefault(); removeLegalRepresentative(legalRepresentative.id, index);}" style="float: right;">X</a>
                  </div>
                  <div class="col-md-6">
                    <input v-model="legalRepresentative.firstname" required placeholder="Prénom*" type="text" class="form-control">
                  </div>
                  <div class="col-md-6">
                    <input v-model="legalRepresentative.lastname" required placeholder="Nom*" type="text" class="form-control">
                  </div>
                  <div class="col-md-12">
                    <input v-model="legalRepresentative.birthDate" required placeholder="Date de naissance*" type="date" class="form-control">
                  </div>
                  <div class="col-md-2">
                    Pièce d'identité recto
                  </div>
                  <div class="col-md-10">
                    <input @change="uploadLegalRepresentativeIdRecto($event, index)" required type="file">
                  </div>
                  <div class="col-md-2">
                  </div>
                  <div class="col-10">
                    {{ legalRepresentative.savedIdRecto }}
                  </div>
                  <div class="col-md-2">
                    Pièce d'identité verso
                  </div>
                  <div class="col-md-10">
                    <input @change="uploadLegalRepresentativeIdVerso($event, index)" required type="file">
                  </div>
                  <div class="col-md-2">
                  </div>
                  <div class="col-10">
                    {{ legalRepresentative.savedIdVerso }}
                  </div>
                </div>

                <div class="col-md-12">
                  <a href="#" @click="(e) => {e.preventDefault(); addLegalRepresentative();}">+ Ajouter un représentant légal</a>
                </div>-->

              </div><!-- row -->

              <div class="mt-5">
                <div v-if="!this.isFormValid" class="text--error">Un ou plusieurs champs sont manquants</div>
                <div v-if="this.error" class="text--error">{{ this.error }}</div>
                <button-loader
                    @clickLoading="(e) => {e.preventDefault(); this.isActive = false; this.isLoading = true; sendForm();}"
                    :title="'Valider'"
                    :isActive="this.isActive"
                    :isLoading="this.isLoading"
                ></button-loader>
                <div class="mt-4">
                  <a target="_blank" :href="urlWP + 'cgu-cgv-m'">Voir les conditions générales</a>
                </div>
              </div>
            </form>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import Countries from "../components/Countries";
import ButtonLoader from "../components/ButtonLoader.vue";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, Countries, ButtonLoader},
  name: "MakerMyBankInfos",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Ma société',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      legalStatus: '',
      socialReason: '',
      address:  {
        street1: '',
        street2: '',
        city: '',
        zipcode: '',
        country: 'FR',
        phone: '',
        street1Error: false,
        cityError: false,
        zipcodeError: false,
        phoneError: false,
      },
      iban: '',
      siret: '',
      vatNumber: '',
      birthDate: '',
      firstnameError: false,
      lastnameError: false,
      birthDateError: false,
      legalStatusError: false,
      socialReasonError: false,
      siretError: false,
      ibanError: false,
      vatNumberError: false,
      idRectoError: false,
      idVersoError: false,
      passportError: false,
      kbisError: false,
      isFormValid: true,
      idRecto: null,
      idVerso: null,
      passport: null,
      kbis: null,
      isIbanSaved: false,
      savedIdRecto: null,
      savedIdVerso: null,
      savedPassport: null,
      savedKbis: null,
      legalRepresentatives: [],
      removedLegalRepresentatives: [],
      loaded: false,
      isActive: true,
      isLoading: false,
      sameAddressAsMaker: 'sameAddressAsMaker',
      idType: 'card',
      error: '',
      urlWP: process.env.VUE_APP_WWW_BASE_URL,
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'bank-infos/get', {credentials: true})
        .then(({body}) => {
          this.firstname = body.legal_representatives[0] !== undefined ? body.legal_representatives[0].firstname : ''
          this.lastname = body.legal_representatives[0] !== undefined ? body.legal_representatives[0].lastname : ''
          this.legalStatus = body.company_type
          this.siret = body.siren
          this.vatNumber = body.vat_number
          this.birthDate = body.legal_representatives[0] !== undefined ? body.legal_representatives[0].birth_date : ''
          this.socialReason = body.address_head_office !== undefined ? body.address_head_office.company : ''
          this.savedIdRecto = body.legal_representatives[0] !== undefined && body.legal_representatives[0].identity_paper_original_name !== undefined ? body.legal_representatives[0].identity_paper_original_name : null
          this.savedIdVerso = body.legal_representatives[0] !== undefined && body.legal_representatives[0].identity_paper_verso_original_name !== undefined ? body.legal_representatives[0].identity_paper_verso_original_name : null
          this.savedPassport = body.legal_representatives[0] !== undefined && body.legal_representatives[0].passport_original_name !== undefined ? body.legal_representatives[0].passport_original_name : null
          this.savedKbis = body.kbis_original_name !== undefined ? body.kbis_original_name : null

          this.address.street1 = body.address_head_office !== undefined ? body.address_head_office.street1 : ''
          this.address.street2 = body.address_head_office !== undefined ? body.address_head_office.street2 : ''
          this.address.city = body.address_head_office !== undefined ? body.address_head_office.city : ''
          this.address.zipcode = body.address_head_office !== undefined ? body.address_head_office.zipcode : ''
          this.address.country = body.address_head_office !== undefined ? body.address_head_office.country : 'FR'
          this.address.phone = body.address_head_office !== undefined ? body.address_head_office.telephone : ''

          if(body.iban_last4 !== undefined && body.iban_bank !== undefined) {
            this.iban = 'FR** **** **** **** **** ***' + body.iban_last4 + ' - ' + body.iban_bank
            this.isIbanSaved = true
          }

          if(!body.is_head_office_same_address_as_maker) {
            this.sameAddressAsMaker = 'notSameAddressAsMaker'
          }

          if(body.id_type === 'passport') {
            this.idType = 'passport'
          }

          if(body.legal_representatives.length > 0) {
            for (let legalRepresentative of body.legal_representatives) {
              this.legalRepresentatives.push(
                  {
                    id : legalRepresentative.id,
                    firstname : legalRepresentative.firstname,
                    lastname : legalRepresentative.lastname,
                    birthDate : legalRepresentative.birth_date,
                    savedIdRecto : legalRepresentative.identity_paper_original_name,
                    savedIdVerso : legalRepresentative.identity_paper_verso_original_name,
                    address: {
                      street1: legalRepresentative.address !== undefined ? legalRepresentative.address.street1 : '',
                      street2: legalRepresentative.address !== undefined ? legalRepresentative.address.street2 : '',
                      city: legalRepresentative.address !== undefined ? legalRepresentative.address.city : '',
                      zipcode: legalRepresentative.address !== undefined ? legalRepresentative.address.zipcode : '',
                      country: legalRepresentative.address !== undefined ? legalRepresentative.address.country : 'FR',
                      street1Error: false,
                      cityError: false,
                      zipcodeError: false,
                    }
                  }
              )
            }
          } else {
            this.legalRepresentatives.push(
                {
                  address: {
                    street1: '',
                    street2: '',
                    city: '',
                    zipcode: '',
                    country: 'FR',
                    street1Error: false,
                    cityError: false,
                    zipcodeError: false,
                  }
                }
            )
          }
          this.loaded = true
        })
  },
  methods: {
    changeIbanEvent(event) {
      this.isIbanSaved = false
      this.iban = event.target.value
    },
    checkFormValidity() {
      this.error = ''
      this.firstnameError = false
      this.lastnameError = false
      this.birthDateError = false
      this.legalStatusError = false
      this.socialReasonError = false
      this.siretError = false
      this.ibanError = false
      this.vatNumberError = false
      this.idRectoError = false
      this.idVersoError = false
      this.kbisError = false
      this.passportError = false

      this.address.street1Error = false
      this.address.cityError = false
      this.address.zipcodeError = false
      this.address.phoneError = false

      this.legalRepresentatives[0].address.street1Error = false
      this.legalRepresentatives[0].address.cityError = false
      this.legalRepresentatives[0].address.zipcodeError = false

      let isValid = true

      if(this.firstname === '') {
        this.firstnameError = true
        isValid = false
      }
      if(this.lastname === '') {
        this.lastnameError = true
        isValid = false
      }
      if(this.birthDate === '') {
        this.birthDateError = true
        isValid = false
      }
      if(this.legalStatus === '') {
        this.legalStatusError = true
        isValid = false
      }
      if(this.socialReason === '') {
        this.socialReasonError = true
        isValid = false
      }
      if(!this.isSiretValid(this.siret.replaceAll(' ',''))) {
        this.siretError = true
        isValid = false
      }
      if(this.iban === '') {
        this.ibanError = true
        isValid = false
      }
      if(this.vatNumber === '') {
        this.vatNumberError = true
        isValid = false
      }

      if(this.sameAddressAsMaker === 'notSameAddressAsMaker') {
        if(this.address.street1 === '') {
          this.address.street1Error = true
          isValid = false
        }
        if(this.address.city === '') {
          this.address.cityError = true
          isValid = false
        }
        if(this.address.zipcode === '') {
          this.address.zipcodeError = true
          isValid = false
        }
        if(this.address.phone === '') {
          this.address.phoneError = true
          isValid = false
        }
      }

      if(this.legalRepresentatives[0].address.street1 === '') {
        this.legalRepresentatives[0].address.street1Error = true
        isValid = false
      }
      if(this.legalRepresentatives[0].address.city === '') {
        this.legalRepresentatives[0].address.cityError = true
        isValid = false
      }
      if(this.legalRepresentatives[0].address.zipcode === '') {
        this.legalRepresentatives[0].address.zipcodeError = true
        isValid = false
      }
      if(this.idType === 'card') {
        if(this.idRecto === null && this.savedIdRecto === null) {
          this.idRectoError = true
          isValid = false
        }
        if(this.idVerso === null && this.savedIdVerso === null) {
          this.idVersoError = true
          isValid = false
        }
      } else {
        if(this.passport === null && this.savedPassport === null) {
          this.passportError = true
          isValid = false
        }
      }

      if(this.isValidIBANNumber(this.iban) !== 1 && !this.isIbanSaved) {
        this.ibanError = true
        isValid = false
      }

      if(this.kbis === null && this.savedKbis === null) {
        this.kbisError = true
        isValid = false
      }

      this.isFormValid = isValid

      if(!isValid) {
        this.isActive = true
        this.isLoading = false
      }

      return isValid
    },
    updateCountry(payload) {
      this.address.country = payload
    },
    uploadIdRecto() {
      this.idRecto = event.target.files[0]
    },
    uploadIdVerso() {
      this.idVerso = event.target.files[0]
    },
    uploadPassport() {
      this.passport = event.target.files[0]
    },
    uploadKbis() {
      this.kbis = event.target.files[0]
    },
    uploadLegalRepresentativeIdRecto(event, index) {
      this.legalRepresentatives[index].idRecto = event.target.files[0]
    },
    uploadLegalRepresentativeIdVerso(event, index) {
      this.legalRepresentatives[index].idVerso = event.target.files[0]
    },
    addLegalRepresentative() {
      this.legalRepresentatives.push([])
    },
    removeLegalRepresentative(id, index) {
      this.legalRepresentatives.splice(index, 1)
      this.removedLegalRepresentatives.push(id)
    },
    sendForm() {
      if(this.checkFormValidity()) {
        let formData = new FormData();
        formData.append("firstname", this.firstname);
        formData.append("lastname", this.lastname);
        formData.append("legalStatus", this.legalStatus);
        formData.append("socialReason", this.socialReason);
        formData.append("iban", this.iban);
        formData.append("siret", this.siret);
        formData.append("vatNumber", this.vatNumber);
        formData.append("birthDate", this.birthDate);
        if(this.idType === 'card') {
          formData.append('idRecto', this.idRecto);
          formData.append('idVerso', this.idVerso);
        } else {
          formData.append('passport', this.passport);
        }
        formData.append('kbis', this.kbis);

        if(this.sameAddressAsMaker === 'notSameAddressAsMaker') {
          formData.append('street1', this.address.street1);
          formData.append('street2', this.address.street2 === null ? '' : this.address.street2);
          formData.append('zipcode', this.address.zipcode === null ? '' : this.address.zipcode);
          formData.append('city', this.address.city === null ? '' : this.address.city);
          formData.append('country', this.address.country === null ? '' : this.address.country);
          formData.append('phone', this.address.phone === null ? '' : this.address.phone);
        } else {
          formData.append('sameAddressAsMaker', '1');
        }

        this.removedLegalRepresentatives.forEach((legalRepresentative, index) => {
          formData.append("removedLegalRepresentatives[" + index + "]", legalRepresentative);
        })

        this.legalRepresentatives.forEach((legalRepresentative, index) => {
          formData.append("legalRepresentatives[" + index + "][firstname]", legalRepresentative.firstname);
          formData.append("legalRepresentatives[" + index + "][lastname]", legalRepresentative.lastname);
          formData.append("legalRepresentatives[" + index + "][birthDate]", legalRepresentative.birthDate);
          formData.append("legalRepresentatives[" + index + "][idRecto]", legalRepresentative.idRecto);
          formData.append("legalRepresentatives[" + index + "][idVerso]", legalRepresentative.idVerso);
          formData.append('legalRepresentatives[' + index +'][street1]', legalRepresentative.address.street1);
          formData.append('legalRepresentatives[' + index +'][street2]', legalRepresentative.address.street2);
          formData.append('legalRepresentatives[' + index +'][zipcode]', legalRepresentative.address.zipcode);
          formData.append('legalRepresentatives[' + index +'][city]', legalRepresentative.address.city);
          formData.append('legalRepresentatives[' + index +'][country]', legalRepresentative.address.country);
          if(legalRepresentative.id) {
            formData.append("legalRepresentatives[" + index + "][id]", legalRepresentative.id);
          }
        })

        this.$http.post(process.env.VUE_APP_API_BASE_URL + 'bank-infos/send', formData, {credentials: true})
            .then(() => {
              window.location.reload()
            }, ({body}) => {
              //console.log(body)
              this.isActive = true
              this.isLoading = false
              this.error = 'Une erreur c\'est produite : ' + body
            })

      }
    },
    isValidIBANNumber(input) {
      var CODE_LENGTHS = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
      };
      var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
      // check syntax and length
      if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
      });
      // final check
      return this.mod97(digits)
    },
    mod97(string) {
      var checksum = string.slice(0, 2), fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum
    },
    isSiretValid(siret) {
      var estValide;
      if ( (siret.length != 14) || (isNaN(siret)) )
        estValide = false;
      else {
        // Donc le SIRET est un numérique à 14 chiffres
        // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
        // correspondent au numéro d'établissement
        // et enfin le dernier chiffre est une clef de LUHN.
        var somme = 0;
        var tmp;
        for (var cpt = 0; cpt<siret.length; cpt++) {
          if ((cpt % 2) == 0) { // Les positions impaires : 1er, 3è, 5è, etc...
            tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
            if (tmp > 9)
              tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
          }
          else
            tmp = siret.charAt(cpt);
          somme += parseInt(tmp);
        }
        if ((somme % 10) == 0)
          estValide = true; // Si la somme est un multiple de 10 alors le SIRET est valide
        else
          estValide = false;
      }
      return estValide;
    }
  }
}
</script>
