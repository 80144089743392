<template>
  <div class="a-accordion__news">
    <!-- accordion-header -->
    <div @click="open">

      <slot name="header">titre</slot>

    </div>

    <!-- accordion-content -->
    <transition
        name="a-accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end"
    >
      <div class="a-accordion__news__content" v-show="showNews">

        <slot name="content"></slot>

      </div>
    </transition>

  </div>
</template>

<script>

export default {
  name: "AccordionItem",
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showNews: true
    }
  },
  methods: {
    open() {
      this.showNews = !this.showNews
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
};
</script>