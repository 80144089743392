<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes technologies</h4>

            <technologies-items :activeType="'machining'"></technologies-items>

            <div class="a-accordion-header theme--active">
              <icon-thread :width="32" :color="'#7F1F23'"></icon-thread>
              <h4 class="h4">Usinage</h4>
            </div>
            <div v-if="loaded">
              <p>
                Taguez vos autres machines, technologies, matériaux, finitions de fabrication, etc...
              </p>
              <form @submit.prevent="() => sendForm">
                <div class="row g-2">
                  <div class="col-md-12">
                    <multiselect v-model="value" selectLabel="Entrer pour sélectionner" tag-placeholder="Ajouter en tant que nouveau tag" placeholder="Rechercher ou ajouter un tag" label="name" track-by="id" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                  </div>
                </div>
              </form>
              <a style="margin-top: 20px; float: right" href="#" class="btn-u3dm btn-u3dm--primary" v-on:click="sendForm()">Enregistrer</a>
            </div>
          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import IconThread from "../components/icon/IconThread";
import TechnologiesItems from "../components/maker/TechnologiesItems";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, IconThread, TechnologiesItems},
  name: "MakerMachining",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Usinage',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      machiningDescription: '',
      value: [],
      options: [],
      loaded: false,
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'machining-tag/', {credentials: true})
        .then(({body}) => {
          this.options = body
          this.$http.get(process.env.VUE_APP_API_BASE_URL + 'machining-tag/maker', {credentials: true})
              .then(({body}) => {
                this.value = body.machining_tags
                this.loaded = true
              })
        })
  },
  methods: {
    addTag (newTag) {
      const tag = {
        name: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    },
    sendForm() {
      let formData = new FormData();
      formData.append("machiningTags", JSON.stringify(this.value));

      this.$http.post(process.env.VUE_APP_API_BASE_URL + 'machining-tag/add', formData, {credentials: true})
          .then(() => {
            this.$notify({ type: 'success', text: 'Tags d\'usinage enregistrées' })
          })
    },
  }

  }
</script>
