<template>
    <div class="my-printer"
    >
      <div class="mb-2">
        <div style="display: flex; justify-content: space-between">
          <div class="material-card-media">
            <img width="40px" height="40px" :src="pictureBaseUrl + item.printer_model.ref_brand.id" alt="image marque">
          </div>
          <div>
            <a style="margin-left: 10px; color: #7C7C7C; font-size: 16px" href="#" @click="(e) => {e.preventDefault(); removePrinter(item.id)}"><i class="bi bi-trash"></i></a>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <h5 class="h5" style="display: inline-block"><strong>{{ item.printer_model.brand }} - {{ item.model }} -</strong> {{ item.technology.name }}</h5>
          <div>
            <strong>Dimension max. d'impression :</strong>
            <input style="width: 60px; margin-left: 10px; border-radius: 5px 0 0 5px;" type="number" v-model="item.max_dimensions.x" placeholder="x">
            <input style="width: 60px; border-radius: 0; border-right: 0; border-left: 0;" type="number" v-model="item.max_dimensions.y" placeholder="y">
            <input style="width: 60px; border-radius: 0 5px 5px 0;" type="number" v-model="item.max_dimensions.z" placeholder="z">
            <strong style="margin-left: 10px">mm</strong>
          </div>
          <div>
            <strong>Nombre <i data-bs-toggle="tooltip" title="Nombres d'imprimantes de ce modèle" class="bi bi-info-circle"></i> : </strong> <input style="width: 50px" type="number" v-model="item.quantity">
          </div>
        </div>
      </div>
      <div>
        <span class="badge-material" :class="material.selected ? 'active' : ''" v-for="(material, index) in item.materials" :key="index" @click="selectMaterial(material)">{{ material.name }}</span>
      </div>
  </div>
</template>

<script>

export default {
  name: "MyPrinter",
  data() {
    return {
      pictureBaseUrl: process.env.VUE_APP_API_BASE_URL + 'brand/picture/'
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    selectMaterial(material) {
      if(material.selected) {
        material.selected = false
      } else {
        material.selected = true
      }
      this.$forceUpdate();
    },
    removePrinter(itemId) {
      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'printer/' + itemId, {credentials: true})
          .then(() => {
            this.$emit('remove-my-printer', itemId)
          })
    },
  }
}
</script>