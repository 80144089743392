<template>
    <div 
    class="btn-u3dm btn-u3dm--primary btn-loading"
    @click="goLoading" 
    >

        <div 
        :class="isLoading == true ? 'btn-loading-text--hide' : ''"
        >{{title}}</div>

        <div 
        v-if="isLoading == true" 
        class="btn-loading-loader">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ButtonLoader",
    props: {
        title: {
            type: String,
            default: 'cliquer'
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        goLoading(e) {
            if (this.isActive == true) {
                this.$emit('clickLoading', e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-loading {
    position: relative;

    &-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        transform: translate(-5px, -4px);

        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-text--hide {
        opacity: 0;
    }
}
</style>