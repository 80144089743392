<template>
  <div class="position-relative background-light" id="maker-dashboard">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">
          <!-- Content -->
          <div v-if="loaded" class="mt-5" style="padding-bottom: 30px;">
            <div v-if="!isMakerAvailable">
              <div class="progression-maker mb-5">
                <h4 class="h4">Atelier Fermé</h4>
                <router-link to="maker/mon-atelier">Mon atelier</router-link>
              </div>
            </div>
            <div v-if="isMakerAvailable">
              <accordion-news :index="1">
              <template v-slot:header>
                <div
                    @click="toggleNew = !toggleNew"
                    class="accordion-title-news"
                >
                  <h4 class="h4">
                    Nouveauté(s)
                    <span class="accordion-news-icon">
                      <i v-if="toggleNew" class="bi bi-chevron-down"></i>
                      <i v-if="!toggleNew" class="bi bi-chevron-right"></i>
                    </span>
                  </h4>
                </div>
              </template>
              <template v-slot:content>
                <div v-for="(news, index) in newsList" :key="index">
                  <h5 class="accordion-news-title-date">{{ news.title }} - {{ news.start_date }}</h5>
                  <div class="row">
                    <div class="col-lg-8 accordion-news-description" v-html="news.description">
                    </div>
                    <div v-if="news.youtube_url !== undefined" class="col-lg-3 accordion-news-image">
                      <iframe width="280" height="158" :src="'https://www.youtube.com/embed/' + news.youtube_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div v-if="news.youtube_url === undefined" class="col-lg-3 accordion-news-image">
                      <img width="280" height="158" :src="newPictureBaseUrl + news.id">
                    </div>
                  </div>
                  </div>
              </template>
            </accordion-news>
            </div>

            <div class="progression-maker">
              <h4 class="h4">Progression de votre compte : {{progressPercent}}%</h4>
              <div class="progress-container">
                <progress max="100" :value="progressPercent"></progress>
                <span class="progress-step">
                {{nbFulfilled}}/{{nbToFulfill}}
              </span>
              </div>
              <ul class="mt-3 mb-0">
                <li>
                  <div v-if="!rdvFulfilled">
                    <router-link to="maker/mon-rendez-vous">Rendez vous</router-link> <i v-if="!rdvFulfilled" class="bi bi-x-lg icon-x-steps"></i> <i v-if="rdvFulfilled" class="bi bi-check-lg icon-check-steps"></i>
                    
                  </div>
                  <div v-if="rdvFulfilled">
                    Rendez-vous <i v-if="!rdvFulfilled" class="bi bi-x-lg icon-x-steps"></i> <i v-if="rdvFulfilled" class="bi bi-check-lg icon-check-steps"></i>
                  </div>


                  
                </li>
                <li>
                  <router-link :to="hasPrinters ? 'maker/mes-imprimantes' : 'maker/imprimantes'">Mes technologies pour recevoir des devis</router-link> <i v-if="!myTechnologiesFulfilled" class="bi bi-x-lg icon-x-steps"></i> <i v-if="myTechnologiesFulfilled" class="bi bi-check-lg icon-check-steps"></i>
                </li>
                <li>
                  <router-link to="maker/ma-societe">Ma société pour être payé</router-link> <i v-if="!bankInfoFulfilled" class="bi bi-x-lg icon-x-steps"></i> <i v-if="bankInfoFulfilled" class="bi bi-check-lg icon-check-steps"></i>
                </li>
                <li v-if="isPrinter">
                  <router-link to="maker/mes-prix-dimpressions">Mes prix d'impressions pour recevoir des commandes en lignes automatisées</router-link> <i v-if="!printPricesFulfilled" class="bi bi-x-lg icon-x-steps"></i> <i v-if="printPricesFulfilled" class="bi bi-check-lg icon-check-steps"></i>
                </li>
              </ul>
            </div>

            <div class="mt-5" v-if="rdvDate">
              Prochain rendez vous avec United 3D Maker le {{rdvDate}}. <a v-if="rdvGoogleMeetLink" target="_blank" :href="rdvGoogleMeetLink">Lien visio Google Meet.</a>
            </div>
            <div class="mt-5" v-if="rdvWaiting">
              En attente de confirmation du rendez-vous.
            </div>

            <div class="row mt-5">
              <div class="col-lg-5 quotations-orders" @mouseover="overQuotations = true" @mouseleave="overQuotations = false" @click="clickQuotations" style="padding-left: 20px">
                <h4 class="h4 title-quotations-orders">Devis <a :class="overQuotations ? 'hovered' : ''" class="dashboard-shortcut-link" :href="urlApp + '/fr/mon-compte/mon-compte-maker/mes-devis/processing'">Accéder aux devis ></a></h4>
                <div class="stats">
                  <h5 class="h5">Nouveaux projets : <span class="color-accent">{{ nbQuotationsByStatus.new_project }}</span></h5>
                  <h5 class="h5">Devis en cours : <span class="color-accent">{{ nbQuotationsByStatus.processing }}</span></h5>
                  <h5 class="h5">Devis à corriger : <span class="color-accent">{{ nbQuotationsByStatus.correction }}</span></h5>
                  <h5 class="h5">Devis envoyés : <span class="color-accent">{{ nbQuotationsByStatus.sent }}</span></h5>
                </div>
              </div>
              <div class="col-lg-1">
              </div>
              <div class="col-lg-5 quotations-orders" @mouseover="overOrders = true" @mouseleave="overOrders = false" @click="clickOrders">
                <h4 class="h4 title-quotations-orders">Commandes <a :class="overOrders ? 'hovered' : ''" class="dashboard-shortcut-link" :href="urlApp + '/fr/mon-compte/mon-compte-maker/mes-commandes/processing'">Accéder aux commandes ></a></h4>
                <div class="stats">
                  <h5 class="h5">Nouvelles commandes : <span class="color-accent">{{ nbOrdersByStatus.new_order }}</span></h5>
                  <h5 class="h5">En cours de production : <span class="color-accent">{{ nbOrdersByStatus.processing }}</span></h5>
                  <h5 class="h5">A déposer au transporteur : <span class="color-accent">{{ nbOrdersByStatus.drop }}</span></h5>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import AccordionNews from "./AccordionNews.vue";
import {mapState} from "vuex";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, AccordionNews},
  name: "MakerDashboard",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Tableau de bord',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_APP_BASE_URL,
      nbQuotationsByStatus: {
        new_project: 0,
        processing: 0,
        correction: 0,
        sent: 0,
      },
      nbOrdersByStatus: {
        new_order: 0,
        processing: 0,
        drop: 0,
      },
      progressPercent: 0,
      bankInfoFulfilled: false,
      rdvFulfilled: false,
      myTechnologiesFulfilled: false,
      printPricesFulfilled: false,
      nbFulfilled: 0,
      nbToFulfill: 0,
      loaded: false,
      urlApp: process.env.VUE_APP_APP_BASE_URL_WITHOUT_SLASH,
      rdvGoogleMeetLink: null,
      rdvDate: null,
      newsList: [],
      newPictureBaseUrl: process.env.VUE_APP_API_BASE_URL + 'news/picture/',
      toggleNew: true,
      overQuotations: false,
      overOrders: false,
      rdvWaiting: false,
    }
  },
  computed: {
    ...mapState("authentication", {
      isPrinter: (state) => state.isPrinter,
      hasPrinters: (state) => state.hasPrinters,
      isMakerAvailable: (state) => state.isMakerAvailable,
      isMakerRdvMenuShowed: (state) => state.isMakerRdvMenuShowed,

    })
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'maker_dashboard/nb_quotations_order', {credentials: true})
        .then((response) => {
          this.nbQuotationsByStatus = response.body.nbQuotationsByStatus
          this.nbOrdersByStatus = response.body.nbOrdersByStatus
        })

    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'maker_dashboard/progression', {credentials: true})
        .then(({body}) => {
          this.progressPercent = body.progressPercent
          this.bankInfoFulfilled = body.bankInfoFulfilled
          this.rdvFulfilled = body.rdvFulfilled
          this.myTechnologiesFulfilled = body.myTechnologiesFulfilled
          this.printPricesFulfilled = body.printPricesFulfilled !== undefined ? body.printPricesFulfilled : false

          if(this.printPricesFulfilled) {
            this.nbFulfilled += 1
          }
          if(this.bankInfoFulfilled) {
            this.nbFulfilled += 1
          }
          if(this.myTechnologiesFulfilled) {
            this.nbFulfilled += 1
          }
          if(this.rdvFulfilled) {
            this.nbFulfilled += 1
          }

          this.nbToFulfill = this.isPrinter ? 4 : 3
          this.loaded = true
        })

    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'rdv/next', {credentials: true})
        .then(({body}) => {
          if(body === true) {
            this.rdvWaiting = true
          } else {
            this.rdvDate = body.date
            let test = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(body.google_meet_link)
            if(test) {
              this.rdvGoogleMeetLink = body.google_meet_link
            } else {
              this.rdvGoogleMeetLink = 'https://' + body.google_meet_link
            }
          }
        })

    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'news', {credentials: true})
        .then(({body}) => {
          this.newsList = body
        })
  },
  methods: {
    clickQuotations() {
      window.location = this.urlApp + '/fr/mon-compte/mon-compte-maker/mes-devis/processing'
    },
    clickOrders() {
      window.location = this.urlApp + '/fr/mon-compte/mon-compte-maker/mes-commandes/processing'
    }
  }
}
</script>
