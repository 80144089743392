<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div class="m-login-content-save-project modal-login-content-appear">

        <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-prospect">
          <h4 class="m-login-heading h4">
            Merci pour toutes ces informations. <br>
            Nous revenons rapidement vers vous pour confirmer le rendez vous.
            <br><br>
            Cordialement, <br><br>
            L'équipe United 3D Makers. <br>
          </h4>
          <div style="text-align: center">
            <img class="logo--desktop"
                 :src="require('@/assets/icon-svg/u3dm-logo-duotone.svg')"
                 alt="logo u3dm"
                 width="150px"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSaveRdv',
  methods: {
    close() {
      window.location.reload()
    },
  }
}
</script>