<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div class="m-login-content-save-project modal-login-content-appear">

        <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-prospect">

          <h4 class="m-login-heading h4">Couleurs</h4>
          <div v-html="colorsText"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalMaterialsColors',
  props: {
    materialsColors: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    colorsText() {
      let text = ''

      Object.keys(this.materialsColors).forEach(key => {
        text += '<strong>' + this.materialsColors[key].material_name + ':</strong> '
        for (let j = 0; j < Object.keys(this.materialsColors[key].colors).length; j++) {
          if(this.materialsColors[key].colors[j].hexadecimal[0] !== '#') {
            text += this.materialsColors[key].colors[j].name + ' '
          } else {
            text += '<span class="circle-color-mini" style="background-color: ' + this.materialsColors[key].colors[j].hexadecimal + '"></span>' + ' '
          }
        }
        text += '<br>'
      })
      return text
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>