export default {
    namespaced: true,
    state: {
        cardNumber: null,
        stripe: null,
        errorsInFormCreditCard: false,
        formCreditCardsIsValid: null
    },
    mutations: {
      UPDATE_CARD_NUMBER(state, payload = null) {
        state.cardNumber = payload
      },
      UPDATE_STRIPE(state, payload = null) {
        state.stripe = payload
      },
      UPDATE_ERRORS_IN_FORM_CREDIT_CARD(state, payload = null) {
        state.errorsInFormCreditCard = payload
      },
      UPDATE_FORM_CREDIT_CARD_IS_VALID(state, payload = null) {
        state.formCreditCardsIsValid = payload
      },
    },
    actions: {
        updateCardNumber({commit}, payload = null) {
            commit('UPDATE_CARD_NUMBER', payload)
        },
        updateStripe({commit}, payload = null) {
            commit('UPDATE_STRIPE', payload)
        },
        updateErrorsInFormCreditCard({commit}, payload) {
          commit('UPDATE_ERRORS_IN_FORM_CREDIT_CARD', payload)
        },
        updateFormCreditCardsIsValid({commit}, payload) {
          commit('UPDATE_FORM_CREDIT_CARD_IS_VALID', payload)
        }
    },
    
}
  
  