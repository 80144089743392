<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div class="m-login-content-save-project modal-login-content-appear">

        <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-prospect text-center">

          <h4 class="m-login-heading h4">Ajouter une nouvelle imprimante</h4>

          <input class="input-add" v-model="brand" type="text" placeholder="Marque">
          <div v-if="brandError" class="text-danger">Veuillez compléter ce champs.</div>
          <br>
          <input class="input-add" v-model="model" type="text" placeholder="Modèle">
          <div v-if="modelError" class="text-danger">Veuillez compléter ce champs.</div>
          <br>
          <select class="input-add" @change="changeEvent($event)">
            <option value="">Technologie</option>
            <option v-for="(technology, index) in technologies" :key="index" :value="technology.id">
              {{technology.name}}
            </option>
          </select>
          <div v-if="technologyIdError" class="text-danger">Veuillez compléter ce champs.</div>
          <br>
          <div>
            <span class="text-add-dimensions">Dimensions d'impression maximum en mm</span> <br>
            <input @input="restrictDimensionX($event.target)" class="input-add-dimension" v-model="dimensionX" type="number" min="1" placeholder="X">
            <input @input="restrictDimensionY($event.target)" class="input-add-dimension" style="margin-left: 15px" v-model="dimensionY" type="number" min="1" placeholder="Y">
            <input @input="restrictDimensionZ($event.target)" class="input-add-dimension" style="margin-left: 15px" v-model="dimensionZ" type="number" min="1" placeholder="Z">
          </div>
          <div v-if="formError !== ''" class="text-danger mt-3">
            {{ formError }}
          </div>
            <a href="#" class="mt-3 btn-u3dm btn-u3dm--primary" v-on:click="(e) => {e.preventDefault(); addNewPrinter()}">Ajouter</a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddNewPrinter',
  data() {
    return {
      brand: '',
      model: '',
      technologyId: '',
      dimensionX: '',
      dimensionY: '',
      dimensionZ: '',
      brandError: false,
      modelError: false,
      technologyIdError: false,
      dimensionXError: false,
      dimensionYError: false,
      dimensionZError: false,
      technologies: [],
      formError: '',
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'ref/technology', {credentials: true})
        .then(({body}) => {
          this.technologies = body
        })
  },
  methods: {
    isInt(n) {
      return n % 1 === 0;
    },
    restrictDimensionX(tis) {
      var prev = tis.getAttribute("data-prev");
      prev = (prev != '') ? prev : '';
      if (!this.isInt(this.dimensionX) && this.dimensionX !== '') {
        this.dimensionX = prev
      }
      tis.setAttribute("data-prev", this.dimensionX)
    },
    restrictDimensionY(tis) {
      var prev = tis.getAttribute("data-prev");
      prev = (prev != '') ? prev : '';
      if (!this.isInt(this.dimensionY) && this.dimensionY !== '') {
        this.dimensionY = prev
      }
      tis.setAttribute("data-prev", this.dimensionY)
    },
    restrictDimensionZ(tis) {
      var prev = tis.getAttribute("data-prev");
      prev = (prev != '') ? prev : '';
      if (!this.isInt(this.dimensionZ) && this.dimensionZ !== '') {
        this.dimensionZ = prev
      }
      tis.setAttribute("data-prev", this.dimensionZ)
    },
    close() {
      this.$emit('close');
    },
    changeEvent(event) {
      this.technologyId = event.target.value
    },
    isPositiveNumber(nb) {
      nb = parseInt(nb)
      if(Number.isInteger(nb)) {
        if(nb > 0) {
          return true
        }
      }
      return false
    },
    addNewPrinter() {
      this.brandError = false
      this.modelError = false
      this.technologyIdError = false
      this.dimensionXError = false
      this.dimensionYError = false
      this.dimensionZError = false

      if(this.brand === '') {
        this.brandError = true
      }
      if(this.model === '') {
        this.modelError = true
      }
      if(this.technologyId === '') {
        this.technologyIdError = true
      }
      if(this.dimensionX === ''){
        this.dimensionXError = true
      }
      if(this.dimensionY === ''){
        this.dimensionYError = true
      }
      if(this.dimensionZ === ''){
        this.dimensionZError = true
      }
      if(!this.isPositiveNumber(this.dimensionX) || !this.isPositiveNumber(this.dimensionY) || !this.isPositiveNumber(this.dimensionZ)) {
        this.formError = 'Les dimensions doivent être des entiers supérieurs à 0.'

      } else {
        if(this.brand === '' || this.model === '' || this.technologyId === '' || this.dimensionX === '' || this.dimensionY === '' || this.dimensionZ === '') {
          this.formError = 'Tout les champs sont obligatoires.'
        } else {
          this.formError = ''

          let formData = new FormData();
          formData.append("brand", this.brand);
          formData.append("model", this.model);
          formData.append("technologyId", this.technologyId);
          formData.append("dimensions[x]", this.dimensionX);
          formData.append("dimensions[y]", this.dimensionY);
          formData.append("dimensions[z]", this.dimensionZ);

          this.$http.post(process.env.VUE_APP_API_BASE_URL + 'printer/addNewPrinter', formData, {credentials: true})
              .then(() => {
                window.location.reload()
              })
      }
      }
    },
  }
}
</script>