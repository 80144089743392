<template>
  <div class="material-card print-base-card print-appearItem cursor-default" style="height: 81px">
    <div class="material-card-inner">
      <div class="material-card-media material-card-custom-media">
        <i class="bi bi-clock-history"></i>
      </div>
      <div>
        <h6 class="material-card-title h6">
          {{ item.brand }} - {{ item.model }}
          <a class="material-card-remove" href="#" @click="(e) => {e.preventDefault(); removeNewScanner(item.id, index)}"><i class="bi bi-trash"></i></a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "NewScanner",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default() {
        return {}
      }
    },
  },
  methods: {
    removeNewScanner(id, index) {
      this.$emit('remove-new-scanner', {id: id, index: index})
    }
  }
}
</script>