import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        firstname: '',
        lastname: '',
        email: '',
        id: '',
        isMaker: false,
        isChecked: false,
        isPrinter: false,
        isDesigner: false,
        isMakerAvailable: true,
        isMakerRdvMenuShowed: false,
        hasPrinters: false,
    },
    mutations: {
        CHANGE_FIRSTNAME(state, payload = null) {
            state.firstname = payload
        },
        CHANGE_LASTNAME(state, payload = null) {
            state.lastname = payload
        },
        CHANGE_EMAIL(state, payload = null) {
            state.email = payload
        },
        CHANGE_ID(state, payload = null) {
            state.id = payload
        },
        CHANGE_IS_MAKER(state, payload = null) {
            state.isMaker = payload
        },
        CHANGE_IS_CHECKED(state, payload = null) {
            state.isChecked = payload
        },
        CHANGE_IS_PRINTER(state, payload = null) {
            state.isPrinter = payload
        },
        CHANGE_IS_DESIGNER(state, payload = null) {
            state.isDesigner = payload
        },
        CHANGE_IS_MAKER_AVAILABLE(state, payload = null) {
            state.isMakerAvailable = payload
        },
        CHANGE_IS_MAKER_RDV_MENU_SHOWED(state, payload = null) {
            state.isMakerRdvMenuShowed = payload
        },
        CHANGE_HAS_PRINTERS(state, payload = null) {
            state.hasPrinters = payload
        },
    },
    getters: {
        initialName: state => {
            return (state.firstname.substr(0, 1) + state.lastname.substr(0, 1)).toUpperCase()
        },
        isConnected: state => {
            if (state.id == '') {
                return false
            } else {
               return true
            }
        },
        getUserId: state => {
            return state.id
        },
        getIsMaker: state => {
            return state.isMaker
        },
    },
    actions: {
        updateHasPrinters({commit}, payload) {
            commit('CHANGE_HAS_PRINTERS', payload)
        },
        updateFirstname({commit}, payload) {
            commit('CHANGE_FIRSTNAME', payload)
        },
        updateLastname({commit}, payload) {
            commit('CHANGE_LASTNAME', payload)
        },
        login({commit}, user) {
            let data = {
                "security": {
                    "credentials": {
                        "login": user.login,
                        "password": user.password
                    }
                }
            }
            return Vue.http.post(process.env.VUE_APP_API_BASE_URL + "user/login", data, {credentials: true}).then((response) => {
                let {data} = response.body
                commit('CHANGE_FIRSTNAME', data.firstname)
                commit('CHANGE_LASTNAME', data.lastname)
                commit('CHANGE_EMAIL', data.email)
                commit('CHANGE_ID', data.id)
                commit('CHANGE_IS_MAKER', data.maker)
                commit('CHANGE_IS_MAKER_AVAILABLE', data.isAvailable)
                commit('CHANGE_IS_CHECKED', data.isChecked)
                commit('CHANGE_IS_PRINTER', data.isPrinter)
                commit('CHANGE_IS_DESIGNER', data.isDesigner)
                commit('CHANGE_IS_MAKER_RDV_MENU_SHOWED', data.isMakerRdvMenuShowed)
                commit('CHANGE_HAS_PRINTERS', data.hasPrinters)

            })
        },
        setConnected({state, commit}) {
            if(state.firstname && state.lastname && state.email) {
                // console.log('Connected by state')
                return true;
            } else {
                // console.log('Test connected by http')
                return Vue.http.get(process.env.VUE_APP_API_BASE_URL + "user/connected", {credentials: true}).then((response) => {
                    let {data} = response.body
                    commit('CHANGE_FIRSTNAME', data.firstname)
                    commit('CHANGE_LASTNAME', data.lastname)
                    commit('CHANGE_EMAIL', data.email)
                    commit('CHANGE_ID', data.id)
                    commit('CHANGE_IS_MAKER', data.maker)
                    commit('CHANGE_IS_CHECKED', data.isChecked)
                    commit('CHANGE_IS_PRINTER', data.isPrinter)
                    commit('CHANGE_IS_DESIGNER', data.isDesigner)
                    commit('CHANGE_IS_MAKER_AVAILABLE', data.isAvailable)
                    commit('CHANGE_IS_MAKER_RDV_MENU_SHOWED', data.isMakerRdvMenuShowed)
                    commit('CHANGE_HAS_PRINTERS', data.hasPrinters)
                })
            }
        },
        logout({commit}) {
            return Vue.http.get(process.env.VUE_APP_API_BASE_URL + "user/logout", {credentials: true}).then(() => {
                commit('CHANGE_FIRSTNAME', '')
                commit('CHANGE_LASTNAME', '')
                commit('CHANGE_EMAIL', '')
                commit('CHANGE_ID', '')
                commit('CHANGE_IS_MAKER', false)
                commit('CHANGE_IS_CHECKED', false)
                commit('CHANGE_IS_PRINTER', false)
                commit('CHANGE_IS_DESIGNER', false)
                commit('CHANGE_IS_MAKER_RDV_MENU_SHOWED', false)
                commit('CHANGE_HAS_PRINTERS', false)
            })
        },
    },
}