<template>
    <div class="min-h-80vh">
        <navigation-site :navThemeTransparent="false"></navigation-site>
        <div class="container space-nav-foot">

            <h1 class="h3 mt-nav">Crée votre société</h1>
            

            <div class="mt-2">
                <div class="row g-2">
                    <div class="col-md-6">
                        <input type="text" placeholder="Nom commercial" class="form-control">
                    </div>
                     <div class="col-md-6">
                        <input type="text" placeholder="Fome juridique" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="N° immatriculation" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="N° TVA intracommunautaire" class="form-control">
                    </div>
                    <!-- Adresse -->
                    <div class="col-md-6">
                        <input type="text" placeholder="Raison social" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Adresse" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Complément d'adresse" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Code postav" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Ville" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Pays" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Télephone" class="form-control">
                    </div>
                    <!-- représentant légal -->
                     <div class="col-md-6">
                        <input type="text" placeholder="Prénom" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="nom" class="form-control">
                    </div>
                     <div class="col-md-6">
                        <input type="date" placeholder="Date de naissance" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <label for="">Pièce d'identité</label>
                        <input type="file" placeholder="importer pièces d'identité" class="">
                    </div>
                    <!-- coordonnées bancaire -->
                    <div class="col-md-6">
                        <input type="text" placeholder="IBAN Actuelle : ********************" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="text" placeholder="Nouvelle IBAN : ********************" class="form-control">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import NavigationSite from '../ui/NavigationSite.vue'
export default {
  components: { NavigationSite },
    name: "ProspectSociety"
}
</script>