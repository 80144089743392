<template>
    <div v-if="item.show" class="material-card print-base-card print-appearItem" @click="addToSelection(item)" style="height: 81px"
         :class="item.selected ? 'is-selected' : ''"
    >
      <div class="material-card-inner">
        <div class="material-card-media">
          <img width="40px" height="40px" :src="pictureBaseUrl + item.ref_brand.id" alt="image marque">
        </div>
        <div>
          <h6 class="material-card-title h6">{{ item.brand }} - {{ item.model }}</h6>
          <span class="material-card-price t3">{{ item.technology.name }}</span>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: "AccordPrinterItem",
  data() {
    return {
      pictureBaseUrl: process.env.VUE_APP_API_BASE_URL + 'brand/picture/'
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    addToSelection(item) {
      this.$emit('update-selection', item, true)
      // this.$forceUpdate();
    }
  },
}
</script>