export default {
    namespaced: true,
    state: {
        stepNextPossible: 1,
        lastChildActive: 1,
        drawerMaterialIsVisible: false,
        manageCarousel: {
            indexActive: 0,
            lengthOftabMaterials: 0
        },
        manageOptions: {
            indexActive: 0,
            infoTechnologyVisible: false,
            infoMaterialVisible: false,
            infoLayerVisible: false,
        },
        viewAllTechnology: false,
        shopbarVisible: true,
        shopbarType2Visible: false,
        viewerMobileIsOpen: false,
        modalConnexionIsOpen: false,
        modalFileErrorIsOpen: false,
        modalRedirectProjectIsOpen: false,
        addBasketAfterConnection: false,
        tokenUser: ''
    },
    mutations: {
        UPDATE_LAST_CHILD_ACTIVE(state, payload = null) {
            state.lastChildActive = payload
        },
        UPDATE_DRAWER_MATERIAL_IS_VISIBLE(state, payload = null) {
            state.drawerMaterialIsVisible = payload
        },
        UPDATE_OPTIONS_INDEX_ACTIVE(state, payload = null) {
            state.manageOptions.indexActive = payload
            if (state.manageOptions.indexActive == 1) {
                state.manageOptions.detailMaterialOpen = false
            }

        },

        UPDATE_INDEX_ACTIVE(state, payload = null) {
            state.manageCarousel.indexActive = payload
        },
        UPDATE_LENGTH_OF_TAB_MATERIALS(state, payload = null) {
            state.manageCarousel.lengthOftabMaterials = payload
        },
        UPDATE_STEP_NEXT_POSSIBLE(state, payload = null) {
            state.stepNextPossible = payload
        },
        UPDATE_ALL_TECHNOLOGY(state, payload = null) {
            state.viewAllTechnology = payload
        },
        UPDATE_SHOPBAR_VISIBLE(state, payload = null) {
            state.shopbarVisible = payload
        },
        UPDATE_VIEWER_MOBILE_IS_OPEN(state, payload = null) {
            state.viewerMobileIsOpen = payload
        },
        UPDATE_MODAL_VISIBLE_IS_OPEN(state, payload = null) {
            state.modalConnexionIsOpen = payload
        },
        UPDATE_MODAL_FILE_ERROR_VISIBLE_IS_OPEN(state, payload = null) {
            state.modalFileErrorIsOpen = payload
        },
        UPDATE_MODAL_REDIRECT_PROJECT_IS_OPEN(state, payload = null) {
            state.modalRedirectProjectIsOpen = payload
        },
        UPDATE_ADD_BASKET_AFTER_CONNECTION(state, payload = null) {
            state.addBasketAfterConnection = payload
        },
        UPDATE_TOKEN_USER(state, payload = null) {
            state.tokenUser = payload
        },
        UPDATE_INFO_TECHNOLOGY_VISIBLE(state, payload = null) {
            state.manageOptions.infoTechnologyVisible = payload
        },
        UPDATE_INFO_MATERIAL_VISIBLE(state, payload = null) {
            state.manageOptions.infoMaterialVisible = payload
        },
        UPDATE_INFO_LAYER_VISIBLE(state, payload = null) {
            state.manageOptions.infoLayerVisible = payload
        },
        RESET_PRINT_MANAGER(state) {
            state.stepNextPossible = 1
            state.lastChildActive = 0
            state.drawerMaterialIsVisible = false
            state.manageCarousel = {
                indexActive: 0,
                lengthOftabMaterials: 0
            },
            state.manageOptions = {
                indexActive: 0,
                infoTechnologyVisible: false,
                infoMaterialVisible: false,
                infoLayerVisible: false,
            },
            state.viewAllTechnology = false
            state.shopbarVisible = true,
            state.viewerMobileIsOpen = false
            state.modalConnexionIsOpen = false
            state.modalRedirectProjectIsOpen = false
            state.addBasketAfterConnection= false
            state.tokenUser = ''
        },
    },
    actions: {
        updateLastChildActive({ commit }, payload = null) {
            commit('UPDATE_LAST_CHILD_ACTIVE', payload)
        },
        updateDrawerMaterialIsVisible({ commit }, payload = null) {
            commit('UPDATE_DRAWER_MATERIAL_IS_VISIBLE', payload)
        },
        updateOptionsIndexActive({commit}, payload = null) {
            commit('UPDATE_OPTIONS_INDEX_ACTIVE', payload)
        },
        updateInfoTechnologyVisible({commit}, payload = null) {
            commit('UPDATE_INFO_TECHNOLOGY_VISIBLE', payload)
        },
        updateInfoMaterialVisible({commit}, payload = null) {
            commit('UPDATE_INFO_MATERIAL_VISIBLE', payload)
        },
        updateInfoLayerVisible({commit}, payload = null) {
            commit('UPDATE_INFO_LAYER_VISIBLE', payload)
        },    
        updateIndexActive({commit}, payload = null) {
            commit('UPDATE_INDEX_ACTIVE', payload)
        },
        updateLengthOfTabMaterials({commit}, payload = null) {
            commit('UPDATE_LENGTH_OF_TAB_MATERIALS', payload)
        },
        updateStepNextPossible({commit}, payload = null) {
            commit('UPDATE_STEP_NEXT_POSSIBLE', payload)
        },
        updateViewAllTechnology({commit}, payload = null) {
            commit('UPDATE_ALL_TECHNOLOGY', payload)
        },
        updateShopbarVisible({commit}, payload = null) {
            commit('UPDATE_SHOPBAR_VISIBLE', payload)
        },
        updateViewerMobileIsOpen({commit}, payload = null) {
            commit('UPDATE_VIEWER_MOBILE_IS_OPEN', payload)
        },
        updateModalVisibleIsOpen({commit}, payload = null) {
            commit('UPDATE_MODAL_VISIBLE_IS_OPEN', payload)
        },
        updateModalFileErrorVisibleIsOpen({commit}, payload = null) {
            commit('UPDATE_MODAL_FILE_ERROR_VISIBLE_IS_OPEN', payload)
        },
        updateModalRedirectProjectIsOpen({commit}, payload = null) {
            commit('UPDATE_MODAL_REDIRECT_PROJECT_IS_OPEN', payload)
        },
        updateAddBasketAfterConnection({commit}, payload = null) {
            commit('UPDATE_ADD_BASKET_AFTER_CONNECTION', payload)
        },
        updateTokenUser({commit}, payload = null) {
            commit('UPDATE_TOKEN_USER', payload)
        },
        // reset
        resetPrintManager({commit}) {
            commit('RESET_PRINT_MANAGER')
        },
        resetPrintManagerByStep2({commit}) {
            commit('UPDATE_STEP_NEXT_POSSIBLE', 2)
            commit('UPDATE_LAST_CHILD_ACTIVE', 0)
        }
    },
  }
  
  