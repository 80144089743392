export default {
    namespaced: true,
    state: {
        orderId: null,
        billing: {
            firstname: null,
            lastname: null,
            enterprise: null,
            adress: null,
            adress2: null,
            city: null,
            zipcode: null,
            phone: null
        },
        shipping: {
            firstname: null,
            lastname: null,
            enterprise: null,
            adress: null,
            adress2: null,
            city: null,
            zipcode: null,
            phone: null
        },
        savingShippingForLater: false,
        modeDelivery: {
            name: null,
            price: null,
            label: null
        },
        priceDelivery: null,
        shippingRelayIdentifier: null,
        discountAmountTaxIncl: null,
        discountAmountTaxExcl: null,
        promoCode: null,
        promoCodeLabel: null,
        relayAddress: {
            firstname: null,
            lastname: null,
            enterprise: null,
            adress: null,
            adress2: null,
            city: null,
            zipcode: null,
            phone: null
        }
    },
    mutations: {
        UPDATE_PROMO_CODE_LABEL(state, payload = null) {
            state.promoCodeLabel = payload
        },
        UPDATE_PROMO_CODE(state, payload = null) {
            state.promoCode = payload
        },
        UPDATE_DISCOUNT_AMOUNT_TAX_INCL(state, payload = null) {
            state.discountAmountTaxIncl = payload
        },
        UPDATE_DISCOUNT_AMOUNT_TAX_EXCL(state, payload = null) {
            state.discountAmountTaxExcl = payload
        },
        UPDATE_ORDER_ID(state, payload = null) {
            state.orderId = payload
        },
        UPDATE_BILLING(state, payload = null) {
            state.billing.firstname = payload.firstname
            state.billing.lastname = payload.lastname
            state.billing.enterprise = payload.enterprise
            state.billing.adress = payload.adress
            state.billing.adress2 = payload.adress2
            state.billing.city = payload.city
            state.billing.zipcode = payload.zipcode
            state.billing.phone = payload.phone
        },
        UPDATE_SHIPPING(state, payload = null) {
            state.shipping.firstname = payload.firstname
            state.shipping.lastname = payload.lastname
            state.shipping.enterprise = payload.enterprise
            state.shipping.adress = payload.adress
            state.shipping.adress2 = payload.adress2
            state.shipping.city = payload.city
            state.shipping.zipcode = payload.zipcode
            state.shipping.phone = payload.phone
        },
        UPDATE_SAVING_SHIPPING_FOR_LATER(state, payload = null) {
            state.savingShippingForLater = payload
        },
        UPDATE_MODE_DELIVERY(state, payload = null) {
            state.modeDelivery = {
                name: payload.name,
                price: payload.price,
                label: payload.label
            }
        },
        UPDATE_PRICE_DELIVERY(state, payload = null) {
            state.priceDelivery = payload
        },
        UPDATE_SHIPPING_RELAY_IDENTIFIER(state, payload = null) {
            state.shippingRelayIdentifier = payload
        },
        UPDATE_RELAY_ADDRESS(state, payload = null) {
            state.relayAddress.firstname = payload.firstname
            state.relayAddress.lastname = payload.lastname
            state.relayAddress.enterprise = payload.enterprise
            state.relayAddress.adress = payload.adress
            state.relayAddress.adress2 = payload.adress2
            state.relayAddress.city = payload.city
            state.relayAddress.zipcode = payload.zipcode
            state.relayAddress.phone = payload.phone
        },
        // reset
        RESET_ORDER(state) {
            state.orderId= null
            state.billing = {
                firstname: null,
                lastname: null,
                enterprise: null,
                adress: null,
                adress2: null,
                city: null,
                zipcode: null,
                phone: null
            },
            state.shipping = {
                firstname: null,
                lastname: null,
                enterprise: null,
                adress: null,
                adress2: null,
                city: null,
                zipcode: null,
                phone: null
            },
            state.savingShippingForLater = false
            state.modeDelivery = {
                name: null,
                price: null,
                label: null
            }
            state.priceDelivery = null,
            state.shippingRelayIdentifier = null,
            state.discountAmountTaxIncl = null,
            state.discountAmountTaxExcl = null,
            state.promoCode = null,
            state.promoCodeLabel = null,
            state.relayAddress = {
                firstname: null,
                lastname: null,
                enterprise: null,
                adress: null,
                adress2: null,
                city: null,
                zipcode: null,
                phone: null
            }
        }
    },
    actions: {
        updatePromoCodeLabel({commit}, payload) {
            commit('UPDATE_PROMO_CODE_LABEL', payload)
        },
        updatePromoCode({commit}, payload) {
            commit('UPDATE_PROMO_CODE', payload)
        },
        updateDiscountAmountTaxExcl({commit}, payload) {
            commit('UPDATE_DISCOUNT_AMOUNT_TAX_EXCL', payload)
        },
        updateDiscountAmountTaxIncl({commit}, payload) {
            commit('UPDATE_DISCOUNT_AMOUNT_TAX_INCL', payload)
        },
        updateOrderId({commit}, payload) {
            commit('UPDATE_ORDER_ID', payload)
        },
        updateBilling({commit}, payload = null) {
            commit('UPDATE_BILLING', payload)
        },
        updateShipping({commit}, payload = null) {
            commit('UPDATE_SHIPPING', payload)
        },
        updateSavingShippingForLater({commit}, payload = null) {
            commit('UPDATE_SAVING_SHIPPING_FOR_LATER', payload)
        },
        updateModeDelivery({commit}, payload = null) {
            commit('UPDATE_MODE_DELIVERY', payload)
        },
        updatePriceDelivery({commit}, payload = null) {
            commit('UPDATE_PRICE_DELIVERY', payload)
        },
        updateShippingRelayIdentifier({commit}, payload = null) {
            commit('UPDATE_SHIPPING_RELAY_IDENTIFIER', payload)
        },
        updateRelayAddress({commit}, payload = null) {
            commit('UPDATE_RELAY_ADDRESS', payload)
        },
        // reset
        resetOrder({commit}) {
            commit('RESET_ORDER')
        } 
    },
}
  
  