<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mes prix d'impressions</h4>

            <technologies-items :activeType="'printers'"></technologies-items>

            <div class="a-accordion-header theme--active">
              <icon-thread :width="32" :color="'#7F1F23'"></icon-thread>
              <h4 class="h4">Vos prix d'impressions</h4>
            </div>

            <div v-if="printers.length === 0">
              <p>
                Vous n'avez aucune imprimante de sélectionné. <br>
              </p>
              <p>
                Aller à la sélection de <router-link to="/maker/imprimantes">mes imprimantes</router-link> pour configurer ensuite <strong>mes prix</strong>.
              </p>
            </div>
            <div style="display: flex; justify-content: space-between;" class="mb-2">
              <router-link to="/maker/mes-imprimantes">
                <span class="btn-u3dm btn-u3dm--secondary">Retour vers la sélection de matériaux</span>
              </router-link>
              <button class="btn-u3dm btn-u3dm--primary" @click="sendForm" @keyup.enter="sendForm">Enregistrer</button>
            </div>
            <div v-if="Object.keys(printers).length > 0">
              <printer v-for="(item, index) in printers"
                       :key="index"
                       :index="index"
                       :item="item"></printer>

              <div style="display: flex; justify-content: end;" class="mt-2">
                <button class="btn-u3dm btn-u3dm--primary" @click="sendForm" @keyup.enter="sendForm">Enregistrer</button>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>
    <transition name="fade-modal-login">
      <modal-materials-colors v-if="isModalColorsVisible" @close="isModalColorsVisible = false" :materialsColors="materialsColors"></modal-materials-colors>
    </transition>
  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import IconThread from "../components/icon/IconThread";
import Printer from "../components/maker/my-prints-prices/Printer";
import TechnologiesItems from "../components/maker/TechnologiesItems";
import ModalMaterialsColors from "../components/modal/ModalMaterialsColors";

export default {
  components: {ModalMaterialsColors, Printer, MakerNavigationSite, FooterSite, MakerMenu, IconThread, TechnologiesItems},
  name: "MakerMyPrintsPrices",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mes prix d\'impressions',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      printers: [],
      materialsColors: [],
      isModalColorsVisible: false,
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'printsPrices/get', {credentials: true})
        .then(({body}) => {
          this.printers = body
          Object.keys(this.printers).forEach(key => {
            if(this.printers[key].setup_price === 0) {
              this.printers[key].setup_price = ''
            }
            if(this.printers[key]['standard_layer']) {
              for(let standardLayer of this.printers[key]['standard_layer']) {
                if(standardLayer.price === 0) {
                  standardLayer.price = ''
                }
              }
            }
            if(this.printers[key]['precision_layer']) {
              for(let precisionLayer of this.printers[key]['precision_layer']) {
                if(precisionLayer.price === 0) {
                  precisionLayer.price = ''
                }
              }
            }
          })

        });
  },
  methods: {
    sendForm() {
      Object.keys(this.printers).forEach(key => {
        if(this.printers[key].setup_price < 0) {
          this.printers[key].setup_price = ''
        }
        if(this.printers[key]['standard_layer']) {
          for(let standardLayer of this.printers[key]['standard_layer']) {
            if(standardLayer.price < 0) {
              standardLayer.price = ''
            }
          }
        }
        if(this.printers[key]['precision_layer']) {
          for(let precisionLayer of this.printers[key]['precision_layer']) {
            if(precisionLayer.price < 0) {
              precisionLayer.price = ''
            }
          }
        }
      })

      let error = false

      Object.keys(this.printers).forEach(key => {
        let hasMaterialPrice = false

        if(this.printers[key]['standard_layer']) {
          for(let standardLayer of this.printers[key]['standard_layer']) {
            if(standardLayer.price > 0) {
              hasMaterialPrice = true
            }
          }
        }

        if(this.printers[key]['precision_layer']) {
          for(let precisionLayer of this.printers[key]['precision_layer']) {
            if(precisionLayer.price > 0) {
              hasMaterialPrice = true
            }
          }
        }

        if(hasMaterialPrice && (this.printers[key].setup_price === '' || this.printers[key].setup_price === undefined)) {
          error = true
        }
      })

      if(!error) {
        let formData = new FormData();
        formData.append("printers", JSON.stringify(this.printers));

        this.$http.post(process.env.VUE_APP_API_BASE_URL + 'printsPrices/send', formData, {credentials: true})
            .then(() => {
              this.$notify({ type: 'success', text: 'Enregistré' })
            })
      } else {
        this.$notify({ type: 'error', text: 'Les prix du setup doivent être renseignés' })
      }
    },
  }
}
</script>
