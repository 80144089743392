<template>
  <div>
    <navigation-site :navThemeTransparent="true"></navigation-site>


    <header class="header-theme-main" :style="{'background-image': 'url(' + require('@/assets/svg/u3dm-w.svg') + ')'}">
      <!-- <img src="" alt="" class="header-theme-main-bg-media"> -->
      <!-- <div class="header-theme-main-bg-media">
        <img src="@/assets/svg/u3dm-w.svg" alt="image u3dm">
      </div> -->
      <!-- <div class="header-theme-main-bg-media"
      :style="{'background-image': 'url(' + require('@/assets/svg/u3dm-w.svg') + ')'}"></div> -->

      <div class="container">
       
          <div class="header-theme-main-title">
            <h1 class="h1 mb-5 text-center color-white">Maker professionnel</h1>
            <h2 class="t1 mt-2 text-center color-white">
              United 3D Makers est un réseau de services de fabrication additive et de modélisation 3D en France.
              Les entreprises du réseau qui produisent les pièces ou les designs commandés via le site United 3D Makers sont nos partenaires de fabrication.
            </h2>
          </div>
        
      </div>
    </header>


    <section class="bi-color">
      <div class="bi-color-top background-main"></div>
      <div class="bi-color-bot background-light"></div>
      <!-- reasurance -->
        <div class="container">
          <div class="background-white block-reassurance">
            <div class="row">
              <div class="col-xl-3 col-lg-6">
                <div class="p-3 text-center">
                  <div class="mb-4">
                    <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.992441 18.4909C0.852181 18.4909 0.711036 18.4609 0.576527 18.3991C0.220788 18.2363 0 17.8927 0 17.5027V2.87763C0 1.29081 1.29464 0 2.88618 0H16.5047C18.0962 0 19.3909 1.29081 19.3909 2.87763V12.1308C19.3909 13.7176 18.0962 15.0084 16.5047 15.0084H5.42237L1.63401 18.2509C1.4495 18.4092 1.22296 18.4909 0.992441 18.4909ZM2.88618 1.41168C2.07559 1.41168 1.41588 2.06944 1.41588 2.87763V16.5771L4.69894 13.767C4.82726 13.6572 4.99097 13.5967 5.15999 13.5967H16.5043C17.3148 13.5967 17.9746 12.939 17.9746 12.1308V2.87763C17.9746 2.06944 17.3148 1.41168 16.5043 1.41168H2.88618Z" fill="#FC3640"/>
                      <path d="M11.0713 9.16047H5.04192C4.65079 9.16047 4.33398 8.8446 4.33398 8.45462C4.33398 8.06464 4.65079 7.74878 5.04192 7.74878H11.0713C11.4625 7.74878 11.7793 8.06464 11.7793 8.45462C11.7793 8.8446 11.4625 9.16047 11.0713 9.16047Z" fill="#FC3640"/>
                      <path d="M14.3053 6.17389H5.04192C4.65079 6.17389 4.33398 5.85803 4.33398 5.46805C4.33398 5.07807 4.65079 4.76221 5.04192 4.76221H14.3053C14.6964 4.76221 15.0132 5.07807 15.0132 5.46805C15.0132 5.85803 14.696 6.17389 14.3053 6.17389Z" fill="#FC3640"/>
                      <path d="M23.0078 24C22.7773 24 22.5507 23.9184 22.3662 23.7605L18.5779 20.518H7.49555C5.90402 20.518 4.60938 19.2272 4.60938 17.6404C4.60938 17.2504 4.92618 16.9345 5.31731 16.9345C5.70845 16.9345 6.02525 17.2504 6.02525 17.6404C6.02525 18.4486 6.68496 19.1063 7.49555 19.1063H18.8403C19.0093 19.1063 19.173 19.1668 19.3013 19.2766L22.5844 22.0868V8.38679C22.5844 7.5786 21.9247 6.92084 21.1141 6.92084C20.7229 6.92084 20.4061 6.60498 20.4061 6.215C20.4061 5.82502 20.7229 5.50916 21.1141 5.50916C22.7056 5.50916 24.0003 6.79997 24.0003 8.38679V23.0119C24.0003 23.4018 23.7795 23.7455 23.4237 23.9083C23.2897 23.9696 23.1481 24 23.0078 24Z" fill="#FC3640"/>
                    </svg>
                  </div>
                  <h4 class="h4 mb-2 min-h-50">Créez un profil simplement</h4>
                  <p class="t1 mt-2 color-secondary">
                    En quelques minutes, créez votre profil, vous êtes prêts à recevoir vos premiers projets.
                  </p>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6">
                <div class="p-3 text-center">
                  <div class="mb-4">
                    <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.2486 24H5.29683C4.03057 24 3 22.9487 3 21.6569V2.34353C3 1.05132 4.03057 0 5.29683 0H16.2374C16.416 0 16.5872 0.0701471 16.7161 0.196324L21.3322 4.71132C21.4685 4.84456 21.5455 5.02853 21.5455 5.22088V21.6569C21.5455 22.9487 20.5149 24 19.2486 24ZM5.29683 1.41176C4.79344 1.41176 4.38389 1.83 4.38389 2.34353V21.6565C4.38389 22.1704 4.79344 22.5882 5.29683 22.5882H19.2486C19.752 22.5882 20.1616 22.1704 20.1616 21.6569V5.52221L15.9593 1.41176H15.641C15.6393 1.41176 15.638 1.41176 15.6362 1.41176H5.29683Z" fill="#FC3640"/>
                      <path d="M20.8527 5.92681H16.7914C15.9896 5.92681 15.3379 5.26152 15.3379 4.44402V0.705933H16.7218V4.44402C16.7218 4.48329 16.7529 4.51505 16.7914 4.51505H20.8527V5.92681Z" fill="#FC3640"/>
                      <path d="M13.3955 18.6694H11.1527C9.68144 18.6694 8.48438 17.4482 8.48438 15.9473V13.2945C8.48438 12.5229 9.09977 11.8947 9.85659 11.8947H14.6911C15.4475 11.8947 16.0633 12.5224 16.0633 13.2945V15.9473C16.0638 17.4482 14.8667 18.6694 13.3955 18.6694ZM9.8687 13.3064V15.9473C9.8687 16.6695 10.4447 17.2576 11.1531 17.2576H13.3959C14.1038 17.2576 14.6803 16.6699 14.6803 15.9473V13.3064H9.8687Z" fill="#FC3640"/>
                      <path d="M12.274 18.6694C11.8917 18.6694 11.582 18.3535 11.582 17.9635V15.2821C11.582 14.8921 11.8917 14.5762 12.274 14.5762C12.6563 14.5762 12.9659 14.8921 12.9659 15.2821V17.9635C12.9659 18.3535 12.6558 18.6694 12.274 18.6694Z" fill="#FC3640"/>
                      <path d="M14.4247 13.3065H10.1216C9.73933 13.3065 9.42969 12.9906 9.42969 12.6006V10.9007C9.42969 9.30103 10.7055 8 12.2732 8C13.8409 8 15.1166 9.30147 15.1166 10.9007V12.6006C15.1166 12.9906 14.8066 13.3065 14.4247 13.3065ZM10.8132 11.8947H13.7323V10.9007C13.7323 10.0797 13.0776 9.41176 12.2727 9.41176C11.4679 9.41176 10.8132 10.0797 10.8132 10.9007V11.8947Z" fill="#FC3640"/>
                    </svg>
                  </div>
                  <h4 class="h4 mb-2 min-h-50">Gagnez du temps</h4>
                  <p class="t1 mt-2 color-secondary">
                    Sur la plateforme, recevez des projets, créez des devis, éditez l’étiquette de transport.
                  </p>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6">
                <div class="p-3 text-center">
                  <div class="mb-4">
                    <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.379 10.9641C17.3706 10.9641 17.3622 10.9641 17.3539 10.9637C17.1227 10.9551 16.91 10.8307 16.7852 10.6307L15.2451 8.16709C15.0342 7.82999 15.129 7.38083 15.4573 7.16396C15.7851 6.9471 16.2218 7.04464 16.4327 7.38219L17.4227 8.96605L19.685 5.85686C19.9184 5.53565 20.3609 5.47031 20.6732 5.71032C20.9856 5.95032 21.0491 6.40538 20.8153 6.7266L17.9437 10.6733C17.8105 10.8571 17.6009 10.9641 17.379 10.9641Z" fill="#FC3640"/>
                      <path d="M18.0432 16.2229C17.9294 16.2229 17.8142 16.1948 17.7075 16.1349C15.1183 14.6912 13.4789 12.9672 12.6954 10.8634C12.0058 9.01232 12.0632 7.16305 12.1183 5.37457C12.1333 4.89411 12.147 4.43996 12.147 3.9849C12.147 3.69317 12.3168 3.43003 12.5776 3.3166L17.7679 1.05764C18.1266 0.90157 18.5413 1.07398 18.693 1.44329C18.8448 1.81259 18.6772 2.23862 18.318 2.39469L13.5543 4.46809C13.5486 4.78794 13.5389 5.1078 13.5292 5.42085C13.4242 8.82494 13.3245 12.0403 18.3803 14.8587C18.723 15.0497 18.8501 15.4902 18.6644 15.8427C18.5364 16.0855 18.2938 16.2229 18.0432 16.2229Z" fill="#FC3640"/>
                      <path d="M18.0426 16.2229C17.792 16.2229 17.5493 16.0855 17.4214 15.8427C17.2357 15.4902 17.3627 15.0497 17.7055 14.8587C22.7608 12.0403 22.6616 8.82494 22.5566 5.42085C22.5469 5.1078 22.5372 4.78794 22.5314 4.46809L17.7677 2.39469C17.4086 2.23862 17.2409 1.81259 17.3927 1.44329C17.5445 1.07398 17.9587 0.90157 18.3178 1.05764L23.5077 3.3166C23.7689 3.43003 23.9383 3.69362 23.9383 3.9849C23.9383 4.43996 23.9524 4.89365 23.967 5.37457C24.0221 7.16305 24.0795 9.01232 23.3899 10.8634C22.6064 12.9667 20.9671 14.6912 18.3778 16.1349C18.2711 16.1943 18.1559 16.2229 18.0426 16.2229Z" fill="#FC3640"/>
                      <path d="M22.2159 22.3334H1.78364C0.800279 22.3334 0 21.5104 0 20.4986V7.40034C0 6.3886 0.800279 5.56604 1.78364 5.56604H9.84908C10.2391 5.56604 10.555 5.89089 10.555 6.29196C10.555 6.69303 10.2391 7.01788 9.84908 7.01788H1.78364C1.5785 7.01788 1.41174 7.18937 1.41174 7.40034V20.4986C1.41174 20.7096 1.5785 20.8811 1.78364 20.8811H22.2155C22.4206 20.8811 22.5874 20.7096 22.5874 20.4986V15.4966C22.5874 15.0955 22.9033 14.7707 23.2932 14.7707C23.6832 14.7707 23.9991 15.0955 23.9991 15.4966V20.4986C23.9996 21.5104 23.1993 22.3334 22.2159 22.3334Z" fill="#FC3640"/>
                      <path d="M6.78867 16.2225H3.80548C3.41549 16.2225 3.09961 15.8977 3.09961 15.4966C3.09961 15.0956 3.41549 14.7708 3.80548 14.7708H6.78867C7.17866 14.7708 7.49454 15.0956 7.49454 15.4966C7.49454 15.8977 7.17822 16.2225 6.78867 16.2225Z" fill="#FC3640"/>
                      <path d="M9.84952 11.613H0.705869C0.315877 11.613 0 11.2881 0 10.887C0 10.486 0.315877 10.1611 0.705869 10.1611H9.84952C10.2395 10.1611 10.5554 10.486 10.5554 10.887C10.5554 11.2881 10.2391 11.613 9.84952 11.613Z" fill="#FC3640"/>
                    </svg> 
                  </div>
                  <h4 class="h4 mb-2 min-h-50">Soyez sûrs d’être payés</h4>
                  <p class="t1 mt-2 color-secondary">
                    United 3D Makers sécurise votre paiement avant le début du projet. Une fois la mission terminée, vous recevez l’argent à votre rythme.
                  </p>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6">
                <div class="p-3 text-center">
                  <div class="mb-4">
                    <svg width="54" height="54" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.6928 22.1312C11.5741 22.1312 11.4559 22.1038 11.3478 22.0487L2.39093 17.486C2.15039 17.3634 2 17.1238 2 16.8626V7.12146C2 6.87396 2.13522 6.64455 2.35644 6.51661C2.57766 6.38911 2.85223 6.38161 3.08035 6.49764L12.0373 11.0448C12.2783 11.167 12.4291 11.407 12.4291 11.6687V21.4253C12.4291 21.6732 12.2939 21.9026 12.0722 22.0301C11.9554 22.0972 11.8239 22.1312 11.6928 22.1312ZM3.47174 16.4382L10.9569 20.2509V12.0935L3.47174 8.29366V16.4382Z" fill="#FC3640"/>
                      <path d="M10.957 21.4257V21.4253H12.4288L10.957 21.4257Z" fill="#FC3640"/>
                      <path d="M12.6862 3.78618C12.5703 3.78618 12.4525 3.75971 12.3426 3.70412L11.3469 3.19853C10.9877 3.01632 10.8507 2.58882 11.0406 2.24426C11.2305 1.89971 11.6762 1.76824 12.0354 1.95044L13.0311 2.45603C13.3903 2.63824 13.5274 3.06574 13.3374 3.41029C13.2054 3.64941 12.9502 3.78618 12.6862 3.78618Z" fill="#FC3640"/>
                      <path d="M17.3285 6.14338C17.2126 6.14338 17.0949 6.1169 16.985 6.06132L14.6633 4.88249C14.3041 4.70029 14.1671 4.27278 14.357 3.92823C14.5469 3.58367 14.9926 3.4522 15.3518 3.6344L17.6735 4.81323C18.0327 4.99543 18.1697 5.42293 17.9798 5.76749C17.8478 6.00661 17.5925 6.14338 17.3285 6.14338Z" fill="#FC3640"/>
                      <path d="M20.648 8.90818C20.2415 8.90818 19.9122 8.5923 19.9122 8.2023V7.54628L19.3078 7.23966C18.9486 7.05746 18.8116 6.62996 19.0015 6.2854C19.1915 5.94084 19.6371 5.80937 19.9963 5.99158L20.992 6.49717C21.233 6.61937 21.3839 6.85937 21.3839 7.12099V8.20186C21.3839 8.5923 21.0546 8.90818 20.648 8.90818Z" fill="#FC3640"/>
                      <path d="M20.648 13.9611C20.2414 13.9611 19.9121 13.6452 19.9121 13.2552V10.7286C19.9121 10.3386 20.2414 10.0227 20.648 10.0227C21.0545 10.0227 21.3838 10.3386 21.3838 10.7286V13.2552C21.3838 13.6452 21.0545 13.9611 20.648 13.9611Z" fill="#FC3640"/>
                      <path d="M19.6524 18.0754C19.3888 18.0754 19.134 17.9396 19.002 17.7004C18.8116 17.3563 18.9478 16.9288 19.307 16.7457L19.9113 16.4378V15.7813C19.9113 15.3913 20.2406 15.0754 20.6472 15.0754C21.0537 15.0754 21.383 15.3913 21.383 15.7813V16.8622C21.383 17.1234 21.2326 17.3629 20.9921 17.4856L19.9968 17.9925C19.8869 18.049 19.7692 18.0754 19.6524 18.0754Z" fill="#FC3640"/>
                      <path d="M15.0098 20.4414C14.7463 20.4414 14.4915 20.3056 14.3595 20.0664C14.1691 19.7223 14.3052 19.2948 14.6644 19.1117L16.9865 17.929C17.3457 17.7463 17.7909 17.8769 17.9818 18.2215C18.1722 18.5656 18.0361 18.9931 17.6769 19.1762L15.3547 20.3589C15.2444 20.4145 15.1262 20.4414 15.0098 20.4414Z" fill="#FC3640"/>
                      <path d="M11.6914 22.1312C11.4279 22.1312 11.1731 21.9953 11.0411 21.7562C10.8507 21.4121 10.9868 20.9846 11.346 20.8015L12.3413 20.2946C12.7 20.1119 13.1457 20.2425 13.3366 20.5871C13.527 20.9312 13.3908 21.3587 13.0316 21.5418L12.0364 22.0487C11.926 22.1047 11.8078 22.1312 11.6914 22.1312Z" fill="#FC3640"/>
                      <path d="M19.6547 8.33292C19.3907 8.33292 19.1359 8.19659 19.0035 7.95704C18.8135 7.61248 18.9506 7.18542 19.3098 7.00278L20.3055 6.49719C20.6647 6.31499 21.1099 6.44646 21.3003 6.79101C21.4903 7.13557 21.3532 7.56263 20.994 7.74527L19.9983 8.25086C19.8884 8.30644 19.7706 8.33292 19.6547 8.33292Z" fill="#FC3640"/>
                      <path d="M15.0102 10.6902C14.7462 10.6902 14.4914 10.5538 14.359 10.3143C14.169 9.96973 14.3061 9.54267 14.6653 9.36002L16.9869 8.18164C17.3461 7.99944 17.7913 8.13091 17.9817 8.47547C18.1717 8.82002 18.0346 9.24708 17.6754 9.42973L15.3538 10.6081C15.2438 10.6637 15.1261 10.6902 15.0102 10.6902Z" fill="#FC3640"/>
                      <path d="M11.6938 12.3745C11.4298 12.3745 11.175 12.2382 11.0425 11.9987C10.8526 11.6541 10.9897 11.227 11.3489 11.0444L12.3446 10.5388C12.7038 10.3566 13.149 10.4881 13.3394 10.8326C13.5298 11.1772 13.3923 11.6042 13.0331 11.7869L12.0374 12.2925C11.9274 12.3481 11.8097 12.3745 11.6938 12.3745Z" fill="#FC3640"/>
                      <path d="M11.6918 12.3746C11.5759 12.3746 11.4582 12.3481 11.3483 12.2925L2.39185 7.74529C2.15085 7.62309 2 7.38309 2 7.12147C2 6.85985 2.15085 6.61985 2.39185 6.49765L11.3483 1.95044C11.7075 1.76824 12.1527 1.89971 12.3431 2.24426C12.533 2.58882 12.396 3.01588 12.0368 3.19853L4.30924 7.12147L12.0368 11.0444C12.396 11.2266 12.533 11.6541 12.3431 11.9987C12.2111 12.2378 11.9558 12.3746 11.6918 12.3746Z" fill="#FC3640"/>
                      <path d="M11.6929 24C11.2863 24 10.957 23.6841 10.957 23.2941V0.705882C10.957 0.315882 11.2863 0 11.6929 0C12.0995 0 12.4288 0.315882 12.4288 0.705882V23.2941C12.4288 23.6841 12.0995 24 11.6929 24Z" fill="#FC3640"/>
                    </svg>
                  </div>
                  <h4 class="h4 mb-2 min-h-50">Travaillez sereinement</h4>
                  <p class="t1 mt-2 color-secondary">
                    Vous avez des questions ? Notre équipe vous accompagne tout au long de vos missions.
                  </p>
                </div>
              </div>
            </div>
          </div><!-- reasurance -->
        </div>
    </section>

    <section class="background-light pt-5 pb-5">
      <div class="container pt-5 pb-5">
        <!-- <div class="d-flex">
          <img src="" alt="">
        </div> -->
        <div class="row">
          <div class="col-xxl-8 col-xl-7 col-lg-10 offset-lg-2">

            <h4 id="inscriptionMaker" class="h3 text-center">Nous rejoindre</h4>

            <form class="row g-2 mt-4" @submit.prevent="() => registerProspectClient">
              <div class="col-md-6">
                <input type="text" @input="updateFirstname"  placeholder="Prénom" class="form-control" required>
              </div>
              <div class="col-md-6">
                <input @input="updateLastname" type="text" placeholder="Nom" class="form-control" required>
              </div>
              <div class="col-md-12">
                <input @input="updateEmail" type="email" placeholder="Adresse e-mail pro" class="form-control" required>
              </div>
              <div class="ms-1 form-check">
                <input @input="updatePrinter" class="form-check-input" type="checkbox" id="for-maker">
                <label class="form-check-label" for="for-maker">
                  Vous êtes imprimeur
                </label>
              </div>
              <div class="ms-1 form-check">
                <input @input="updateDesigner" class="form-check-input" type="checkbox" id="for-designer">
                <label class="form-check-label" for="for-designer">
                  Vous êtes designer
                </label>
              </div>

              <div class="text--error">
                {{ errorProspect }}
              </div>

              <div class="text-center">
                <button class="btn-u3dm btn-u3dm--primary" @click="registerProspectClient">Enregistrer</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </section>

   

    <!-- formulaire inscription -->
    <section class="pt-5 pb-5">
      <div class="container pt-5 pb-5">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <h5 class="h3 text-center">Nos exigences</h5>
              <ul class="mt-5 list-style-none list-exigence">
                <li class="h6">- Vous êtes une entreprise professionnelle établie dans l’UE et inscrite au Registre du Commerce et des Sociétés.</li>
                <li class="h6">- Pour l’impression 3D : vous utilisez au moins une machine de fabrication additive professionnelle (pas uniquement des imprimantes de bureau).</li>
                <li class="h6">- Pour la modélisation 3D : vous avez acquis les licences des logiciels que vous utilisez.</li>
                <li class="h6">- Toutes vos productions sont faites en interne, pas de sous traitance.</li>
                <li class="h6">- Vous avez mis en place un contrôle qualité et vous respectez les normes des machines, des matériaux et des logiciels que vous utilisez.</li>
              </ul>
            </div></div>
        </div>
    </section>

    <!-- Ils nous font confiance -->
    <section class="p-5 pb-5 background-light">
      <div class="container pb-5">
        <h2 class="h2 text-center mb-5">Ils nous font confiance</h2>
        <div class="mt-3 d-flex justify-content-center align-items-center flex-wrap">
          <div class="min-w-154 p-3"><img width="200" :src="require('@/assets/images/logo-confiance-lg/Mr_Bricolage_logo-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="200" :src="require('@/assets/images/logo-confiance-lg/Schuco-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="200" :src="require('@/assets/images/logo-confiance-lg/ELSAN-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="200" :src="require('@/assets/images/logo-confiance-lg/Bilfinger_2012-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="200" :src="require('@/assets/images/logo-confiance-lg/le-lit-national-2.png')" alt="logo entreprise"></div>
        </div>
        <div class="mt-3 d-flex justify-content-center align-items-center flex-wrap">
          <div class="min-w-154 p-3"><img width="150" :src="require('@/assets/images/logo-confiance-lg/AC-Media-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="200" :src="require('@/assets/images/logo-confiance-lg/Orange_Business_Services-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="150" :src="require('@/assets/images/logo-confiance-lg/logo_velecta-paris_noir-1-2.png')" alt="logo entreprise"></div>
          <div class="min-w-154 p-3"><img width="150" :src="require('@/assets/images/logo-confiance-lg/schiever-2.png')" alt="logo entreprise"></div>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import NavigationSite from "../ui/NavigationSite.vue";

export default {
  name: 'ProspectMaker',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Inscription Maker',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  components: { NavigationSite },
  data() {
    return {
      errorProspect: '',
    }
  },
  computed: {
    ...mapState('prospectMaker', {
      firstname: state => state.firstname,
      lastname: state => state.lastname,
      email: state => state.email,
      printer: state => state.printer,
      designer: state => state.designer,
    })
  },
  methods: {
    ...mapActions('prospectMaker', [
      'updateFirstname',
      'updateLastname',
      'updateEmail',
      'updatePrinter',
      'updateDesigner',
    ]),
    registerProspectClient: function () {
      this.$googleTagManager(this.id,this.ismaker!=null,'registration','maker.prospect_creation.attempt')

      var formData = new FormData();
      formData.append('firstname', this.firstname);
      formData.append('lastname', this.lastname);
      formData.append('email', this.email);
      formData.append('printer', this.printer);
      formData.append('designer', this.designer);

      this.errorProspect = ''

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "prospect/maker", formData).then((response) => {
        this.$googleTagManager(this.id,this.ismaker!=null,'registration','maker.prospect_creation.success')
        this.$router.push({ name: 'subscription-maker', params: { prospectToken: response.body.prospectToken }})
      }, (response) => {
        for (var key of Object.keys(response.body)) {
          this.errorProspect = this.errorProspect + response.body[key][0]
        }
      })
    },
  },
  mounted() {
    this.$googleTagManager(this.id,this.ismaker!=null,'registration','maker.prospect_creation.view')
  }
}
</script>