<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div class="m-login-content-save-project modal-login-content-appear">

        <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-prospect text-center">

          <h4 class="m-login-heading h4">Ajouter un nouveau scanner</h4>

          <input class="input-add" v-model="brand" type="text" placeholder="Marque"> <br>
          <input class="input-add" v-model="model" type="text" placeholder="Modèle">
          <div v-if="formError !== ''" class="text-danger mt-3">
            {{ formError }}
          </div>
          <br>
          <a href="#" class="btn-u3dm btn-u3dm--primary" v-on:click="(e) => {e.preventDefault(); addNewScanner()}">Ajouter</a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalAddNewScanner',
  data() {
    return {
      brand: '',
      model: '',
      formError: '',
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    addNewScanner() {
      if(this.brand === '' || this.model === '') {
        this.formError = 'Tout les champs sont obligatoires.'
      } else {
        this.formError = ''
        let formData = new FormData();
        formData.append("brand", this.brand);
        formData.append("model", this.model);

        this.$http.post(process.env.VUE_APP_API_BASE_URL + 'scanner/addNewScanner', formData, {credentials: true})
            .then(() => {
              window.location.reload()
            })
      }
    },
  }
}
</script>