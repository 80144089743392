function gtag_report_event2(id, bmaker, g, e, i = null) {

    let cType = 'client'
    if (id <= 0) {
        cType = 'prospect'
        id = -1 * id
    }

    if (bmaker) { cType = 'maker' }
    // eslint-disable-next-line no-undef
    dataLayer.push({
        event: 'track_event',
        event_name: e,
        event_group: g,
        event_info: i,
        user_id: id,
        customer_type: cType
    })
    // eslint-disable-next-line no-undef
    console.log('event_name :', e, 'event_group :', g)
}

export { gtag_report_event2 }