import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        firstname: '',
        lastname: '',
        email: '',
        printer: 0,
        designer: 0,
        started: false,
    },
    mutations: {
        CHANGE_FIRSTNAME(state, payload = null) {
            state.firstname = payload
        },
        CHANGE_LASTNAME(state, payload = null) {
            state.lastname = payload
        },
        CHANGE_EMAIL(state, payload = null) {
            state.email = payload
        },
        CHANGE_PRINTER(state, payload = null) {
            state.printer = payload
        },
        CHANGE_DESIGNER(state, payload = null) {
            state.designer = payload
        },
        CHANGE_STARTED(state, payload = null) {
            state.started = payload
        },
    },
    actions: {
        updateFirstname({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_FIRSTNAME', e.target.value)
        },
        updateLastname({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_LASTNAME', e.target.value)
        },
        updateEmail({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_EMAIL', e.target.value)
        },
        updatePrinter({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            if(e.target.checked) {
                commit('CHANGE_PRINTER', 1)
            } else {
                commit('CHANGE_PRINTER', '')
            }
        },
        updateDesigner({commit, dispatch}, e) {
            dispatch('googleTagStarted')
            if(e.target.checked) {
                commit('CHANGE_DESIGNER', 1)
            } else {
                commit('CHANGE_DESIGNER', '')
            }
        },
        googleTagStarted({commit,state}) {
            if(!state.started) {
                commit('CHANGE_STARTED', true)
                Vue.prototype.$googleTagManager(this.id,this.ismaker!=null,'registration','maker.prospect_creation.started')
            }
        }
    },
}