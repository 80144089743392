<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="mt-5 h4">Mon rendez-vous</h4>

            <div v-if="rdvDate">
              Prochain rendez vous avec United 3D Maker le {{rdvDate}}. <a v-if="rdvGoogleMeetLink" target="_blank" :href="rdvGoogleMeetLink">Lien visio Google Meet.</a>
            </div>
            <div v-if="rdvWaiting">
              En attente de confirmation du rendez-vous.
            </div>

            <h6 class="mt-5 h6">
              L'objet de ce rendez-vous est de nous présenter votre activité, et de vous faire découvrir la plateforme.
              <br> Nous vous précisons que ce rendez-vous se fera en visio conférence.
            </h6>
            <form @submit.prevent="() => sendRdv" class="mt-5">
              <div class="row g-2">
                <div class="col-md-2">
                  Vos créneaux de disponibilités*
                </div>
                <div class="col-md-10">
                  <textarea maxlength="500" v-model="availabilityDates" rows="6" cols="107%" required type="text"></textarea>
                  <div class="t3 mt-1 color-secondary">
                    Merci de bien vouloir mettre au minimum 3 créneaux pour faire un rendez-vous avec nous de 30 minutes environ.
                  </div>
                </div>
                <div class="col-md-2">
                  Vos compétences
                </div>
                <div class="col-md-10">
                  <span class="badge-material" :class="skill.selected ? 'active' : ''" v-for="(skill, index) in skills" :key="index" @click="selectSkill(skill)">{{ skill.name }}</span>
                </div>
                <div class="col-md-2">
                  <label for="description">Parlez nous de votre activité en quelques mots*</label>
                </div>
                <div class="col-md-10">
                  <textarea name="description" maxlength="500" v-model="description" required type="text" rows="6" cols="107%"></textarea>
                  <span class="project-form__helper">{{ nbWords }} / 500</span>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10 text--error">
                  {{ errorDescription }}
                </div>
                <div class="col-md-2">
                  <label for="portfolio">Quelques images de vos réalisations* <br>(fichiers image)</label>
                </div>
                <div class="col-md-10">
                  <input @change="uploadPortfolio($event.target.files[0])" type="file" name="portfolio" id="portfolio-upload" accept="image/*">
                  <div class="t3 mt-1 color-secondary">
                    Déposez des exemples de vos réalisations
                  </div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10">
                  <div v-for="(portfolioFile, index) in this.portfolioFiles" :key="index">{{portfolioFile.name}} <a style="color: #000" @click="(e) => {e.preventDefault(); removePortfolioFile(index)}" href="#"><i class="bi bi-trash"></i></a></div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10">
                  <span style="margin-right: 10px" v-for="(savedPortfolioFile, index) in this.savedPortfolioFiles" :key="index">
                    <div style="width: 100px; display: inline-block">
                      <a target="_blank" :href="portfolioImgBaseUrl + savedPortfolioFile.id">
                        <img width="100px" :src="portfolioImgBaseUrl + savedPortfolioFile.id">
                      </a>
                      <br>
                      <div class="text-center mt-1">
                        <a style="color: #000" @click="(e) => { e.preventDefault(); removeSavedPortfolioFile(index);}" href="#">
                         <i class="bi bi-trash"></i>
                       </a>
                      </div>
                    </div>
                  </span>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10 text--error">
                  {{ errorPortfolio }}
                </div>
              </div><!-- row -->

              <div class="mt-3" style="display: flex; justify-content: end">
                <button class="btn-u3dm btn-u3dm--primary" @click="sendRdv" @keyup.enter="sendRdv">Enregistrer</button>
              </div>
            </form>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

    <transition name="fade-modal-login">
      <modal-save-rdv v-if="isModalSaveRdvVisible"></modal-save-rdv>
    </transition>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import ModalSaveRdv from '../components/modal/ModalSaveRdv.vue';

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, ModalSaveRdv},
  name: "MakerRdv",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Rendez-vous',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'ref/skill', {credentials: true})
        .then((response) => {
          this.skills = response.body

          this.$http.get(process.env.VUE_APP_API_BASE_URL + 'rdv/get', {credentials: true})
              .then((response) => {
                let maker = response.body.maker
                let rendezvous = response.body.rendezvous

                this.description = maker.bio
                this.savedPortfolioFiles = maker.portfolio_images
                this.availabilityDates = rendezvous.availability_dates

                // Set already selected skills
                for (let makerSkill of maker.design_skills) {
                  for (let skill of this.skills) {
                    if(makerSkill.id === skill.id) {
                      skill.selected = true
                    }
                  }
                }

                this.$forceUpdate()
              })
        })

    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'rdv/next', {credentials: true})
        .then(({body}) => {
          if(body === true) {
            this.rdvWaiting = true
          } else {
            this.rdvDate = body.date
            let test = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(body.google_meet_link)
            if(test) {
              this.rdvGoogleMeetLink = body.google_meet_link
            } else {
              this.rdvGoogleMeetLink = 'https://' + body.google_meet_link
            }
          }
        })
  },
  data() {
    return {
      errorSkill: '',
      errorPortfolio: '',
      errorDescription: '',
      skills: [],
      availabilityDates: '',
      description: '',
      portfolioFiles: [],
      savedPortfolioFiles: [],
      removedSavedPortfolioFiles: [],
      isModalSaveRdvVisible: false,
      rdvGoogleMeetLink: null,
      rdvDate: null,
      rdvWaiting: false,
      portfolioImgBaseUrl: process.env.VUE_APP_API_BASE_URL + 'user/portfolio/',
    }
  },
  computed: {
    nbWords() {  return this.description !== undefined && this.description.length > 1 ? this.description.length : 0 }
  },
  methods: {
    selectSkill(skill) {
      if(skill.selected) {
        skill.selected = false
      } else {
        skill.selected = true
      }
      this.$forceUpdate();
    },
    uploadPortfolio(file) {
      let fileExist = this.portfolioFiles.filter((portfolioFile) => {
        return portfolioFile.name === file.name;
      });
      if(fileExist.length === 0) {
        this.portfolioFiles.push(file);
      } else {
        alert('Cette image a déjà été ajoutée')
      }
    },
    removePortfolioFile(index) {
      this.portfolioFiles.splice(index, 1)
    },
    removeSavedPortfolioFile(index) {
      this.removedSavedPortfolioFiles.push(this.savedPortfolioFiles[index])
      this.savedPortfolioFiles.splice(index, 1)
    },
    sendRdv() {
      this.errorSkill = ''
      this.errorPortfolio = ''
      this.errorDescription = ''

      for (let portfolioFile of this.portfolioFiles) {
        if(!portfolioFile['type'].includes('image')) {
          this.errorPortfolio = "Les images du portfolio doivent toutes être des fichiers image."
        }
      }

      if(this.savedPortfolioFiles.length <= 0 && this.portfolioFiles.length <= 0) {
        this.errorPortfolio = "Les images de votre portfolio sont obligatoires."
      }

      if(this.description === '') {
        this.errorDescription = "Merci de décrire votre activité."
      }

      if(!this.errorPortfolio && this.availabilityDates && !this.errorDescription) {
        var formData = new FormData();
        formData.append('availabilityDates', this.availabilityDates);
        formData.append('description', this.description);
        this.portfolioFiles.forEach((portfolioFile) => {
          formData.append("portfolios[]", portfolioFile);
        })
        this.removedSavedPortfolioFiles.forEach((removedSavedPortfolioFile) => {
          formData.append("removedPortfolios[]", removedSavedPortfolioFile.id);
        })
        this.skills.forEach((skill) => {
          if(skill.selected) {
            formData.append("skills[]", skill.id);
          }
        })

        this.$http.post(process.env.VUE_APP_API_BASE_URL + "rdv/send", formData, {credentials: true})
            .then(() => {
              this.isModalSaveRdvVisible = true
            })
      }

    },
  }
}
</script>
