import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        firstname: '',
        lastname: '',
        email: '',
        customer_type: 'company',
        started: false,
    },
    mutations: {
        CHANGE_FIRSTNAME(state, payload = null) {
            state.firstname = payload
        },
        CHANGE_LASTNAME(state, payload = null) {
            state.lastname = payload
        },
        CHANGE_EMAIL(state, payload = null) {
            state.email = payload
        },
        CHANGE_CUSTOMER_TYPE(state, payload = null) {
            state.customer_type = payload
        },
        CHANGE_STARTED(state, payload = null) {
            state.started = payload
        },
    },
    actions: {
        updateFirstname({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_FIRSTNAME', e.target.value)
        },
        updateLastname({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_LASTNAME', e.target.value)
        },
        updateEmail({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_EMAIL', e.target.value)
        },
        updateCustomerType({commit,dispatch}, e) {
            dispatch('googleTagStarted')
            commit('CHANGE_CUSTOMER_TYPE', e.target.value)
        },
        googleTagStarted({commit,state}) {
            if(!state.started) {
                commit('CHANGE_STARTED', true)
                Vue.prototype.$googleTagManager(this.id,this.ismaker!=null,'registration','client.prospect_creation.started')
            }
        }
    },
}