<template>
  <nav id="navOuter" class="nav-outer" 
	:class="[isThemeTransparent == true && navThemeTransparent == true ? 'nav-theme--transparent' : '', navOuterIsHidden == true ? 'nav-outer--hide' : '', navIsOpen == true ? 'mobile-nav--is-open' : '']">
    <div class="container">
      <div class="navbar-site">
                    
				<!-- hamburger -->
				<div id="btnHambuger" class="hamburger hide-on-desktop" @click="navIsOpen = true">
					<svg class="i-hamburger" width="19" height="14" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM18 2C18.5523 2 19 1.55228 19 1C19 0.447715 18.5523 0 18 0V2ZM1 2H18V0H1V2Z" />
						<path d="M1 5.5C0.447715 5.5 0 5.94772 0 6.5C0 7.05228 0.447715 7.5 1 7.5V5.5ZM18 7.5C18.5523 7.5 19 7.05228 19 6.5C19 5.94772 18.5523 5.5 18 5.5V7.5ZM1 7.5H18V5.5H1V7.5Z" />
						<path d="M1 11.5C0.447715 11.5 0 11.9477 0 12.5C0 13.0523 0.447715 13.5 1 13.5V11.5ZM12 13.5C12.5523 13.5 13 13.0523 13 12.5C13 11.9477 12.5523 11.5 12 11.5V13.5ZM1 13.5H12V11.5H1V13.5Z" />
					</svg>
				</div>
                
				<!-- branding -->
				<div class="branding">
					<a :href="urlWWWHome">
						<div class="branding-desktop">
							<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									viewBox="0 0 1720 291.33" style="enable-background:new 0 0 1720 291.33;" xml:space="preserve">

									<path class="st0" d="M585.18,60.33c-0.02,22.03-11.96,33.1-35.93,33.08c-23.97-0.03-35.88-11.13-35.85-33.16l0.06-47.8l-26.33-0.03
											l-0.05,45.87c-0.05,38.8,21.97,55.87,62.15,55.91c40.18,0.05,62.24-16.97,62.28-55.76l0.05-45.87l-26.33-0.03L585.18,60.33z"/>
									<polygon class="st0" points="747.44,85.88 661.47,12.62 633.76,12.58 633.64,112.63 659.97,112.66 660.05,41.57 743.25,112.76 
											773.73,112.8 773.85,12.75 747.52,12.72 	"/>
									
											<rect x="759.13" y="49.65" transform="matrix(1.082690e-03 -1 1 1.082690e-03 745.462 871.9022)" class="st0" width="100.05" height="26.33"/>
									<polygon class="st0" points="894.87,112.95 921.2,112.97 921.3,33.3 980.19,33.37 980.21,13 836.24,12.83 836.22,33.2 
											894.97,33.27 	"/>
									<polygon class="st0" points="1116.49,92.84 1020.32,92.73 1020.35,72.91 1110.42,73.02 1110.44,53.21 1020.37,53.09 1020.4,33.42 
											1116.57,33.53 1116.59,13.17 994.09,13.02 993.97,113.06 1116.47,113.21 	"/>
									<path class="st0" d="M1277.84,63.38c0.04-31.59-27.1-50.06-72.28-50.11l-70.95-0.09l-0.12,100.04l71.92,0.09
											C1250.49,113.37,1277.8,94.98,1277.84,63.38z M1160.86,92.48l0.07-58.48l43.37,0.05c30.76,0.04,46.83,8.65,46.8,29.29
											c-0.03,20.65-16.11,29.22-46.87,29.18L1160.86,92.48z"/>
									<path class="st0" d="M584.98,225.67c10.54-5.39,14.7-13.01,14.71-20.77c0.02-17.74-23.8-28.85-61.49-28.9
											c-21.76-0.02-40.05,4.25-56.83,13.38l-0.02,22.72l0.55,0.41c15.81-10.65,34.1-15.61,57.11-15.58c21.2,0.02,35.74,4.75,35.74,11.54
											c-0.01,5.54-7.08,8.3-21.35,8.29l-40.46-0.05l-0.02,19.82l40.04,0.04c17.05,0.02,25.77,3.36,25.76,9.6
											c-0.01,8.59-14.29,13.42-39.37,13.39c-23.97-0.03-44.89-5.46-60.12-15.59l-0.69,0.41l-0.03,22.86
											c15.65,8.62,36.01,13.21,58.87,13.24c41.57,0.05,66.25-11.98,66.28-32.21C603.67,238.71,598.56,230.4,584.98,225.67z"/>
									<path class="st0" d="M691.34,178.96l-70.95-0.09l-0.12,100.05l71.92,0.09c44.07,0.05,71.38-18.34,71.42-49.94
											C763.65,197.48,736.52,179.02,691.34,178.96z M690,258.22l-43.37-0.05l0.07-58.48l43.37,0.06c30.76,0.04,46.82,8.64,46.8,29.29
											C736.84,249.69,720.76,258.26,690,258.22z"/>
									<polygon class="st0" points="902.88,255.71 855.16,179.16 824.95,179.12 824.83,279.17 850.33,279.2 850.41,214.21 890.51,279.24 
											915.18,279.28 955.44,214.34 955.36,279.32 980.86,279.36 980.98,179.31 950.77,179.27 	"/>
									<path class="st0" d="M1055.84,179.4l-63.86,99.97l28.27,0.03l12.5-20.07l70.67,0.09l11.75,20.11l28.27,0.03l-60.43-100.12
											L1055.84,179.4z M1045.23,238.98l23.88-38.36l22.4,38.42L1045.23,238.98z"/>
									<polygon class="st0" points="1283.53,180.23 1283.11,179.68 1246.67,179.63 1180.93,221.96 1180.98,179.55 1154.66,179.52 
											1154.54,279.57 1180.86,279.6 1180.9,248.7 1203.78,233.48 1252.23,279.69 1286.73,279.73 1287.15,279.17 1224.17,219.79 	"/>
									<polygon class="st0" points="1298.25,279.74 1420.75,279.89 1420.77,259.52 1324.6,259.41 1324.63,239.59 1414.7,239.7 
											1414.72,219.89 1324.65,219.77 1324.68,200.1 1420.84,200.21 1420.87,179.84 1298.37,179.69 	"/>
									<path class="st0" d="M1568.15,213.84c0.02-21.9-18.67-33.83-52.2-33.88l-77.04-0.09l-0.12,100.05l26.33,0.03l0.04-32.57l40.19,0.05
											l33.49,32.61l32.01,0.04l0.42-0.56l-36.13-33.58C1556.47,242.23,1568.13,231.15,1568.15,213.84z M1465.18,226.6l0.03-25.92
											l50.02,0.06c17.6,0.02,26.6,2.8,26.59,13.05c-0.01,10.12-9.03,12.88-26.62,12.86L1465.18,226.6z"/>
									<path class="st0" d="M1610.86,207.65c0.01-6.24,11.93-9.68,40.06-9.65c19.67,0.02,39.21,2.95,58.33,8.66l0.83-0.28l0.02-21.06
											c-18.29-5.43-38.1-8.08-59.71-8.11c-43.23-0.06-65.83,10.72-65.86,31.23c-0.06,47.39,109.17,21.06,109.14,41.15
											c-0.01,6.79-12.48,11.49-41.86,11.45c-27.44-0.03-47.25-3.24-65.95-10.33l-0.83,0.41l-0.03,22.17c17.04,5.43,40.03,8.51,65.25,8.54
											c49.33,0.06,69.71-10.03,69.74-32.89C1720.06,202.24,1610.83,227.75,1610.86,207.65z"/>
									<path class="st1" d="M260.21,167.87c0,54.39-52.8,98.64-117.7,98.64s-117.7-44.25-117.7-98.64V24.81h40.24v143.06
											c0,37.46,34.75,67.93,77.46,67.93s77.46-30.47,77.46-67.93V0h-89.86v167.87c0,15.88,6.37,30.97,17.44,42.91
											c-1.66,0.13-3.34,0.21-5.04,0.21c-29.03,0-52.65-19.34-52.65-43.12V0H0v167.87c0,68.07,63.93,123.46,142.51,123.46h65.05
											c78.58,0,142.51-55.38,142.51-123.46V0h-89.87V167.87z M154.92,167.87V24.81h40.24v143.06c0,13.72-7.89,25.94-20.13,33.84
											C162.49,193.58,154.92,181.05,154.92,167.87z M285.02,24.81h40.24v143.06c0,47.67-40.56,87.55-94.28,96.68
											c32.89-22.63,54.04-57.55,54.04-96.68V24.81z"/>
							
							</svg>
						</div>
						<div class="branding-mobile hide-on-desktop">
							<svg class="i-brand--mobile" width="29" height="23" viewBox="0 0 29 23" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.8772 13.2527C20.8772 17.5474 16.6415 21.0413 11.4339 21.0413C6.22629 21.0413 1.99054 17.5474 1.99054 13.2527V1.95867H5.21931V13.2527C5.21931 16.2109 8.00607 18.616 11.4339 18.616C14.8617 18.616 17.6485 16.2109 17.6485 13.2527V0H10.4386V13.2527C10.4386 14.5057 10.9509 15.6982 11.8379 16.6401C11.7061 16.6516 11.5715 16.6574 11.4339 16.6574C9.10379 16.6574 7.20985 15.1307 7.20985 13.2527V0H0V13.2527C0 18.6276 5.12857 23 11.4339 23H16.6532C22.9585 23 28.0871 18.6276 28.0871 13.2527V0H20.8772V13.2527ZM12.4292 13.2527V1.95867H15.6579V13.2527C15.6579 14.3358 15.0256 15.3007 14.0421 15.9257C13.038 15.2834 12.4292 14.2925 12.4292 13.2527ZM22.8678 1.95867H26.0966V13.2527C26.0966 17.0174 22.8414 20.1657 18.5325 20.8858C21.17 19.0999 22.8678 16.3434 22.8678 13.2527V1.95867Z"/>
							</svg>
						</div>
					</a>
				</div>

				<div id="navList" class="nav-list" :class="navIsOpen == true ? 'nav-list--is-open' : ''">
					<!-- close hamburger -->
					<div id="closeNav" class="close-nav hide-on-desktop" @click="navIsOpen = false">
						<svg class="i-close" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.1647 17.94C16.9669 17.94 16.7686 17.8645 16.6177 17.7136L6.285 7.38043C5.98313 7.07855 5.98313 6.58871 6.285 6.28683C6.58687 5.98496 7.07672 5.98496 7.37859 6.28683L17.7117 16.62C18.0136 16.9218 18.0136 17.4117 17.7117 17.7136C17.5603 17.8645 17.3625 17.94 17.1647 17.94Z" fill="#161616"/>
							<path d="M6.83399 17.94C6.63617 17.94 6.43789 17.8645 6.28695 17.7136C5.98508 17.4117 5.98508 16.9218 6.28695 16.62L16.6201 6.28683C16.922 5.98496 17.4118 5.98496 17.7137 6.28683C18.0156 6.58871 18.0156 7.07855 17.7137 7.38043L7.38102 17.7131C7.23008 17.8645 7.03227 17.94 6.83399 17.94Z" fill="#161616"/>
						</svg> 
						<span>Fermer</span>     
					</div>
					<!-- list links -->
					<ul class="level-1">
						<li class="level-1-item level-1-item--service"><h6 class="h6 level-1-service hide-on-mobile">Services</h6>
							<ul class="level-2">
								<li class="level-2-item">
									<a :href="urlWWWPrint" class="level-2-link">
										<svg class="level-2-svg" width="44" height="44" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
												<path d="M23.2941 24H1.74794C0.783971 24 0 23.216 0 22.252V21.9758C0 21.0119 0.783971 20.2279 1.74794 20.2279H18.5184C18.7037 20.2279 18.8546 20.077 18.8546 19.8917C18.8546 19.7064 18.7037 19.5555 18.5184 19.5555H12.9212C11.9572 19.5555 11.1732 18.7716 11.1732 17.8076V16.3124C11.1732 15.9224 11.4891 15.6066 11.8791 15.6066C12.2691 15.6066 12.585 15.9224 12.585 16.3124V17.8076C12.585 17.9929 12.7359 18.1438 12.9212 18.1438H18.5184C19.4824 18.1438 20.2663 18.9277 20.2663 19.8917C20.2663 20.8557 19.4824 21.6397 18.5184 21.6397H1.74794C1.56265 21.6397 1.41176 21.7905 1.41176 21.9758V22.252C1.41176 22.4373 1.56265 22.5882 1.74794 22.5882H23.2941C23.6841 22.5882 24 22.9041 24 23.2941C24 23.6841 23.6841 24 23.2941 24Z" />
												<path d="M17.8762 3.52632H6.12504C5.33357 3.52632 4.68945 2.88221 4.68945 2.09074V1.43559C4.68945 0.644118 5.33357 0 6.12504 0H17.8762C18.6677 0 19.3118 0.644118 19.3118 1.43559V2.09118C19.3118 2.88265 18.6677 3.52632 17.8762 3.52632ZM6.12504 1.41176C6.11181 1.41176 6.10122 1.42235 6.10122 1.43559V2.09118C6.10122 2.10441 6.11181 2.115 6.12504 2.115H17.8762C17.8895 2.115 17.9 2.10441 17.9 2.09118V1.43559C17.9 1.42235 17.8895 1.41176 17.8762 1.41176H6.12504Z" />
												<path d="M13.5365 9.03661H10.4642C9.21739 9.03661 8.20312 8.02278 8.20312 6.77602V2.82087C8.20312 2.43087 8.51901 2.11499 8.90901 2.11499C9.29901 2.11499 9.61489 2.43087 9.61489 2.82087V6.77646C9.61489 7.24455 9.99562 7.62528 10.4642 7.62528H13.5365C14.0046 7.62528 14.3853 7.24455 14.3853 6.77646V2.82087C14.3853 2.43087 14.7012 2.11499 15.0912 2.11499C15.4812 2.11499 15.7971 2.43087 15.7971 2.82087V6.77646C15.7971 8.02278 14.7828 9.03661 13.5365 9.03661Z" />
												<path d="M11.9995 13.0491C10.8948 13.0491 9.99609 12.1504 9.99609 11.0457V8.33113C9.99609 7.94113 10.312 7.62524 10.702 7.62524C11.092 7.62524 11.4079 7.94113 11.4079 8.33113V11.0457C11.4079 11.3722 11.6734 11.6373 11.9995 11.6373C12.3255 11.6373 12.5911 11.3717 12.5911 11.0457V8.33113C12.5911 7.94113 12.907 7.62524 13.297 7.62524C13.687 7.62524 14.0029 7.94113 14.0029 8.33113V11.0457C14.0029 12.1504 13.1042 13.0491 11.9995 13.0491Z" />
												<path d="M12.0008 14.2694C11.6108 14.2694 11.2949 13.9535 11.2949 13.5635V12.3432C11.2949 11.9532 11.6108 11.6373 12.0008 11.6373C12.3908 11.6373 12.7067 11.9532 12.7067 12.3432V13.5635C12.7067 13.9535 12.3908 14.2694 12.0008 14.2694Z" />
												<path d="M13.536 1.41176H10.4637C10.0737 1.41176 9.75781 1.09588 9.75781 0.705882C9.75781 0.315882 10.0737 0 10.4637 0H13.536C13.926 0 14.2419 0.315882 14.2419 0.705882C14.2419 1.09588 13.926 1.41176 13.536 1.41176Z" />
												<path d="M22.6221 1.41176H1.38166C0.991664 1.41176 0.675781 1.09588 0.675781 0.705882C0.675781 0.315882 0.991664 0 1.38166 0H22.6221C23.0121 0 23.328 0.315882 23.328 0.705882C23.328 1.09588 23.0117 1.41176 22.6221 1.41176Z" />
										</svg>
										<h4 class="level-2-text">Impression 3D</h4>
									</a>
								</li>
								<li class="level-2-item">
									<a :href="urlWWWProject" class="level-2-link">
											<svg class="level-2-svg" width="44" height="44" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
													<path d="M11.6928 22.1312C11.5741 22.1312 11.4559 22.1038 11.3478 22.0487L2.39093 17.486C2.15039 17.3634 2 17.1238 2 16.8626V7.12146C2 6.87396 2.13522 6.64455 2.35644 6.51661C2.57766 6.38911 2.85223 6.38161 3.08035 6.49764L12.0373 11.0448C12.2783 11.167 12.4291 11.407 12.4291 11.6687V21.4253C12.4291 21.6732 12.2939 21.9026 12.0722 22.0301C11.9554 22.0972 11.8239 22.1312 11.6928 22.1312ZM3.47174 16.4382L10.9569 20.2509V12.0935L3.47174 8.29366V16.4382Z"/>
													<path d="M10.957 21.4257V21.4253H12.4288L10.957 21.4257Z" />
													<path d="M12.6862 3.78618C12.5703 3.78618 12.4525 3.75971 12.3426 3.70412L11.3469 3.19853C10.9877 3.01632 10.8507 2.58882 11.0406 2.24426C11.2305 1.89971 11.6762 1.76824 12.0354 1.95044L13.0311 2.45603C13.3903 2.63824 13.5274 3.06574 13.3374 3.41029C13.2054 3.64941 12.9502 3.78618 12.6862 3.78618Z" />
													<path d="M17.3285 6.14338C17.2126 6.14338 17.0949 6.1169 16.985 6.06132L14.6633 4.88249C14.3041 4.70029 14.1671 4.27278 14.357 3.92823C14.5469 3.58367 14.9926 3.4522 15.3518 3.6344L17.6735 4.81323C18.0327 4.99543 18.1697 5.42293 17.9798 5.76749C17.8478 6.00661 17.5925 6.14338 17.3285 6.14338Z" />
													<path d="M20.648 8.90818C20.2415 8.90818 19.9122 8.5923 19.9122 8.2023V7.54628L19.3078 7.23966C18.9486 7.05746 18.8116 6.62996 19.0015 6.2854C19.1915 5.94084 19.6371 5.80937 19.9963 5.99158L20.992 6.49717C21.233 6.61937 21.3839 6.85937 21.3839 7.12099V8.20186C21.3839 8.5923 21.0546 8.90818 20.648 8.90818Z"/>
													<path d="M20.648 13.9611C20.2414 13.9611 19.9121 13.6452 19.9121 13.2552V10.7286C19.9121 10.3386 20.2414 10.0227 20.648 10.0227C21.0545 10.0227 21.3838 10.3386 21.3838 10.7286V13.2552C21.3838 13.6452 21.0545 13.9611 20.648 13.9611Z" />
													<path d="M19.6524 18.0754C19.3888 18.0754 19.134 17.9396 19.002 17.7004C18.8116 17.3563 18.9478 16.9288 19.307 16.7457L19.9113 16.4378V15.7813C19.9113 15.3913 20.2406 15.0754 20.6472 15.0754C21.0537 15.0754 21.383 15.3913 21.383 15.7813V16.8622C21.383 17.1234 21.2326 17.3629 20.9921 17.4856L19.9968 17.9925C19.8869 18.049 19.7692 18.0754 19.6524 18.0754Z"/>
													<path d="M15.0098 20.4414C14.7463 20.4414 14.4915 20.3056 14.3595 20.0664C14.1691 19.7223 14.3052 19.2948 14.6644 19.1117L16.9865 17.929C17.3457 17.7463 17.7909 17.8769 17.9818 18.2215C18.1722 18.5656 18.0361 18.9931 17.6769 19.1762L15.3547 20.3589C15.2444 20.4145 15.1262 20.4414 15.0098 20.4414Z" />
													<path d="M11.6914 22.1312C11.4279 22.1312 11.1731 21.9953 11.0411 21.7562C10.8507 21.4121 10.9868 20.9846 11.346 20.8015L12.3413 20.2946C12.7 20.1119 13.1457 20.2425 13.3366 20.5871C13.527 20.9312 13.3908 21.3587 13.0316 21.5418L12.0364 22.0487C11.926 22.1047 11.8078 22.1312 11.6914 22.1312Z" />
													<path d="M19.6547 8.33292C19.3907 8.33292 19.1359 8.19659 19.0035 7.95704C18.8135 7.61248 18.9506 7.18542 19.3098 7.00278L20.3055 6.49719C20.6647 6.31499 21.1099 6.44646 21.3003 6.79101C21.4903 7.13557 21.3532 7.56263 20.994 7.74527L19.9983 8.25086C19.8884 8.30644 19.7706 8.33292 19.6547 8.33292Z" />
													<path d="M15.0102 10.6902C14.7462 10.6902 14.4914 10.5538 14.359 10.3143C14.169 9.96973 14.3061 9.54267 14.6653 9.36002L16.9869 8.18164C17.3461 7.99944 17.7913 8.13091 17.9817 8.47547C18.1717 8.82002 18.0346 9.24708 17.6754 9.42973L15.3538 10.6081C15.2438 10.6637 15.1261 10.6902 15.0102 10.6902Z" />
													<path d="M11.6938 12.3745C11.4298 12.3745 11.175 12.2382 11.0425 11.9987C10.8526 11.6541 10.9897 11.227 11.3489 11.0444L12.3446 10.5388C12.7038 10.3566 13.149 10.4881 13.3394 10.8326C13.5298 11.1772 13.3923 11.6042 13.0331 11.7869L12.0374 12.2925C11.9274 12.3481 11.8097 12.3745 11.6938 12.3745Z" />
													<path d="M11.6918 12.3746C11.5759 12.3746 11.4582 12.3481 11.3483 12.2925L2.39185 7.74529C2.15085 7.62309 2 7.38309 2 7.12147C2 6.85985 2.15085 6.61985 2.39185 6.49765L11.3483 1.95044C11.7075 1.76824 12.1527 1.89971 12.3431 2.24426C12.533 2.58882 12.396 3.01588 12.0368 3.19853L4.30924 7.12147L12.0368 11.0444C12.396 11.2266 12.533 11.6541 12.3431 11.9987C12.2111 12.2378 11.9558 12.3746 11.6918 12.3746Z"/>
													<path d="M11.6929 24C11.2863 24 10.957 23.6841 10.957 23.2941V0.705882C10.957 0.315882 11.2863 0 11.6929 0C12.0995 0 12.4288 0.315882 12.4288 0.705882V23.2941C12.4288 23.6841 12.0995 24 11.6929 24Z" />
											</svg>
										<h4 class="level-2-text">Projet 3D</h4>
									</a>
								</li>
								<li class="level-2-item">
									<a :href="urlWWWModel" class="level-2-link">
										<svg class="level-2-svg" width="44" height="44" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
												<path d="M11.9992 12.3088C11.8858 12.3088 11.7724 12.2815 11.6692 12.2268L1.37393 6.77823C1.14275 6.65603 0.998047 6.41603 0.998047 6.15441C0.998047 5.89279 1.14275 5.65279 1.37393 5.53059L11.6688 0.0820588C11.8752 -0.0273529 12.1227 -0.0273529 12.3292 0.0820588L22.6236 5.53059C22.8548 5.65279 22.9995 5.89279 22.9995 6.15441C22.9995 6.41603 22.8548 6.65603 22.6236 6.77823L12.3292 12.2268C12.226 12.2815 12.1126 12.3088 11.9992 12.3088ZM3.21319 6.15441L11.9992 10.8044L20.7843 6.15441L11.9992 1.50441L3.21319 6.15441Z"/>
												<path d="M12.0008 23.9995C11.8746 23.9995 11.7489 23.9656 11.6373 23.8985C11.4251 23.771 11.2949 23.5412 11.2949 23.2937V11.6029C11.2949 11.3413 11.4396 11.1013 11.6708 10.9791L21.9652 5.53059C22.184 5.41456 22.4474 5.42206 22.6596 5.54956C22.8718 5.67706 23.0015 5.90692 23.0015 6.15442V17.8266C23.0015 18.0878 22.8573 18.3273 22.6265 18.45L12.3317 23.917C12.228 23.9722 12.1142 23.9995 12.0008 23.9995ZM12.7067 12.0278V22.1193L21.5893 17.4022V7.32662L12.7067 12.0278Z" />
												<path d="M11.9993 23.9995C11.8854 23.9995 11.772 23.9722 11.6684 23.917L1.37305 18.45C1.14231 18.3273 0.998047 18.0878 0.998047 17.8266V6.15439C0.998047 5.90689 1.12775 5.67748 1.33996 5.54954C1.55217 5.4216 1.81555 5.41454 2.03437 5.53013L12.3293 10.9787C12.5604 11.1009 12.7051 11.3409 12.7051 11.6025V23.2932C12.7051 23.5412 12.5754 23.7706 12.3628 23.8981C12.2512 23.9656 12.1254 23.9995 11.9993 23.9995ZM2.41026 17.4022L11.2934 22.1192V12.0278L2.41026 7.3266V17.4022Z" />
												<path d="M11.2949 23.2941V23.2937H12.7067L11.2949 23.2941Z" />
												<path d="M0.705881 3.62558C0.315882 3.62558 0 3.3097 0 2.9197V0.705881C0 0.315882 0.315882 0 0.705881 0H2.9197C3.3097 0 3.62558 0.315882 3.62558 0.705881C3.62558 1.09588 3.3097 1.41176 2.9197 1.41176H1.41176V2.9197C1.41176 3.30926 1.09588 3.62558 0.705881 3.62558Z" />
												<path d="M2.9197 24H0.705881C0.315882 24 0 23.6841 0 23.2941V21.0803C0 20.6903 0.315882 20.3744 0.705881 20.3744C1.09588 20.3744 1.41176 20.6903 1.41176 21.0803V22.5882H2.9197C3.3097 22.5882 3.62558 22.9041 3.62558 23.2941C3.62558 23.6841 3.30926 24 2.9197 24Z"/>
												<path d="M23.2947 24H21.0809C20.6909 24 20.375 23.6841 20.375 23.2941C20.375 22.9041 20.6909 22.5882 21.0809 22.5882H22.5888V21.0803C22.5888 20.6903 22.9047 20.3744 23.2947 20.3744C23.6847 20.3744 24.0006 20.6903 24.0006 21.0803V23.2941C24.0006 23.6841 23.6847 24 23.2947 24Z" />
												<path d="M23.2947 3.62558C22.9047 3.62558 22.5888 3.3097 22.5888 2.9197V1.41176H21.0809C20.6909 1.41176 20.375 1.09588 20.375 0.705881C20.375 0.315882 20.6909 0 21.0809 0H23.2947C23.6847 0 24.0006 0.315882 24.0006 0.705881V2.9197C24.0006 3.30926 23.6847 3.62558 23.2947 3.62558Z" />
												<path d="M1.70481 18.5326C1.4657 18.5326 1.23276 18.4112 1.09996 18.192C0.898345 17.8584 1.00511 17.4243 1.33864 17.2227L7.72024 13.3646C8.05377 13.163 8.48789 13.2698 8.68951 13.6033C8.89112 13.9368 8.78436 14.3709 8.45083 14.5726L2.06923 18.4306C1.9554 18.4999 1.82923 18.5326 1.70481 18.5326Z" />
												<path d="M22.2934 18.5325C22.169 18.5325 22.0433 18.4994 21.929 18.4306L15.5478 14.5725C15.2143 14.3709 15.1071 13.9368 15.3091 13.6032C15.5108 13.2697 15.9449 13.1625 16.2784 13.3646L22.6596 17.2227C22.9931 17.4243 23.1003 17.8584 22.8983 18.1919C22.7655 18.4112 22.5325 18.5325 22.2934 18.5325Z" />
										</svg> 
										<h4 class="level-2-text">Modèles</h4> 
									</a>
								</li>
								<li class="level-2-item">
									<a :href="urlWWWResource" class="level-2-link level-2-link--last">
										<svg class="level-2-svg" width="44" height="44" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<path d="M21.1757 23.1252H2.8243C1.81853 23.1252 1 22.3262 1 21.3436V5.65652C1 4.67425 1.81853 3.87524 2.8243 3.87524H9.37779C9.73529 3.87524 10.0249 4.15804 10.0249 4.50718C10.0249 4.85633 9.73529 5.13912 9.37779 5.13912H2.8243C2.53191 5.13951 2.29412 5.37136 2.29412 5.65652V21.3436C2.29412 21.6291 2.53191 21.8614 2.8243 21.8614H21.1757C21.4681 21.8614 21.7059 21.6291 21.7059 21.3436V9.78662C21.7059 9.43748 21.9954 9.15469 22.3529 9.15469C22.7104 9.15469 23 9.43748 23 9.78662V21.344C23 22.3262 22.1815 23.1252 21.1757 23.1252Z"/>
											<path d="M22.352 17.8462C21.9945 17.8462 21.7049 17.5634 21.7049 17.2143V9.78667C21.7049 9.50112 21.4671 9.26888 21.1747 9.26888H11.9986C11.6411 9.26888 11.3516 8.98609 11.3516 8.63694C11.3516 8.2878 11.6411 8.005 11.9986 8.005H21.1747C22.1805 8.005 22.999 8.80441 22.999 9.78667V17.2147C22.999 17.5634 22.7095 17.8462 22.352 17.8462Z"/>
											<path d="M20.3791 9.26887C20.0216 9.26887 19.732 8.98608 19.732 8.63693V6.65423C19.732 6.36867 19.4942 6.13643 19.2018 6.13643H10.0103C9.65284 6.13643 9.36328 5.85364 9.36328 5.5045C9.36328 5.15535 9.65284 4.87256 10.0103 4.87256H19.2018C20.2076 4.87256 21.0261 5.67196 21.0261 6.65423V8.63693C21.0261 8.98568 20.7361 9.26887 20.3791 9.26887Z"/>
											<path d="M11.9999 9.26889C11.7836 9.26889 11.5721 9.16265 11.4495 8.9699L8.82771 4.84019C8.63926 4.54358 8.73308 4.15415 9.0368 3.97009C9.34051 3.78604 9.73926 3.87767 9.92772 4.17429L12.5495 8.304C12.738 8.60062 12.6442 8.99005 12.3405 9.1741C12.2341 9.23808 12.1164 9.26889 11.9999 9.26889Z"/>
										</svg> 
										<h4 class="level-2-text">Blog</h4>
									</a>
								</li>
							</ul>
						</li>
						<li class="level-1-item level-1-item--maker"><a :href="urlContact" class="level-1-link"><span class="h6">Contactez-nous</span></a></li>
						<li class="level-1-item"><router-link to="/project" href="" class="level-1-link"><span class="btn--devis">Obtenir un devis</span></router-link></li>
						<li class="level-1-item">
							<a href="" class="nav-btn-connexion">
								<router-link v-if="initialName == 0" to="/login">
									<svg class="svg-icon-item i-account" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 24C8.79478 24 5.7813 22.7517 3.51478 20.4852C1.24826 18.2187 0 15.2052 0 12C0 8.79478 1.24826 5.7813 3.51478 3.51478C5.7813 1.24826 8.79478 0 12 0C15.2052 0 18.2187 1.24826 20.4852 3.51478C22.7517 5.7813 24 8.79478 24 12C24 15.2052 22.7517 18.2187 20.4852 20.4852C18.2187 22.7517 15.2052 24 12 24ZM12 1.73913C9.25913 1.73913 6.68261 2.80652 4.74435 4.74435C2.80652 6.68261 1.73913 9.25913 1.73913 12C1.73913 14.7409 2.80652 17.3174 4.74435 19.2557C6.68261 21.1935 9.25913 22.2609 12 22.2609C14.7409 22.2609 17.3174 21.1935 19.2557 19.2557C21.1935 17.3174 22.2609 14.7409 22.2609 12C22.2609 9.25913 21.1935 6.68261 19.2557 4.74435C17.3174 2.80652 14.7409 1.73913 12 1.73913Z"/>
										<path d="M3.56847 20.1347C3.37282 20.1347 3.17629 20.069 3.01412 19.9347C2.64412 19.6282 2.59281 19.0803 2.89934 18.7103C5.15369 15.9886 8.47152 14.4277 12.002 14.4277C15.532 14.4277 18.8498 15.9886 21.1037 18.7103C21.4102 19.0803 21.3585 19.6282 20.9889 19.9347C20.6189 20.2408 20.0707 20.1895 19.7646 19.8199C17.842 17.4986 15.0124 16.1673 12.002 16.1673C8.99109 16.1673 6.16152 17.4986 4.2389 19.8199C4.06673 20.0273 3.81847 20.1347 3.56847 20.1347Z"/>
										<path d="M11.9987 13.1427C9.79391 13.1427 8 11.3487 8 9.14396C8 6.93918 9.79391 5.14526 11.9987 5.14526C14.2035 5.14526 15.9974 6.93918 15.9974 9.14396C15.9974 11.3487 14.2035 13.1427 11.9987 13.1427ZM11.9987 6.88439C10.753 6.88439 9.73913 7.89787 9.73913 9.14396C9.73913 10.39 10.7526 11.4035 11.9987 11.4035C13.2448 11.4035 14.2583 10.39 14.2583 9.14396C14.2583 7.89787 13.2443 6.88439 11.9987 6.88439Z"/>
									</svg>
								</router-link>
								<router-link v-if="initialName.length > 0" to="/account" class="initial-name nav-btn-connexion-initial-name">{{ initialName }}</router-link>
								<router-link v-if="initialName == 0" to="/account" class="nav-btn-connexion-for--mobile hide-on-desktop">Connexion</router-link>
								<router-link v-if="initialName.length > 0" to="/account" class="nav-btn-connexion-for--mobile hide-on-desktop">Mon compte</router-link>
							</a>
						</li>
						<li class="level-1-item hide-on-mobile n-card" :class="shopCompleted == true ? 'n-card--articles' : ''">
							<router-link :to="'/basket' + token">
								<svg class="svg-icon-item i-shop" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M17.1241 12.4978H6.92563C6.43472 12.4978 6.03711 12.1087 6.03711 11.6283V5.28478C6.03711 2.37087 8.45923 0 11.4367 0H12.6126C15.5905 0 18.0127 2.37087 18.0127 5.28478V11.6283C18.0127 12.1083 17.615 12.4978 17.1241 12.4978ZM7.81416 10.7587H16.2356V5.28478C16.2356 3.32957 14.6105 1.73913 12.6126 1.73913H11.4367C9.43883 1.73913 7.81372 3.32957 7.81372 5.28478V10.7587H7.81416Z" />
									<path d="M17.1241 12.4978H6.92563C6.43472 12.4978 6.03711 12.1087 6.03711 11.6283V5.28478C6.03711 2.37087 8.45923 0 11.4367 0H12.6126C15.5905 0 18.0127 2.37087 18.0127 5.28478V11.6283C18.0127 12.1083 17.615 12.4978 17.1241 12.4978ZM7.81416 10.7587H16.2356V5.28478C16.2356 3.32957 14.6105 1.73913 12.6126 1.73913H11.4367C9.43883 1.73913 7.81372 3.32957 7.81372 5.28478V10.7587H7.81416Z" />
									<path d="M22.0217 24H1.98056C1.73221 24 1.49542 23.8982 1.32705 23.7195C1.15867 23.5408 1.07471 23.3013 1.0947 23.0591L2.32797 8.32824C2.36573 7.87693 2.75091 7.52954 3.21339 7.52954H20.7884C21.2509 7.52954 21.6361 7.87693 21.6738 8.32824L22.9071 23.0591C22.9275 23.3013 22.8431 23.5408 22.6748 23.7195C22.5068 23.8982 22.27 24 22.0217 24ZM2.94505 22.2608H21.0576L19.9701 9.26911H4.03216L2.94505 22.2608Z"/>
								</svg>
							</router-link>
						</li>
					</ul>

				</div> <!-- nav-lists -->

				<div class="shop--alone n-card" :class="shopCompleted == true ? 'n-card--articles' : ''">
					<router-link to="/basket">
						<svg class="svg-icon-item i-shop" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M17.1241 12.4978H6.92563C6.43472 12.4978 6.03711 12.1087 6.03711 11.6283V5.28478C6.03711 2.37087 8.45923 0 11.4367 0H12.6126C15.5905 0 18.0127 2.37087 18.0127 5.28478V11.6283C18.0127 12.1083 17.615 12.4978 17.1241 12.4978ZM7.81416 10.7587H16.2356V5.28478C16.2356 3.32957 14.6105 1.73913 12.6126 1.73913H11.4367C9.43883 1.73913 7.81372 3.32957 7.81372 5.28478V10.7587H7.81416Z"/>
							<path d="M17.1241 12.4978H6.92563C6.43472 12.4978 6.03711 12.1087 6.03711 11.6283V5.28478C6.03711 2.37087 8.45923 0 11.4367 0H12.6126C15.5905 0 18.0127 2.37087 18.0127 5.28478V11.6283C18.0127 12.1083 17.615 12.4978 17.1241 12.4978ZM7.81416 10.7587H16.2356V5.28478C16.2356 3.32957 14.6105 1.73913 12.6126 1.73913H11.4367C9.43883 1.73913 7.81372 3.32957 7.81372 5.28478V10.7587H7.81416Z"/>
							<path d="M22.0217 24H1.98056C1.73221 24 1.49542 23.8982 1.32705 23.7195C1.15867 23.5408 1.07471 23.3013 1.0947 23.0591L2.32797 8.32824C2.36573 7.87693 2.75091 7.52954 3.21339 7.52954H20.7884C21.2509 7.52954 21.6361 7.87693 21.6738 8.32824L22.9071 23.0591C22.9275 23.3013 22.8431 23.5408 22.6748 23.7195C22.5068 23.8982 22.27 24 22.0217 24ZM2.94505 22.2608H21.0576L19.9701 9.26911H4.03216L2.94505 22.2608Z"/>
						</svg>
					</router-link>
				</div>
                        
                   
                
			</div> <!-- navbar-site -->
		</div> <!-- nav-container -->
	</nav> <!-- nav-outer -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'NavigationSite',
	props: {
		navThemeTransparent: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			navIsOpen: false,
			navOuterIsHidden: false,
			lastScrollTop: 0,
			isThemeTransparent: this.navThemeTransparent,
			// 
			token: '',
			//
			urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
			urlWWWPrint: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PRINT,
			urlWWWProject: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PROJECT,
			urlWWWModel: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_MODEL,
			urlWWWResource: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_RESOURCE,
      urlContact: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CONTACT,
		}
	},
	computed: {
    ...mapGetters('authentication', {
    initialName: 'initialName'
    }),
	...mapGetters("shop", {
		shopCompleted: "shopCompleted"
	})
	},
	created () {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	beforeCreate() {
		if (this.$route.params.token != undefined) {
			this.token = '/' + this.$route.params.token
		} else {
			this.token = ''
		}
	},
	methods: {
    handleScroll() {
 

      let st = window.pageYOffset || document.documentElement.scrollTop;

      if (window.scrollY <= 88) {

        if (window.innerWidth <= 992) {
        this.navOuterIsHidden = false;
			this.isThemeTransparent = true
        } else {
			this.navOuterIsHidden = false;
			this.isThemeTransparent = true
        }

      } else if (st > this.lastScrollTop) {
				this.isThemeTransparent = false
				this.navOuterIsHidden = true
			} else {
				this.navOuterIsHidden = false;
				this.isThemeTransparent = false
			}

      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
  },
}
</script>